const debug = require("debug")("mutant:UIYank")

import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"

import css from "./yank.css"

const UIYank = ({ id, model }) => {
  return (
    <div className={cx(css.yank)}>
      {id}
      <br />
      {model}
    </div>
  )
}

UIYank.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
}

const memo = React.memo(UIYank)

export { memo as UIYank }
