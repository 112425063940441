const debug = require("debug")("mutant:ProductsList")

import { buildList } from "@mutant-ws/redux-list"
import { GET, POST } from "@mutant-ws/fetch-browser"

const ProductsList = buildList({
  name: "PROFILE.PRODUCTS.PRODUCTS",

  create: ({ name }) =>
    POST("/products", {
      body: {
        name,
      },
    }),

  read: () =>
    GET("/products", {
      query: {
        pluck: ["name", "createdAt"],
      },
    }),
})

export { ProductsList }
