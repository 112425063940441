const debug = require("debug")("mutant:EventsList")

import { buildList } from "@mutant-ws/redux-list"
import { findWith, is } from "@mutant-ws/m"
import { GET } from "@mutant-ws/fetch-browser"
import { DateTime } from "luxon"
import { sort, map, range, pipe, prop, always, ifElse } from "ramda"

export const EventsList = buildList({
  name: "PRODUCT.WORK.CARD.FIELDS.EVENTS",

  read: ({ productId, names }) =>
    GET("/events", {
      query: {
        productId,
        names,
      },
    }),

  onChange: map(({ id, points }) => ({
    id,
    points: pipe(
      // explicitly set days with 0 events
      map(noOfDaysInPast => {
        const dateInPast = DateTime.utc()
          .minus({ days: noOfDaysInPast })
          .set({ hour: 0, minutes: 0, seconds: 0, milliseconds: 0 })

        return {
          x: dateInPast.toJSDate(),
          y: pipe(
            findWith({ x: dateInPast.toISO() }, null),
            ifElse(is, prop("y"), always(0))
          )(points),
        }
      }),

      // sort asc by date
      sort((a, b) => (a.x > b.x ? 1 : -1))
    )(range(0, 30)),
  })),
})
