const debug = require("debug")("mutant:MembersUI")

import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { top, map, pipe, sort } from "@mutant-ws/m"

import { deepReactMemo } from "/core.hooks/use-deep"
import { UIAvatar } from "/core.ui/avatar/avatar"

import css from "./members.css"

const MembersUI = ({ meId, items, isMeOnline, isMeLoading }) => {
  return (
    <ul className={css.members}>
      {pipe(
        sort((a, b) => {
          // sort1: me first
          if (a.userId === meId) {
            return -1
          }
          if (b.userId === meId) {
            return 1
          }

          // sort2: online first
          if (a.isOnline && !b.isOnline) {
            return -1
          }
          if (!a.isOnline && b.isOnline) {
            return 1
          }

          // sort3: recent added first
          return new Date(a.assignedAt) > new Date(b.assignedAt) ? -1 : 1
        }),
        top(6),
        map(({ userId, name, avatarURL, bgColor, fgColor, isOnline }) => {
          const isMe = userId === meId

          return (
            <li
              key={userId}
              className={cx(css.member, {
                [css["member--is-me"]]: isMe,
                [css["member--is-me-loading"]]: isMe && isMeLoading,
                [css["member--is-connected"]]: isMe ? isMeOnline : isOnline,
              })}>
              <UIAvatar
                name={name}
                avatar={avatarURL}
                size={isOnline || isMe ? 30 : 25}
                backgroundColor={bgColor}
                foregroundColor={fgColor}
                isOnline={isMe ? isMeOnline : isOnline}
              />
            </li>
          )
        })
      )(items)}
      {items.length > 6 ? (
        <li className={cx(css.member)}>{items.length - 6}</li>
      ) : null}
    </ul>
  )
}

MembersUI.propTypes = {
  meId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
      bgColor: PropTypes.string,
      fgColor: PropTypes.string,
      isOnline: PropTypes.bool,
    })
  ),
  isMeOnline: PropTypes.bool.isRequired,
  isMeLoading: PropTypes.bool.isRequired,
}

MembersUI.defaultProps = {
  items: [],
}

const memo = deepReactMemo(MembersUI)

export { memo as MembersUI }
