const debug = require("debug")("mutant:FeedbackContainer")

import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import React, { useEffect, useCallback } from "react"
import {
  pipe,
  when,
  count,
  gt,
  read,
  top,
  findWith,
  startsWith,
  isEmpty,
  is,
} from "@asd14/m"

import { useList } from "../core.hooks/use-list"
import { useQuery } from "../core.hooks/use-query"
import {
  useFocus,
  FEEDBACK_LAYER,
  FEEDBACK_INSIGHTS_LAYER,
  FEEDBACK_QUOTES_LAYER,
} from "../core.hooks/use-focus"

import { InsightsContainer } from "../product.feedback.insights/insights.container"
import { InsightsList } from "../product.feedback.insights/data/list.insights"
import { QuotesList } from "../product.feedback.quotes/data/list.quotes"
import { QuotesContainer } from "../product.feedback.quotes/quotes.container"

import { useFeedbackCLI } from "./feedback.cli"

import css from "./feedback.css"

const FeedbackContainer = ({ productId, members }) => {
  const [{ "insight-id": queryInsightId }] = useQuery()
  const [{ layer }, setFocus] = useFocus(productId)

  //
  // Insights - data fetching
  //

  const { create: createQuote } = useList(QuotesList)

  const {
    selector: { items },
    create: createInsight,
    update: updateInsight,
    read: readInsights,
  } = useList(InsightsList)

  useEffect(() => {
    readInsights({ productId })
  }, [productId, readInsights])

  const selectedInsight = findWith(
    is(queryInsightId) ? { id: queryInsightId } : { isInbox: true },
    {},
    items()
  )

  // when Feedback Workspace gains keyboard, focus Quotes section
  useEffect(() => {
    if (layer === FEEDBACK_LAYER) {
      if (is(queryInsightId)) {
        setFocus({ layer: FEEDBACK_QUOTES_LAYER })
      } else {
        setFocus({ layer: FEEDBACK_INSIGHTS_LAYER })
      }
    }
  }, [queryInsightId, layer, setFocus])

  //
  // Feedback - specific cli commands
  //

  useFeedbackCLI(FEEDBACK_LAYER, {
    productId,
    insightId: selectedInsight.id,
    onInsightCreate: createInsight,
    onQuoteCreate: createQuote,
  })

  const handleInsightUpdate = useCallback(
    (id, { body }) => {
      if (selectedInsight.body !== body) {
        return updateInsight(id, { productId, body })
      }
    },
    [productId, selectedInsight.body, updateInsight]
  )

  return (
    <section className={css.feedback}>
      {startsWith(FEEDBACK_LAYER)(layer) ? (
        <Helmet
          title={pipe(
            read("body"),
            when([count, gt(10)], [top(20), source => `${source} ...`])
          )(selectedInsight)}
        />
      ) : null}

      <div className={css["insights-wrapper"]}>
        <InsightsContainer
          productId={productId}
          insight={selectedInsight}
          items={items()}
          members={members}
        />
      </div>
      <div className={css["quotes-wrapper"]}>
        {isEmpty(selectedInsight) ? null : (
          <QuotesContainer
            productId={productId}
            insight={selectedInsight}
            members={members}
            onInsightUpdate={handleInsightUpdate}
          />
        )}
      </div>
      <div className={css["chat-wrapper"]} />
    </section>
  )
}

FeedbackContainer.propTypes = {
  productId: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
    })
  ),
}

FeedbackContainer.defaultProps = {
  members: [],
}

export { FeedbackContainer }
