const debug = require("debug")("mutant:ContactPage")

import React from "react"
import { Helmet } from "react-helmet"

import css from "./contact.css"

import lnPath from "./ui/images/ln.png"
import gitPath from "./ui/images/github.png"
import angelPath from "./ui/images/angel-list.png"

const ContactPage = () => {
  return (
    <div className={css.contact}>
      <Helmet>
        <title>Contact</title>
      </Helmet>

      <div className={css.content}>
        <h1>
          Questions / feedback?
          <br />
          We’d love to hear from you.
        </h1>

        <h2>
          Mutant is a young product. We truly appreciate & fully expect your
          honest feedback.
        </h2>

        <div className={css.team}>
          <div>
            <p>
              <code>online</code>
            </p>

            <p className={css["team-contact"]}>
              <strong>Email</strong>
              <a href="mailto:hello@getmutant.com">hello@getmutant.com</a>
              <br />
              <strong>Phone</strong>
              <a href="tel:+31631245458">+31 631 24 54 58</a>
            </p>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/getmutant">
              <img src={lnPath} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/mutant-ws">
              <img src={gitPath} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://angel.co/company/mutant-workspace">
              <img src={angelPath} />
            </a>

            <br />
            <br />
            <br />
            <br />

            <p>
              <code>offline</code>
            </p>

            <p>
              Want to have a chat? <br />
              We are currently working in Amsterdam, NL.
            </p>
          </div>

          <div className={css.pose} />
        </div>
      </div>
    </div>
  )
}

export { ContactPage }
