const debug = require("debug")("mutant:WorkerUI")

import React, { useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import SimpleBar from "simplebar-react"
import { filterWith, sort, is } from "@mutant-ws/m"
import { append, when, pipe, map, keys } from "ramda"

import { deepReactMemo } from "/core.hooks/use-deep"

import { UIAvatar } from "/core.ui/avatar/avatar"

import css from "./worker.css"

const WorkerUI = ({ className, current, items, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    userId: currentUserId = null,
    name: currentUserName = "none :[",
    avatarURL: currentUserAvaratURL,
    bgColor: currentUserBgColor,
    fgColor: currentUserFgColor,
  } = current

  return (
    <div
      style={{ height: 30 }}
      className={cx(css.worker, {
        [className]: is(className),
        [css["worker--is-open"]]: isOpen,
      })}>
      <div className={css["worker-content"]}>
        <UIAvatar
          className={css.avatar}
          name={currentUserName}
          avatar={currentUserAvaratURL}
          size={30}
          backgroundColor={currentUserBgColor}
          foregroundColor={currentUserFgColor}
          isNameVisible={isOpen}
          hasTooltip={false}
          onClick={() => setIsOpen(prev => !prev)}
        />

        {isOpen ? (
          <SimpleBar
            autoHide={true}
            style={{ minWidth: "200px", maxHeight: "200px" }}>
            {pipe(
              sort((a, b) => {
                // sort2: online first
                if (a.isOnline && !b.isOnline) {
                  return -1
                }
                if (!a.isOnline && b.isOnline) {
                  return 1
                }

                return new Date(a.assignedAt) > new Date(b.assignedAt) ? -1 : 1
              }),
              when(
                () => is(current),
                append({ userId: null, name: "none :[" })
              ),
              filterWith({
                userId: value => value !== currentUserId,
              }),
              map(({ userId, name, avatarURL, bgColor, fgColor }) => (
                <UIAvatar
                  className={css.avatar}
                  key={userId}
                  name={name}
                  avatar={avatarURL}
                  size={30}
                  backgroundColor={bgColor}
                  foregroundColor={fgColor}
                  isNameVisible={true}
                  hasTooltip={false}
                  onClick={() => {
                    setIsOpen(false)
                    onChange(userId)
                  }}
                />
              ))
            )(items)}
          </SimpleBar>
        ) : null}
      </div>
    </div>
  )
}

WorkerUI.propTypes = {
  className: PropTypes.string,
  current: PropTypes.shape({
    userId: PropTypes.string,
    name: PropTypes.string,
    avatarURL: PropTypes.string,
    bgColor: PropTypes.string,
    fgColor: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
      assignedAt: PropTypes.string,
      bgColor: PropTypes.string,
      fgColor: PropTypes.string,
    })
  ),
  onChange: PropTypes.func.isRequired,
}

WorkerUI.defaultProps = {
  className: null,
  current: {},
  items: [],
}

const memo = deepReactMemo(WorkerUI, keys(WorkerUI.propTypes))

export { memo as WorkerUI }
