const debug = require("debug")("mutant:UICommandLine")

import React, { useState, forwardRef } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import {
  partition,
  read,
  count,
  pipe,
  head,
  trim,
  filter,
  forEach,
  toLower,
  filterWith,
  findWith,
  startsWith,
  not,
  same,
  hasWith,
  when,
  isEmpty,
  is,
} from "@asd14/m"
import { evolve, join, split, tryCatch, contains, keys } from "ramda"

import { hover } from "/core.libs/positioning"
import { deepReactMemo, useEffect, useCallback } from "/core.hooks/use-deep"

import { UIInput } from "../input/input"

import { UISuggestions } from "./suggestions"
import { tokenize } from "./tokenizer"

import css from "./command-line.css"

const isTop = (item, index, array) => index < array.length - 1

const getCurrentParamOptions = source => {
  const currentParamIndex = read("currentParamIndex", null)(source)

  return read(["params", currentParamIndex, "options"], [])(source)
}

const UICommandLine = forwardRef(
  (
    {
      className,
      classNameSuggestions,
      value,
      commands,
      hasFocusAfterRun,
      onFocus,
      onBlur,
    },
    ref
  ) => {
    const [query, setQuery] = useState(value)

    useEffect(() => {
      setQuery(value)
    }, [value])

    const [hasInputFocus, setHasInputFocus] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)

    //
    // `command` object from parsing input `query`
    //

    const parseQuery = useCallback(() => {
      const [cmd, ...paramValues] = tokenize(query)

      return pipe(
        findWith({ name: cmd }, null),
        when(is, item => ({
          ...item,
          currentParamIndex: isEmpty(paramValues)
            ? null
            : count(paramValues) - 1,
          paramValues,
        }))
      )(commands)
    }, [query, commands])

    const command = tryCatch(
      pipe(trim(" "), parseQuery),
      pipe(
        read("message"),
        // prevent infinite re-render
        message => !is(errorMessage) && setErrorMessage(message),
        same(null)
      )
    )(query)

    //
    // Suggestions matching current input `query`
    //

    const filteredCommands = filterWith({
      name: startsWith(read("name", query)(command)),
    })(commands)

    //
    // focusCmdName - current element being hovered/selected in suggestion box
    // shadow - autocomplete string, pressing Tab will fill it in
    //

    const [focusCmdName, setFocusCmdName] = useState(null)
    const [shadow, setShadow] = useState("")

    //
    // autocomplete command and parameter values if defined
    //

    useEffect(() => {
      if (is(command)) {
        const [topParams, [lastParamValue = ""]] = pipe(
          read("paramValues", []),
          partition(isTop)
        )(command)

        const lastParamSuggestion = pipe(
          getCurrentParamOptions,
          filter(startsWith(lastParamValue)),
          head
        )(command)

        setShadow(join(" ")([focusCmdName, ...topParams, lastParamSuggestion]))
      } else {
        setShadow(focusCmdName)
      }
    }, [command, focusCmdName])

    //
    // Select first when gaining focus or suggestions change
    //

    useEffect(() => {
      if (hasInputFocus) {
        setFocusCmdName(pipe(head, read("name"))(filteredCommands))
      }
    }, [filteredCommands, hasInputFocus])

    //
    // Run currently filled in command, if not present, run command from param
    //

    const runCommand = useCallback(
      async commandName => {
        if (!hasWith({ name: commandName })(commands)) {
          throw new Error(`"${commandName}" command not defined`)
        }

        const { params = [], paramValues = [], onFinish } = is(command)
          ? command
          : findWith({ name: commandName }, {})(commands)

        //
        // If hovered command has required parameters, fill in command name
        //

        if (is(commandName) && !is(command) && !isEmpty(params)) {
          // when clicking a suggestion, focus will be lost from input
          setTimeout(() => {
            is(ref.current) && ref.current.focus()
          }, 1)

          return setQuery(`${commandName} `)
        }

        //
        // Check parameter conditions and throw error is not met
        //

        when(
          not(isEmpty),
          forEach(({ name, options, isRequired = false }, index) => {
            const paramValue = pipe(read(index, ""), trim(" "))(paramValues)

            if (isRequired && isEmpty(paramValue)) {
              throw new Error(`Parameter "${name}" is required`)
            }

            if (!isEmpty(options) && !contains(paramValue, options)) {
              throw new Error(
                `Invalid value "${paramValue}" for param "${name}"`
              )
            }
          })
        )(params)

        //
        // If command has no params, run it
        //

        // The onFinish handler might do something that will unmout this component
        // and React will throw "Can't perform a React state update on an
        // unmounted component". Place all setState calls before onFinish.
        setQuery("")

        // one cmd at a time. loose focus and continue workflow
        if (hasFocusAfterRun === false && is(ref.current)) {
          ref.current.blur()
        }

        return onFinish(...paramValues)
      },
      [command, commands, hasFocusAfterRun, ref]
    )

    const handleRun = useCallback(
      source => {
        runCommand(source)
          .then(({ message } = {}) => {
            if (is(message)) {
              setSuccessMessage(message)
              setTimeout(() => setSuccessMessage(null), 5000)
            }
          })
          .catch(error => {
            setErrorMessage(read(["message"], "Something bad happened")(error))

            throw error
          })
      },
      [runCommand]
    )

    const handleShortcuts = useCallback(
      event => {
        switch (event.key) {
          case "ArrowUp": {
            const upName = hover("up", {
              id: focusCmdName,
              items: filteredCommands,
              matchIdField: "name",
            })

            setFocusCmdName(upName)
            break
          }
          case "ArrowDown": {
            const downName = hover("down", {
              id: focusCmdName,
              items: filteredCommands,
              matchIdField: "name",
            })

            setFocusCmdName(downName)
            break
          }
          case "Escape": {
            if (isEmpty(query) && isEmpty(errorMessage)) {
              is(ref.current) && ref.current.blur()
            } else {
              setQuery("")
              setErrorMessage(null)
            }

            break
          }
          case "Enter": {
            handleRun(focusCmdName)
            break
          }
          default:
        }
      },
      [errorMessage, filteredCommands, focusCmdName, query, ref, handleRun]
    )

    const handleInputChange = useCallback(
      newValue => {
        setHasInputFocus(true)

        // transform to lower case the command name
        setQuery(pipe(split(" "), evolve({ 0: toLower }), join(" "))(newValue))

        if (!isEmpty(errorMessage)) {
          setErrorMessage(null)
        }
      },
      [errorMessage]
    )

    const handleInputFocus = useCallback(
      event => {
        setHasInputFocus(true)

        if (is(onFocus)) {
          onFocus(event)
        }
      },
      [onFocus]
    )

    const handleInputBlur = useCallback(
      event => {
        setHasInputFocus(false)

        if (is(onBlur)) {
          onBlur(event)
        }
      },
      [onBlur]
    )

    return (
      <React.Fragment>
        <UIInput
          ref={ref}
          className={cx(css.cli, {
            [className]: is(className),
          })}
          value={query}
          shadow={isEmpty(query) ? "type a command" : shadow}
          hasAutoFocus={false}
          onChange={useCallback(
            event => handleInputChange(event.currentTarget.value),
            [handleInputChange]
          )}
          onKeyDown={useCallback(
            event => {
              if (event.key === "Tab") {
                // prevent loosing focus
                event.preventDefault()

                if (!isEmpty(shadow)) {
                  handleInputChange(`${trim(" ")(shadow)} `)
                }
              } else {
                handleShortcuts(event)
              }
            },
            [handleInputChange, handleShortcuts, shadow]
          )}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />

        {hasInputFocus && !is(errorMessage) && !is(successMessage) ? (
          <UISuggestions
            className={classNameSuggestions}
            commands={filteredCommands}
            focusCmdName={focusCmdName}
            paramIndex={read("currentParamIndex")(command)}
            onClick={handleRun}
            onMouseEnter={setFocusCmdName}
          />
        ) : null}

        {is(successMessage) ? (
          <div className={css.success}>{successMessage}</div>
        ) : null}

        {is(errorMessage) ? (
          <div className={css.error}>{errorMessage}</div>
        ) : null}
      </React.Fragment>
    )
  }
)

const CommandPropType = PropTypes.shape({
  layer: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  alias: PropTypes.string,
  params: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["text", "password"]),
    })
  ),
  onFinish: PropTypes.func.isRequired,
})

UICommandLine.propTypes = {
  className: PropTypes.string,
  classNameSuggestions: PropTypes.string,
  value: PropTypes.string,
  commands: PropTypes.arrayOf(CommandPropType),
  hasFocusAfterRun: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

UICommandLine.defaultProps = {
  className: null,
  classNameSuggestions: null,
  value: "",
  commands: [],
  hasFocusAfterRun: true,
  onFocus: null,
  onBlur: null,
}

const memo = deepReactMemo(UICommandLine, keys(UICommandLine.propTypes))

export { memo as UICommandLine }
