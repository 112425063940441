const debug = require("debug")("mutant:useMetricsCLI")

import { is } from "@mutant-ws/m"

import { positionAfter } from "/core.libs/positioning"
import { useEffect } from "/core.hooks/use-deep"
import { useCommands } from "/core.hooks/use-commands"

export const useMetricsCLI = (
  sectionLayer,
  { productId, focusId, metricsList: { items, create }, onHoverChange }
) => {
  const [, [addCommands, removeCommands]] = useCommands()

  useEffect(() => {
    addCommands({
      layer: sectionLayer,
      commands: [
        {
          name: "metric",
          description: "add new metric (plot event streams)",
          params: [{ name: "title" }],
          onFinish: title =>
            create({
              productId,
              title,
              position: positionAfter({ id: focusId, items }),
              isKPI: false,
            }).then(({ result, error }) => {
              if (is(error)) {
                throw error
              }

              onHoverChange(result.id)
            }),
        },
      ],
    })

    return () => removeCommands({ layer: sectionLayer })
  }, [
    productId,
    focusId,
    sectionLayer,
    items,
    create,
    onHoverChange,
    addCommands,
    removeCommands,
  ])
}
