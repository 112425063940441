const debug = require("debug")("mutant:MeView")

import React from "react"
import PropTypes from "prop-types"

import { UIInput } from "/core.ui/input/input"

import css from "./me.css"

const MeView = ({
  name,
  nameError,
  email,
  emailError,
  isLoading,
  onNameChange,
  onEmailChange,
  onSubmit,
}) => {
  return (
    <div className={css.form}>
      <UIInput
        value={name}
        label="Name"
        type="text"
        name="name"
        error={nameError}
        isLoading={isLoading}
        hasAutoFocus={false}
        onChange={onNameChange}
        onSubmit={onSubmit}
      />
      <UIInput
        value={email}
        label="Email"
        type="text"
        name="email"
        error={emailError}
        isLoading={isLoading}
        onChange={onEmailChange}
        onSubmit={onSubmit}
      />
    </div>
  )
}

MeView.propTypes = {
  name: PropTypes.string,
  nameError: PropTypes.string,
  email: PropTypes.string,
  emailError: PropTypes.string,
  isLoading: PropTypes.bool,
  onEmailChange: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

MeView.defaultProps = {
  name: "",
  nameError: null,
  email: "",
  emailError: null,
  isLoading: false,
}

const memo = React.memo(MeView)

export { memo as MeView }
