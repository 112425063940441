const debug = require("debug")("mutant:MetricsContainer")

import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import { findWith, isEmpty, is } from "@mutant-ws/m"
import { pipe, prop, reduce } from "ramda"
import isDeepEqual from "fast-deep-equal"

import { useEffect } from "/core.hooks/use-deep"
import { useList } from "/core.hooks/use-list"
import { useLiveList } from "/core.hooks/use-live-list"
import { MEASURE_METRICS_LAYER } from "/core.hooks/use-focus"

import { useMetricsKeyboard } from "./metrics.keyboard"
import { useMetricsCLI } from "./metrics.cli"
import { MetricsList } from "./data/list.metrics"
import { EventsList } from "./data/list.events"

import { MetricsView } from "./metrics.view"

const MetricsContainer = ({ productId }) => {
  const [editId, setEditId] = useState()
  const [focusId, setFocusId] = useState()

  //
  // Metrics data fetching
  //

  const {
    selector: { items: metricsItems },
    read: readMetrics,
    update,
    create,
    remove,
  } = useLiveList(MetricsList, {
    events: {
      prefix: "metrics",
    },
  })

  useEffect(() => {
    readMetrics({ productId })
  }, [productId, readMetrics])

  const metrics = metricsItems()

  //
  // Events data fetching
  //

  const {
    selector: { items: eventItems },
    read: readEvents,
  } = useList(EventsList)

  const eventNames = reduce(
    (acc, { code }) => [...acc, ...code.events],
    []
  )(metrics)

  useEffect(() => {
    if (!isEmpty(eventNames)) {
      readEvents({
        productId,
        names: eventNames,
      })
    }
  }, [productId, eventNames, readEvents])

  const events = eventItems()

  //
  // Metrics keyboard shortcuts
  //

  const { hasKeyboard } = useMetricsKeyboard(MEASURE_METRICS_LAYER, {
    productId,
    focusId,
    metricsList: { items: metrics, update, remove },
    onHoverChange: setFocusId,
    onEdit: useCallback((id, event) => {
      if (is(id)) {
        event.preventDefault()
        setEditId(id)
      }
    }, []),
  })

  //
  // Metrics CLI commands
  //

  useMetricsCLI(MEASURE_METRICS_LAYER, {
    productId,
    focusId,
    metricsList: { items: metrics, create },
    onHoverChange: setFocusId,
  })

  //
  const handleMetricSelect = useCallback(id => {
    setFocusId(id)
  }, [])

  const handleMetricToEditMode = useCallback(id => {
    setEditId(id)
    setFocusId(id)
  }, [])

  const handleMetricToViewMode = useCallback(() => {
    setEditId(null)
  }, [])

  const handleMetricSubmit = useCallback(
    (id, value) => {
      const prev = pipe(findWith({ id }), prop("code"))(metrics)
      const next = isEmpty(value) ? {} : JSON.parse(value)

      if (isDeepEqual(prev, next)) {
        setEditId(null)
      } else {
        update(id, {
          code: isEmpty(value) ? {} : JSON.parse(value),
        }).then(() => {
          setEditId(null)
        })
      }
    },
    [metrics, update]
  )

  return (
    <MetricsView
      productId={productId}
      focusId={hasKeyboard ? focusId : null}
      editId={editId}
      metrics={metrics}
      events={events}
      onMetricClick={handleMetricSelect}
      onMetricDoubleClick={handleMetricToEditMode}
      onMetricSubmit={handleMetricSubmit}
      onMetricCancel={handleMetricToViewMode}
    />
  )
}

MetricsContainer.propTypes = {
  productId: PropTypes.string.isRequired,
}

export { MetricsContainer }
