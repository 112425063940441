const debug = require("debug")("mutant:QuotesList")

import { buildList } from "@mutant-ws/redux-list"
import { descend, sortWith, prop, path } from "ramda"
import { GET, POST, PATCH, DELETE } from "@mutant-ws/fetch-browser"

export const QuotesList = buildList({
  name: "PRODUCT.FEEDBACK.QUOTES",

  create: ({ productId, insightId, body }) =>
    POST("/quotes", {
      body: {
        productId,
        insightId,
        body,
      },
    }),

  read: ({ productId, insightId }) =>
    GET("/quotes", {
      query: {
        filter: {
          productId,
          insightId,
        },
        pluck: [
          "insightId",
          "body",
          "aggregate",
          "contact",
          "source",
          "createdAt",
        ],
      },
    }),

  update: (id, { productId, ...data }) =>
    PATCH(`/quotes/${id}`, {
      query: { productId },
      body: data,
    }),

  remove: (id, { productId }) =>
    DELETE(`/quotes/${id}`, {
      query: {
        productId,
      },
    }),

  onChange: sortWith([
    descend(path(["aggregate", "score"])),
    descend(prop("createdAt")),
  ]),
})
