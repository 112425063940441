const debug = require("debug")("mutant:UISuggestionsItem")

import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { map } from "@mutant-ws/m"

import { deepReactMemo } from "/core.hooks/use-deep"

import css from "./suggestions-item.css"

const UISuggestionsItem = ({
  name,
  description,
  paramIndex,
  params,
  isFocus,
  onMouseDown,
  onMouseEnter,
}) => {
  return (
    <li
      className={cx({
        [css["is-focus"]]: isFocus,
      })}
      onMouseDown={() => onMouseDown(name)}
      onMouseEnter={() => onMouseEnter(name)}>
      <span className={css.name}>{name}</span>

      <span className={css.params}>
        {map(({ name: paramName, isRequired = false }, index) => (
          <span
            key={paramName}
            className={cx(css.param, {
              [css["param--is-active"]]: paramIndex === index,
            })}>
            {paramName}
            {isRequired === true && (
              <span className={css["param--is-required"]} />
            )}
          </span>
        ))(params)}
      </span>

      <span className={css.description}>{description}</span>
    </li>
  )
}

UISuggestionsItem.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  paramIndex: PropTypes.number,
  params: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isRequired: PropTypes.bool,
      options: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  isFocus: PropTypes.bool,
  onMouseDown: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
}

UISuggestionsItem.defaultProps = {
  description: "",
  paramIndex: null,
  params: [],
  isFocus: false,
}

const memo = deepReactMemo(UISuggestionsItem)

export { memo as UISuggestionsItem }
