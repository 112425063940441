const debug = require("debug")("mutant:UIVxTooltip")

import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { is, map } from "@asd14/m"

import { deepReactMemo } from "../../core.hooks/use-deep"

import css from "./vx-tooltip.css"

const UIVxTooltip = ({ className, style, title, values }) => {
  return (
    <div
      className={cx(css.tooltip, {
        [className]: is(className),
      })}
      style={style}>
      <p className={css.tooltip__title}>{title}</p>
      {map(
        ({ id, color, text }) => (
          <p key={id} className={css.tooltip__item}>
            <span
              className={css.tooltip__legend}
              style={{
                backgroundColor: color,
              }}
            />
            {` ${text}`}
          </p>
        ),
        values
      )}
    </div>
  )
}

UIVxTooltip.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({
    maxWidth: PropTypes.string,
    top: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
  }),
  title: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      color: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
}

UIVxTooltip.defaultProps = {
  className: null,
  style: {},
  title: "",
  values: [],
}

const memo = deepReactMemo(UIVxTooltip)

export { memo as UIVxTooltip }
