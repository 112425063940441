const debug = require("debug")("mutant:useQuery")

import { useHistory, useLocation } from "react-router-dom"

import { useCallback } from "/core.hooks/use-deep"
import { buildQueryString, parseQuery } from "/core.libs/routes"

/**
 * Get/Set query params using `qs` library
 *
 * @returns {Object}
 */
const useQuery = () => {
  const { search } = useLocation()
  const history = useHistory()
  const queryParams = parseQuery(search)

  return [
    queryParams,

    useCallback(
      props => {
        history.push({
          search: buildQueryString({
            ...queryParams,
            ...props,
          }),
        })
      },
      [history, queryParams]
    ),
  ]
}

export { useQuery }
