const debug = require("debug")("mutant:InsightsList")

import { buildList } from "@mutant-ws/redux-list"
import { sort, pipe, path, map, mergeRight } from "ramda"
import { GET, POST, DELETE, PATCH } from "@mutant-ws/fetch-browser"

export const InsightsList = buildList({
  name: "PRODUCT.PLAN.INSIGHTS",

  create: ({ productId, body }) =>
    POST("/insights", {
      body: { productId, body },
    }),

  read: ({ productId }) =>
    GET("/insights", {
      query: {
        filter: { productId },
        pluck: ["body", "isInbox", "aggregate"],
      },
    }),

  update: (id, { productId, body }) =>
    PATCH(`/insights/${id}`, {
      body: { productId, body },
    }),

  remove: (id, { productId }) =>
    DELETE(`/insights/${id}`, { query: { productId } }),

  onChange: pipe(
    map(mergeRight({ isInbox: false })),
    sort((a, b) => {
      const aQuotes = path(["aggregate", "quotesCount"], a)
      const bQuotes = path(["aggregate", "quotesCount"], b)

      return a.isInbox
        ? -1
        : b.isInbox
        ? 1
        : aQuotes === bQuotes
        ? a.body <= b.body
        : aQuotes <= bQuotes
    })
  ),
})
