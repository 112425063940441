/* eslint-disable react/no-multi-comp  */
const debug = require("debug")("mutant:FieldMetricUI")

import React, { useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { map, prop, propOr, reduceWhile } from "ramda"
import { hasWith, is, isEmpty } from "@mutant-ws/m"
import { ParentSize } from "@vx/responsive"
import { scaleOrdinal } from "d3-scale"

import { deepReactMemo, useMemo } from "/core.hooks/use-deep"

import { UIFieldCode } from "/core.ui/field-code/field-code"
import { UIVxLegend } from "/core.ui/vx-legend/vx-legend"
import { UIVxArea } from "/core.ui/vx-area/vx-area"
import { UIVxBar } from "/core.ui/vx-bar/vx-bar"
import { UIErrorBoundary } from "/core.ui/error-boundary/error-boundary"

import css from "./field-metric.css"

const FieldMetricUI = ({
  className,
  id,
  title,
  language,
  code,
  type,
  dataSets,
  isFocus,
  isEdit,
  onClick,
  onDoubleClick,
  onCodeSubmit,
  onCodeCancel,
}) => {
  const [error, setError] = useState()
  const [localCode, setLocalCode] = useState()
  const [highlightX, setHighlightX] = useState()

  // keep state code in sync
  useEffect(() => {
    setLocalCode(code)
  }, [code])

  const handleHightlightPoint = useCallback(source => {
    setHighlightX(is(source) ? source : null)
  }, [])

  //
  const handleCodeChange = useCallback(
    (value /* , event  */) => {
      try {
        JSON.parse(value)

        if (is(error)) {
          setError(null)
        }
      } catch (validationError) {
        setError(validationError)
      } finally {
        setLocalCode(value)
      }
    },
    [error]
  )

  const handleCodeSubmit = useCallback(
    value => {
      if (!is(error) && is(onCodeSubmit)) {
        onCodeSubmit(id, value)
      }
    },
    [id, error, onCodeSubmit]
  )

  const handleCodeCancel = useCallback(() => {
    if (is(onCodeCancel)) {
      onCodeCancel(id)

      setLocalCode(code)
      setError(null)
    }
  }, [id, code, onCodeCancel])

  const eventNames = map(prop("id"))(dataSets)
  const color = useMemo(
    () =>
      scaleOrdinal()
        .domain(eventNames)
        .range([
          "#6d76ca",
          "#ff6347",
          "#913596",
          "#ffa000",
          "#22b573",
          "#fc4b96",
          "#29abe2",
          "#90c300",
          "#a67c52",
          "#999999",
        ]),
    [eventNames]
  )
  const hasEventsPoints = reduceWhile(
    source => source === false,
    (acc, { points }) => {
      return acc ? acc : hasWith({ y: source => source !== 0 })(points)
    },
    false,
    dataSets
  )

  return (
    <div
      className={cx(css["metric-wrapper"], {
        [className]: is(className),
      })}>
      <div className={css.code}>
        {isFocus ? (
          <UIFieldCode
            id={id}
            value={localCode}
            error={propOr(null, "message", error)}
            language={language}
            isEdit={true}
            onChange={handleCodeChange}
            onEditModeCancel={handleCodeCancel}
            onEditModeBlur={handleCodeSubmit}
            onEditModeSubmit={handleCodeSubmit}
          />
        ) : null}
      </div>
      <div
        className={cx(css.metric, {
          [css["is-focus"]]: isFocus,
          [css["is-edit"]]: isEdit,
        })}
        onDoubleClick={event => {
          if (is(onDoubleClick)) {
            onDoubleClick(id, event)
          }
        }}
        onClick={event => {
          if (is(onClick)) {
            onClick(id, event)
          }
        }}>
        {isEmpty(title) ? null : <div className={css.title}>{title}</div>}

        {isEmpty(dataSets) && !isEdit ? (
          <div className={css["chart--empty"]}>
            ¯\_(ツ)_/¯
            <small>
              You need to measure to <strong>know</strong>.
            </small>
          </div>
        ) : null}

        <div className={css.legend}>
          <UIVxLegend size={15} color={color} />
        </div>

        {!hasEventsPoints && !isEmpty(dataSets) ? (
          <div className={css["chart--empty"]}>
            ¯\_(ツ)_/¯
            <small>No events registered yet</small>
          </div>
        ) : null}

        {hasEventsPoints ? (
          <div className={css.chart}>
            <ParentSize>
              {({ width, height }) => {
                if (type === "bar") {
                  return (
                    <UIVxBar
                      width={width}
                      height={height}
                      highlightX={highlightX}
                      color={color}
                      dataSets={dataSets}
                      hasAxisBottom={true}
                      hasTooltip={true}
                      onPointHover={handleHightlightPoint}
                    />
                  )
                }

                return (
                  <UIVxArea
                    width={width}
                    height={height}
                    highlightX={highlightX}
                    color={color}
                    dataSets={dataSets}
                    isSmooth={true}
                    hasAxisBottom={true}
                    hasTooltip={true}
                    onPointHover={handleHightlightPoint}
                  />
                )
              }}
            </ParentSize>
          </div>
        ) : null}
      </div>
    </div>
  )
}

FieldMetricUI.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  language: PropTypes.oneOf(["json"]),
  title: PropTypes.string,
  code: PropTypes.string.isRequired,
  type: PropTypes.string,
  dataSets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.instanceOf(Date).isRequired,
          y: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  isFocus: PropTypes.bool,
  isEdit: PropTypes.bool,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onCodeSubmit: PropTypes.func,
  onCodeCancel: PropTypes.func,
}

FieldMetricUI.defaultProps = {
  className: null,
  title: null,
  dataSets: [],
  language: "json",
  type: "area",
  isFocus: false,
  isEdit: false,
  onClick: null,
  onDoubleClick: null,
  onCodeSubmit: null,
  onCodeCancel: null,
}

const MemoFieldMetricUI = deepReactMemo(FieldMetricUI)

const FieldMetricUIWithBoundary = props => {
  return (
    <UIErrorBoundary message="Something inside this card has gone wrong. We got our best middle manager on it!">
      <MemoFieldMetricUI {...props} />
    </UIErrorBoundary>
  )
}

export { FieldMetricUIWithBoundary as FieldMetricUI }
