const debug = require("debug")("mutant:CommentsList")

import { buildList } from "@mutant-ws/redux-list"
import { GET, POST, PATCH, DELETE } from "@mutant-ws/fetch-browser"
import { sort } from "ramda"

export const CommentsList = buildList({
  name: "PRODUCT.WORK.CARD.COMMENTS",

  create: data =>
    POST("/comments", {
      body: data,
    }),

  read: ({ parentId }) =>
    GET(`/comments`, {
      query: {
        filter: { parentId },
        pluck: ["text", "createdAt", "updatedAt", "permissions"],
      },
    }),

  update: (id, data) =>
    PATCH(`/comments/${id}`, {
      body: data,
    }),

  remove: id => DELETE(`/comments/${id}`),

  // Sort from newest to oldest
  onChange: sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt)),
})
