/* eslint-disable react/no-multi-comp */
const debug = require("debug")("mutant:UIFieldCode")

import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { is } from "@mutant-ws/m"

// too much patching, use directly Ace in a custom component at some point
import "ace-builds/src-noconflict/ace"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/mode-javascript"
import "ace-builds/src-noconflict/mode-batchfile"
import "ace-builds/src-noconflict/mode-markdown"
import "ace-builds/src-noconflict/theme-clouds"
import AceEditor from "react-ace"

import css from "./field-code.css"

const UIFieldCode = ({
  className,
  id,
  value,
  error,
  language,
  minLines,
  isSelected,
  isEdit,
  isReadOnly,
  hasPrintMargin,
  hasLineNumbers,
  hasLineHighlight,
  shouldScroll,
  onChange,
  onViewModeClick,
  onViewModeDoubleClick,
  onEditModeCancel,
  onEditModeBlur,
  onEditModeSubmit,
}) => {
  const fieldRef = useRef()

  // scroll if selected
  useEffect(() => {
    if (is(fieldRef.current) && shouldScroll && isSelected) {
      // fieldRef.current.scrollIntoView({
      //   block: "center",
      //   behavior: "smooth",
      // })
    }
  }, [shouldScroll, isSelected])

  return (
    <div
      ref={fieldRef}
      className={cx(css.code, {
        [className]: is(className),
        [css["is-selected"]]: isSelected,
        [css["is-edit-mode"]]: isEdit,
        [css["is-view-mode"]]: !isEdit,
      })}
      onDoubleClick={() => {
        if (!isEdit && is(onViewModeDoubleClick)) {
          onViewModeDoubleClick(id)
        }
      }}
      onClick={() => {
        if (!isEdit && is(onViewModeClick)) {
          onViewModeClick(id)
        }
      }}>
      <AceEditor
        mode={language === "bash" ? "batchfile" : language}
        theme="clouds"
        value={value}
        width="100%"
        maxLines={15}
        minLines={minLines}
        wrapEnabled={true}
        focus={false}
        highlightActiveLine={hasLineHighlight}
        showPrintMargin={hasPrintMargin}
        showGutter={hasLineNumbers}
        readOnly={isReadOnly}
        editorProps={{ $blockScrolling: true }}
        setOptions={{
          showFoldWidgets: false,
          useWorker: false,
        }}
        commands={
          isReadOnly
            ? []
            : [
                {
                  name: "Revert and Exit",
                  bindKey: { win: "Escape", mac: "Escape" },
                  exec: () => {
                    is(onEditModeCancel) && onEditModeCancel()
                  },
                },
                {
                  name: "Save and Exit",
                  bindKey: { win: "Shift+Enter", mac: "Shift+Enter" },
                  exec: editor => {
                    is(onEditModeSubmit) && onEditModeSubmit(editor.getValue())
                  },
                },
              ]
        }
        onBlur={() => is(onEditModeBlur) && onEditModeBlur(value)}
        onChange={onChange}
      />
      {is(error) && <div className={css.error}>{error}</div>}
    </div>
  )
}

UIFieldCode.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  language: PropTypes.oneOf(["json", "markdown", "javascript", "bash"]),
  minLines: PropTypes.number,
  isSelected: PropTypes.bool,
  isEdit: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  hasPrintMargin: PropTypes.bool,
  hasLineNumbers: PropTypes.bool,
  hasLineHighlight: PropTypes.bool,
  shouldScroll: PropTypes.bool,
  onChange: PropTypes.func,
  onViewModeClick: PropTypes.func,
  onViewModeDoubleClick: PropTypes.func,
  onEditModeCancel: PropTypes.func,
  onEditModeBlur: PropTypes.func,
  onEditModeSubmit: PropTypes.func,
}

UIFieldCode.defaultProps = {
  className: null,
  value: "",
  error: null,
  language: "json",
  minLines: 1,
  isSelected: false,
  isEdit: false,
  isReadOnly: false,
  hasPrintMargin: false,
  hasLineNumbers: true,
  hasLineHighlight: true,
  shouldScroll: false,
  onChange: null,
  onViewModeClick: null,
  onViewModeDoubleClick: null,
  onEditModeCancel: null,
  onEditModeBlur: null,
  onEditModeSubmit: null,
}

const memo = React.memo(UIFieldCode)

export { memo as UIFieldCode }
