const debug = require("debug")("mutant:useYank")

import { useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import { is } from "@mutant-ws/m"

import { STORE_KEY, selector } from "./reducers"

export const useYank = () => {
  const dispatch = useDispatch()
  const { id, model, onPaste } = useSelector(selector)

  return {
    id,
    model,

    paste: useCallback(
      (...args) => {
        if (is(onPaste)) {
          onPaste(...args)

          dispatch({
            type: `${STORE_KEY}.CLEAR`,
          })
        }
      },
      [onPaste, dispatch]
    ),

    yank: useCallback(
      data => {
        dispatch({
          type: `${STORE_KEY}.YANK`,
          payload: data,
        })
      },
      [dispatch]
    ),

    clear: useCallback(() => {
      dispatch({
        type: `${STORE_KEY}.CLEAR`,
      })
    }, [dispatch]),
  }
}
