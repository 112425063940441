const debug = require("debug")("mutant:WorkContainer")

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { is } from "@mutant-ws/m"

import { useQuery } from "../core.hooks/use-query"
import {
  useFocus,
  WORK_LAYER,
  WORK_BOARD_LAYER,
  WORK_CARD_LAYER,
} from "../core.hooks/use-focus"

import { CardContainer } from "../product.work.card/card.container"
import { BoardContainer } from "../product.work.board/board.container"

const WorkContainer = ({ productId, members }) => {
  const [{ "work-id": queryCardId }] = useQuery()
  const [{ layer }, setFocus] = useFocus(productId)

  // default selected workspace
  useEffect(() => {
    if (layer === WORK_LAYER) {
      if (is(queryCardId)) {
        setFocus({ layer: WORK_CARD_LAYER })
      } else {
        setFocus({ layer: WORK_BOARD_LAYER })
      }
    }
  }, [queryCardId, layer, setFocus])

  return is(queryCardId) ? (
    <CardContainer productId={productId} members={members} />
  ) : (
    <BoardContainer productId={productId} members={members} />
  )
}

WorkContainer.propTypes = {
  productId: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
    })
  ),
}

WorkContainer.defaultProps = {
  members: [],
}

export { WorkContainer }
