const debug = require("debug")("mutant:UITopMenu")

import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { NavLink } from "react-router-dom"

import { getPath } from "/core.libs/routes"
import { UILogo } from "/core.ui/logo/logo"

import css from "./topmenu.css"

const UITopMenu = ({ isLoggedIn, isLoginActive, isDemoActive, onClick }) => (
  <React.Fragment>
    <div className={css["menu-distancer"]} />
    <div className={css["menu-wrapper"]}>
      <ul className={css.menu}>
        <li className={css.logo}>
          <NavLink to={getPath("home")} onClick={onClick("logo")}>
            <UILogo height="22px" />
          </NavLink>
        </li>

        <li />

        <li>
          <span
            className={cx(css.link, {
              [css["link--is-active"]]: isDemoActive,
            })}
            onClick={onClick("demo")}>
            Demo
          </span>
        </li>
        <li>
          <NavLink
            className={css.link}
            activeClassName={css["link--is-active"]}
            to={getPath("pricing")}
            onClick={onClick("pricing")}>
            Pricing
          </NavLink>
        </li>

        <li>
          <NavLink
            className={css.link}
            activeClassName={css["link--is-active"]}
            to={getPath("contact")}
            onClick={onClick("contact")}>
            Contact
          </NavLink>
        </li>

        <li>
          <span
            className={cx(css.link, css["link--login"], {
              [css["link--is-active"]]: isLoginActive,
            })}
            onClick={onClick("login")}>
            {isLoggedIn ? "Account" : "Login"}
          </span>
        </li>
      </ul>
    </div>
  </React.Fragment>
)

UITopMenu.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isLoginActive: PropTypes.bool.isRequired,
  isDemoActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

const memo = React.memo(UITopMenu)

export { memo as UITopMenu }
