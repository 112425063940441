const debug = require("debug")("mutant:ProductsContainer")

import React, { useEffect } from "react"
import { map } from "@mutant-ws/m"

import { useList } from "../../core.hooks/use-list"
import { ProductsList } from "./data/list.products"

import { ProductView } from "./product.view"

const ProductsContainer = () => {
  const {
    selector: { items },
    read,
  } = useList(ProductsList)

  // data fetching
  useEffect(() => {
    read()
  }, [read])

  return (
    <React.Fragment>
      {map(({ id, name, integrations }) => (
        <ProductView key={id} id={id} name={name} integrations={integrations} />
      ))(items())}
    </React.Fragment>
  )
}

export { ProductsContainer }
