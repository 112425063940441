const debug = require("debug")("mutant:Product.Work.Board.MetricsList")

import { buildList } from "@mutant-ws/redux-list"
import { GET } from "@mutant-ws/fetch-browser"
import { map, mergeRight } from "ramda"

export const MetricsList = buildList({
  name: "PRODUCT.WORK.BOARD.FEATURES.METRICS",

  read: ({ productId, id }) =>
    GET("/metrics", {
      query: {
        filter: {
          id,
          productId,
        },
        pluck: ["title", "code", "isKPI"],
      },
    }),

  // default values
  onChange: map(mergeRight({ code: [] })),
})
