const debug = require("debug")("mutant:WorkColumnUI")

import React from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import { count, map, findWith, hasWith, filterWith } from "@mutant-ws/m"

import { deepReactMemo } from "/core.hooks/use-deep"

import { WorkCardUI } from "./card"
import css from "./column.css"

const WorkColumnUI = ({
  featureSelectId,
  focusId,
  editId,
  title,
  members,
  membersPresent,
  work,
  onCardClick,
  onCardDoubleClick,
  onCardTitleEditUpdate,
  onCardTitleEditCancel,
}) => {
  return (
    <div className={css.column}>
      <h2 className={css.title}>
        {title}&nbsp;
        <small>{count(work)}</small>
      </h2>

      <SimpleBar autoHide={true} style={{ height: "calc(100% - 60px)" }}>
        <div className={css.content}>
          {map(
            ({
              id: cardId,
              title: cardTitle,
              featureId: cardFeatureId,
              assignedTo: cardAssignedTo,
              isBug: cardIsBug,
            }) => {
              const {
                userId: assignedToId,
                name: assignedToName,
                avatarURL: assignedToAvatarURL,
                bgColor: assignedToBgColor,
                fgColor: assignedToFgColor,
              } = findWith({ userId: cardAssignedTo }, {})(members)

              return (
                <WorkCardUI
                  key={cardId}
                  id={cardId}
                  title={cardTitle}
                  userId={assignedToId}
                  userName={assignedToName}
                  userAvatarURL={assignedToAvatarURL}
                  userBgColor={assignedToBgColor}
                  userFgColor={assignedToFgColor}
                  usersFocusing={filterWith({
                    userId: source =>
                      hasWith({
                        userId: source,
                        cardId,
                      })(membersPresent),
                  })(members)}
                  isBug={cardIsBug}
                  isSelect={cardFeatureId === featureSelectId}
                  isFocus={cardId === focusId}
                  isEdit={cardId === editId}
                  onClick={onCardClick}
                  onDoubleClick={onCardDoubleClick}
                  onTitleEditUpdate={onCardTitleEditUpdate}
                  onTitleEditCancel={onCardTitleEditCancel}
                />
              )
            }
          )(work)}
        </div>
      </SimpleBar>
    </div>
  )
}

WorkColumnUI.propTypes = {
  featureSelectId: PropTypes.string,
  focusId: PropTypes.string,
  editId: PropTypes.string,
  title: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
      bgColor: PropTypes.string,
      fgColor: PropTypes.string,
      isEdit: PropTypes.bool,
      isOnline: PropTypes.bool,
    })
  ).isRequired,
  membersPresent: PropTypes.arrayOf(
    PropTypes.shape({
      cardId: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
    })
  ),
  work: PropTypes.arrayOf(
    PropTypes.shape({
      featureId: PropTypes.string,
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      type: PropTypes.string,
      assignedTo: PropTypes.string,
      position: PropTypes.number,
    })
  ),
  onCardClick: PropTypes.func.isRequired,
  onCardDoubleClick: PropTypes.func.isRequired,
  onCardTitleEditUpdate: PropTypes.func.isRequired,
  onCardTitleEditCancel: PropTypes.func.isRequired,
}

WorkColumnUI.defaultProps = {
  featureSelectId: null,
  focusId: null,
  editId: null,
  membersPresent: [],
  work: [],
}

const memo = deepReactMemo(WorkColumnUI)

export { memo as WorkColumnUI }
