const debug = require("debug")("mutant:FieldQuoteUI")

import React, { useEffect, useRef } from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import marked from "marked"
import { DateTime } from "luxon"
import { is, isEmpty } from "@mutant-ws/m"
import { trim } from "ramda"

import { deepReactMemo } from "/core.hooks/use-deep"

import { UIFocusers } from "/core.ui/focusers/focusers"
import { UITag } from "/core.ui/tag/tag"

import css from "./field-quote.css"

const FieldQuoteUI = ({
  className,
  id,
  score,
  contact,
  source,
  body,
  createdAt,
  usersFocusing,
  isFocus,
  onClick,
}) => {
  const fieldRef = useRef()

  useEffect(() => {
    if (is(fieldRef.current) && isFocus) {
      // fieldRef.current.scrollIntoView({
      //   block: "center",
      //   behavior: "smooth",
      // })
    }
  }, [isFocus])

  return (
    <div
      ref={fieldRef}
      className={cx(css.quote, {
        [className]: is(className),
        [css["is-focus"]]: isFocus,
      })}
      onClick={() => onClick(id)}>
      <UIFocusers users={usersFocusing} />

      <UITag className={css.tag} text="feedback" color="blue" size="small" />

      <div className={css.vote}>
        <div className={cx(css["vote-up"])}>↑</div>
        {score}
        <div className={cx(css["vote-down"])}>↓</div>
      </div>

      <div className={css.contact}>
        {isEmpty(contact) ? (
          <span className={css.placeholder}>Unknown</span>
        ) : (
          contact
        )}
      </div>

      <div className={css["created-at"]}>
        {DateTime.fromISO(createdAt).toRelative()}
      </div>

      <div className={css.body}>
        <div
          dangerouslySetInnerHTML={{
            __html: marked(isEmpty(trim(body)) ? "" : body),
          }}
        />
        <div className={css.source}>
          {isEmpty(source) ? (
            <span className={css.placeholder}>Unknown</span>
          ) : (
            source
          )}
        </div>
      </div>
    </div>
  )
}

FieldQuoteUI.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  score: PropTypes.number,
  contact: PropTypes.string,
  source: PropTypes.string,
  body: PropTypes.string,
  createdAt: PropTypes.string,
  usersFocusing: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
      isOnline: PropTypes.bool,
      location: PropTypes.shape({
        status: PropTypes.oneOf(["read", "update"]),
      }),
    })
  ),
  isFocus: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

FieldQuoteUI.defaultProps = {
  className: null,
  score: null,
  contact: "",
  source: "",
  body: "",
  createdAt: null,
  usersFocusing: [],
  isFocus: false,
}

const memo = deepReactMemo(FieldQuoteUI)

export { memo as FieldQuoteUI }
