const debug = require("debug")("mutant:CardsList")

import { buildList } from "@mutant-ws/redux-list"
import { GET, POST, PATCH, DELETE } from "@mutant-ws/fetch-browser"
import { prop, sortWith, ascend } from "ramda"

export const CardsList = buildList({
  name: "PRODUCT.WORK.BOARD.CARDS",

  create: data =>
    POST("/cards", {
      body: {
        ...data,
        type: "work",
      },
    }),

  read: ({ productId }) =>
    GET("/cards", {
      query: {
        filter: {
          productId,
          type: ["work"],
        },
        pluck: [
          "featureId",
          "title",
          "assignedTo",
          "status",
          "type",
          "position",
          "createdAt",
          "isBug",
        ],
      },
    }),

  update: (id, data) =>
    PATCH(`/cards/${id}`, {
      body: data,
    }),

  remove: id => DELETE(`/cards/${id}`),

  onChange: sortWith([
    ascend(({ status }) =>
      status === "backlog"
        ? 1
        : status === "doing"
        ? 2
        : status === "testing"
        ? 3
        : 4
    ),
    ascend(prop("position")),
  ]),

  onPush: () => {},
})
