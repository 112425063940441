const debug = require("debug")("mutant:UISuggestionsItemExtended")

import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { pipe, map, join, partition, is } from "@mutant-ws/m"

import { deepReactMemo } from "/core.hooks/use-deep"

import css from "./suggestions-item--extended.css"

const isTop = (value, index, array) => index < array.length - 1

const niceToString = pipe(
  partition(isTop),
  ([first, last]) => [join("</code>, <code>")(first), last],
  join("</code> or <code>"),
  source => `<code>${source}</code>`
)

const UISuggestionsItemExtended = ({
  name,
  description,
  paramIndex,
  params,
  isFocus,
  onMouseDown,
  onMouseEnter,
}) => {
  return (
    <li
      className={cx({
        [css["is-focus"]]: isFocus,
      })}
      onMouseDown={() => onMouseDown(name)}
      onMouseEnter={() => onMouseEnter(name)}>
      <div className={css.name}>
        {name}

        {map(({ name: paramName, isRequired = false }, index) => (
          <span
            key={paramName}
            className={cx(css.param, {
              [css["param--is-active"]]: paramIndex === index,
            })}>
            {paramName}
            {isRequired === true && (
              <span className={css["param--is-required"]} />
            )}
          </span>
        ))(params)}
      </div>

      <div className={css.description}>{description}</div>

      <div className={css.params}>
        {map(
          (
            {
              name: paramName,
              description: paramDescription,
              options,
              isRequired = false,
            },
            index
          ) => (
            <div key={paramName} className={cx(css["param-details"])}>
              <span
                className={cx(css["param-name"], {
                  [css["param--is-active"]]: paramIndex === index,
                })}>
                {paramName}

                {isRequired === true ? (
                  <span className={css["param--is-required"]} />
                ) : null}
              </span>

              {is(paramDescription) ? (
                <div className={css["param-description"]}>
                  <div className={css["param-keyword"]}>[description]</div>
                  {paramDescription}
                </div>
              ) : null}

              {Array.isArray(options) ? (
                <div className={css["param-options"]}>
                  <div className={css["param-keyword"]}>[available values]</div>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: niceToString(options),
                    }}
                  />
                </div>
              ) : null}
            </div>
          )
        )(params)}
      </div>
    </li>
  )
}

UISuggestionsItemExtended.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  paramIndex: PropTypes.number,
  params: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isRequired: PropTypes.bool,
      options: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  isFocus: PropTypes.bool,
  onMouseDown: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
}

UISuggestionsItemExtended.defaultProps = {
  description: "",
  paramIndex: null,
  params: [],
  isFocus: false,
}

const memo = deepReactMemo(UISuggestionsItemExtended)

export { memo as UISuggestionsItemExtended }
