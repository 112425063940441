const debug = require("debug")("mutant:PricingPage")

import React from "react"
import { Helmet } from "react-helmet"

import { EarlyAccessContainer } from "/page.home/_.early-access/early-access.container"
import { UITag } from "/core.ui/tag/tag"

import css from "./pricing.css"

const PricingPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Pricing</title>
      </Helmet>
      <div className={css.hero}>
        <div className={css.content}>
          <h1>
            <em>Essential</em> tooling,
            <br /> one single workspace
          </h1>

          <h2>
            Bring your product development team on Mutant. <br />
            We’d love to empower you to build a better product, faster!
          </h2>

          <EarlyAccessContainer location="pricing-hero" isCenter={true} />
        </div>
      </div>

      <div className={css.content}>
        <p className={css["for-all"]}>
          ✓ Free 15 days trial &nbsp;&nbsp;&nbsp;✓ No credit card required
          &nbsp;&nbsp;&nbsp;✓ Pay as you grow
        </p>
        <div className={css.tiers}>
          <div className={css.price}>
            <UITag text="bootstrap" />
            <h2>
              $29 / mo
              <small>billed annually or $36 monthly.</small>
            </h2>
            <div className={css.limits}>
              <p>Team:</p>
              <p>up to 5 members</p>

              <p>Workspace:</p>
              <p>
                ✓ Feedback board
                <br />
                ✓ Issue tracker
                <br />
                ✓ Analytics API
                <br />
              </p>

              <p>User actions:</p>
              <p>50,000/month</p>

              <p>Data retention:</p>
              <p>forever</p>
            </div>
            <hr />
            $6 for extra 10,000 user actions / month
          </div>
          <div className={css.price}>
            <UITag text="start" />
            <h2>
              $99 / mo
              <small>billed annually or $131 monthly.</small>
            </h2>
            <div className={css.limits}>
              <p>Team:</p>
              <p>up to 10 members</p>
              <p>Workspace:</p>
              <p>
                ✓ Feedback board
                <br />
                ✓ Issue tracker
                <br />
                ✓ Analytics API
                <br />
              </p>
              <p>User actions:</p>
              <p>250,000/month</p>
              <p>Data retention:</p>
              <p>forever</p>
            </div>
            <hr />
            $19 for extra 50,000 user actions / month
          </div>
          <div className={css.price}>
            <UITag text="scale" />
            <h2>
              $249 / mo
              <small>billed annually or $324 monthly.</small>
            </h2>
            <div className={css.limits}>
              <p>Team:</p>
              <p>up to 15 members</p>
              <p>Workspace:</p>
              <p>
                ✓ Feedback board
                <br />
                ✓ Issue tracker
                <br />
                ✓ Analytics API
                <br />
              </p>
              <p>User actions:</p>
              <p>1,000,000/month</p>
              <p>Data retention:</p>
              <p>forever</p>
            </div>
            <hr />
            $59 for extra 250,000 user actions / month
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export { PricingPage }
