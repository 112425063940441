const debug = require("debug")("mutant:HelpUI")

import React from "react"
import cx from "classnames"

import { deepReactMemo } from "/core.hooks/use-deep"

import css from "./help.css"

const HelpUI = ({}) => {
  return (
    <div className={cx(css.help)}>
      <div className={css.group}>
        <h3>Moving around</h3>
        <ul>
          <li>
            <div className={css.shortcut}>
              <span className={css.key}>← ↑ ↓ →</span>
            </div>
            <div className={css["shortcut-description"]}>
              move between cards
            </div>
          </li>
          <li>
            <div className={css.shortcut}>
              <span className={css.key}>shift</span>
              <span className={css.key}>← ↑ ↓ →</span>
            </div>
            <div className={css["shortcut-description"]}>
              move card position
            </div>
          </li>
          <li>
            <div className={css.shortcut}>
              <span className={css.key}>1</span>
            </div>
            <div className={css["shortcut-description"]}>issue tracker</div>
          </li>
          <li>
            <div className={css.shortcut}>
              <span className={css.key}>2</span>
            </div>
            <div className={css["shortcut-description"]}>analytics</div>
          </li>
          <li>
            <div>
              <span className={css.key}>3</span>
            </div>
            <div className={css["shortcut-description"]}>feedback</div>
          </li>
        </ul>
      </div>

      <div className={css.group}>
        <h3>Command Line</h3>
        <ul>
          <li>
            <div>
              <span className={css.key}>/</span>
            </div>
            <div className={css["shortcut-description"]}>open command line</div>
          </li>
        </ul>
      </div>
    </div>
  )
}

HelpUI.propTypes = {}

HelpUI.defaultProps = {}

const memo = deepReactMemo(HelpUI)

export { memo as HelpUI }
