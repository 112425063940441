const debug = require("debug")("mutant:FieldsList")

import { buildList } from "@mutant-ws/redux-list"
import { is } from "@mutant-ws/m"
import { GET, POST, PATCH, MULTIPART, DELETE } from "@mutant-ws/fetch-browser"
import { prop, sortBy } from "ramda"

export const FieldsList = buildList({
  name: "PRODUCT.WORK.CARD.FIELDS",

  read: ({ productId, cardId }) =>
    GET(`/fields`, {
      query: {
        filter: { productId, cardId },
        pluck: ["data", "type", "position"],
      },
    }),

  create: ({ productId, cardId, template, type, position, data }) =>
    POST(`/fields`, {
      body: is(template)
        ? {
            productId,
            cardId,
            template,
          }
        : {
            productId,
            cardId,
            type,
            position,
            data,
          },
    }),

  update: (id, { cardId, file, position, value }, { action = "" }) => {
    if (action === "upload") {
      return MULTIPART(`/fields/${id}/upload`, {
        body: {
          cardId,
          file,
        },
      })
    }

    return PATCH(`/fields/${id}`, {
      body: {
        position,
        value,
      },
    })
  },

  remove: id => DELETE(`/fields/${id}`),

  // default values
  onChange: sortBy(prop("position")),
})
