const debug = require("debug")("mutant:Router")

import React from "react"
import { Route, BrowserRouter, Switch } from "react-router-dom"

import { getPath } from "/core.libs/routes"
import { useTrack } from "/core.hooks/use-track"

import { HomePage } from "/page.home/home"
import { FeaturesPage } from "/page.features/features"
import { FAQPage } from "/page.faq/faq"
import { ProfilePage } from "/page.profile/profile"
import { PricingPage } from "/page.pricing/pricing"
import { ContactPage } from "/page.contact/contact"
import { NotFoundPage } from "/page.not-found/not-found"
import { DemoPage } from "/page.demo/demo"
// import { TestPage } from "/page.test/test"

import { LoginPage } from "/page.login/login"
import { ProductPage } from "/page.product/product"

import { GuestRoute } from "./route.guest"
import { UserRoute } from "./route.user"

const MutantRoutes = () => {
  // track page view when route changes (needs to be a child of BrowserRouter
  // to have access to location data)
  useTrack()

  return (
    <Switch>
      <GuestRoute path={getPath("home")} component={HomePage} exact={true} />
      <GuestRoute path={getPath("features")} component={FeaturesPage} />
      <GuestRoute path={getPath("pricing")} component={PricingPage} />
      <GuestRoute path={getPath("contact")} component={ContactPage} />
      <GuestRoute path={getPath("faq")} component={FAQPage} />
      {
        // <GuestRoute path={getPath("test")} component={TestPage} />
      }

      <Route path={getPath("demo:product")} component={DemoPage} />
      <Route path={getPath("demo:login")} component={DemoPage} />

      {
        // Only for un-authenticated users
      }
      <GuestRoute
        path={getPath("users:login")}
        component={LoginPage}
        isExclusive={true}
        hasLayout={false}
      />

      {
        // Only for authenticated users
      }
      <UserRoute path={getPath("users:profile")} component={ProfilePage} />
      <UserRoute path={getPath("products:view")} component={ProductPage} />

      {
        // 404
      }
      <Route component={NotFoundPage} />
    </Switch>
  )
}

/* eslint-disable react/no-multi-comp */

export const MutantRouter = () => {
  return (
    <BrowserRouter>
      <MutantRoutes />
    </BrowserRouter>
  )
}
