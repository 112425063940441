const debug = require("debug")("mutant:UIAvatarGroup")

import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { top, pipe, map, count, when, gt, isEmpty, is } from "@mutant-ws/m"

import { deepReactMemo } from "/core.hooks/use-deep"

import { UITag } from "/core.ui/tag/tag"
import { UIAvatar } from "/core.ui/avatar/avatar"

import css from "./group.css"

const UIAvatarGroup = ({ className, users, limit }) => {
  return isEmpty(users) ? null : (
    <div
      className={cx(css.group, {
        [className]: is(className),
      })}>
      {pipe(
        top(limit),
        map(user => (
          <UIAvatar
            key={user.id}
            className={css.avatar}
            name={user.name}
            avatar={user.avatarURL}
            size={20}
            backgroundColor={user.bgColor}
            foregroundColor={user.fgColor}
          />
        ))
      )(users)}
      {pipe(
        count,
        when(
          gt(limit),
          usersFocusingCount => (
            <UITag
              className={css["avatar-rest"]}
              text={`${usersFocusingCount - limit}`}
              size="small"
            />
          ),
          ""
        )
      )(users)}
    </div>
  )
}

UIAvatarGroup.propTypes = {
  className: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isEdit: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      bgColor: PropTypes.string,
      fgColor: PropTypes.string,
      avatarURL: PropTypes.string,
    })
  ),
  limit: PropTypes.number,
}

UIAvatarGroup.defaultProps = {
  className: null,
  users: [],
  limit: 2,
}

const memo = deepReactMemo(UIAvatarGroup)

export { memo as UIAvatarGroup }
