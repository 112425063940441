const debug = require("debug")("mutant:FieldImageUI")

import React, { useRef, useEffect, forwardRef } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { is } from "@mutant-ws/m"

import { UIFocusers } from "/core.ui/focusers/focusers"

import placeholderPath from "./placeholder.png"
import css from "./field-image.css"

const FieldImageUI = forwardRef(
  (
    {
      className,
      id,
      thumbnailURL,
      label,
      usersFocusing,
      isFocus,
      isEdit,
      onFileChoose,
      onFileBrowse,
      onSelect,
      onFullScreen,
    },
    ref
  ) => {
    const fieldRef = useRef()

    useEffect(() => {
      if (is(fieldRef.current) && isFocus) {
        // fieldRef.current.scrollIntoView({
        //   block: "center",
        //   behavior: "smooth",
        // })
      }
    }, [isFocus, fieldRef])

    return (
      <div
        ref={fieldRef}
        className={cx(css.field, {
          [className]: is(className),
          [css["field--is-focus"]]: isFocus,
          [css["field--is-edit"]]: isEdit,
          [css["field--with-image"]]: is(thumbnailURL),
        })}
        onClick={() => {
          onSelect(id)
        }}
        onDoubleClick={() => {
          onFileBrowse(id)
        }}>
        <UIFocusers users={usersFocusing} isMeEdit={isEdit} />

        <div className={css.label}>{label}</div>

        <div className={css.image}>
          <img
            src={is(thumbnailURL) ? thumbnailURL : placeholderPath}
            onClick={() => {
              if (is(thumbnailURL)) {
                onFullScreen(id)
              } else {
                onFileBrowse(id)
              }
            }}
          />
        </div>

        <input
          className={css.input}
          ref={ref}
          type="file"
          accept="image/png,image/jpeg,image/gif"
          multiple={false}
          onChange={event => {
            onFileChoose({ id, event })
          }}
        />
      </div>
    )
  }
)

FieldImageUI.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  thumbnailURL: PropTypes.string,
  label: PropTypes.string,
  usersFocusing: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
      isOnline: PropTypes.bool,
      location: PropTypes.shape({
        status: PropTypes.oneOf(["read", "update"]),
      }),
    })
  ),
  isFocus: PropTypes.bool,
  isEdit: PropTypes.bool,
  onFileChoose: PropTypes.func.isRequired,
  onFileBrowse: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onFullScreen: PropTypes.func.isRequired,
}

FieldImageUI.defaultProps = {
  className: null,
  thumbnailURL: null,
  label: null,
  usersFocusing: [],
  isFocus: false,
  isEdit: false,
}

const memo = React.memo(FieldImageUI)

export { memo as FieldImageUI }
