const debug = require("debug")("mutant:UIVxLegend")

import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { min, is } from "@mutant-ws/m"
import { map, keys } from "ramda"

import { deepReactMemo } from "../../core.hooks/use-deep"

import css from "./vx.legend.css"

const UIVxLegend = ({ className, color, size, shape }) => {
  const count = min([5, color.domain().length])

  return (
    <span
      className={cx(css.legend, {
        [className]: is(className),
      })}
      style={{
        gridTemplateColumns: `repeat(${count}, ${size}px minmax(auto, max-content))`,
      }}>
      {map(item => (
        <React.Fragment key={item}>
          <span
            style={{
              backgroundColor: color(item),
              width: size,
              height: shape === "line" ? 3 : size,
              marginTop: shape === "line" ? 15 / 2 - 3 / 2 : 0,
            }}
          />
          <span className={cx(css.item__text)}>{item}</span>
        </React.Fragment>
      ))(color.domain())}
    </span>
  )
}

UIVxLegend.propTypes = {
  className: PropTypes.string,
  color: PropTypes.func.isRequired,
  size: PropTypes.number,
  shape: PropTypes.oneOf(["line", "square"]),
}

UIVxLegend.defaultProps = {
  className: null,
  size: 10,
  shape: "line",
}

const memo = deepReactMemo(UIVxLegend, keys(UIVxLegend.propTypes))

export { memo as UIVxLegend }
