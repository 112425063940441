const debug = require("debug")("mutant:Product.Work.Board.CardsList")

import { buildList } from "@mutant-ws/redux-list"
import { GET, PATCH, POST, DELETE } from "@mutant-ws/fetch-browser"
import { sortBy, prop } from "ramda"

export const FeaturesList = buildList({
  name: "PRODUCT.WORK.BOARD.FEATURES.CARDS",

  create: data =>
    POST("/cards", {
      body: {
        ...data,
        type: "feature",
      },
    }),

  read: ({ productId }) =>
    GET("/cards", {
      query: {
        filter: {
          productId,
          type: ["feature"],
          isDraft: false,
        },
        pluck: [
          "componentId",
          "assignedTo",
          "aggregate",
          "coverMetricId",
          "coverURL",
          "title",
          "position",
        ],
      },
    }),

  readOne: id =>
    GET(`/cards/${id}`, {
      query: {
        pluck: ["fields", "createdAt", "doingAt", "testingAt", "doneAt"],
      },
    }),

  update: (id, data) =>
    PATCH(`/cards/${id}`, {
      body: data,
    }),

  remove: id => DELETE(`/cards/${id}`),

  onChange: sortBy(prop("position")),
})
