const debug = require("debug")("mutant:CardContainer")

import React, { useEffect, useCallback } from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import {
  pluck,
  map,
  when,
  count,
  startsWith,
  gt,
  top,
  is,
  isEmpty,
} from "@asd14/m"

import { useQuery } from "/core.hooks/use-query"
import { useColorScale } from "/core.hooks/use-color-scale"
import { useLiveList } from "/core.hooks/use-live-list"
import {
  useFocus,
  WORK_BOARD_FEATURES_LAYER,
  WORK_BOARD_COLUMNS_LAYER,
  WORK_CARD_LAYER,
  WORK_CARD_FIELDS_LAYER,
  WORK_CARD_SIBLINGS_LAYER,
  WORK_CARD_COMMENTS_LAYER,
} from "/core.hooks/use-focus"

import { CardsList } from "./data/list.cards"
import { SiblingsContainer } from "./_.siblings/siblings.container"
import { FieldsContainer } from "./_.fields/fields.container"
import { CommentsContainer } from "./_.comments/comments.container"

import css from "./card.css"

const CardContainer = ({ productId, members }) => {
  const [
    { "work-id": queryWorkId, "work-feature-id": queryFeatureId },
    setQuery,
  ] = useQuery()

  const [colorPicker, reverseColorPicker] = useColorScale(
    pluck("userId")(members)
  )
  const membersWithColors = map(
    item => ({
      ...item,
      bgColor: colorPicker(item.userId),
      fgColor: reverseColorPicker(item.userId),
    }),
    members
  )

  //
  // Fields - data fetching
  //

  const {
    selector: { byId },
    readOne,
    update,
  } = useLiveList(CardsList, {
    events: {
      prefix: "cards",
      shouldAcceptFn: useCallback((data = {}) => data.id === queryWorkId, [
        queryWorkId,
      ]),
    },
  })

  useEffect(() => {
    if (is(queryWorkId)) {
      readOne(queryWorkId)
    }
  }, [queryWorkId, readOne])

  const {
    featureId: parentFeatureId,
    title,
    type,
    componentId,
    assignedTo,
  } = byId(queryWorkId, {})
  const featureId = type === "feature" ? queryWorkId : parentFeatureId

  //
  // Cards keyboard shortcuts
  //

  const [{ layer }, setFocus] = useFocus()
  const hasKeyboard = layer === WORK_CARD_LAYER

  useEffect(() => {
    if (hasKeyboard) {
      setFocus({ layer: WORK_CARD_FIELDS_LAYER })
    }
  }, [hasKeyboard, setFocus])

  const handleCardClose = useCallback(() => {
    setQuery({
      ["work-id"]: undefined,
    })

    setFocus({
      id: queryWorkId,
      layer:
        queryWorkId === queryFeatureId
          ? WORK_BOARD_FEATURES_LAYER
          : WORK_BOARD_COLUMNS_LAYER,
      status: "read",
    })
  }, [queryWorkId, queryFeatureId, setFocus, setQuery])

  const handleAssignedUserUpdate = useCallback(
    userId => {
      if (userId !== assignedTo) {
        update(queryWorkId, {
          assignedTo: userId === "unset" ? null : userId,
        })
      }
    },
    [assignedTo, queryWorkId, update]
  )

  const handleComponentUpdate = useCallback(
    source => update(queryWorkId, { componentId: source }),
    [queryWorkId, update]
  )

  const handleTitleUpdate = useCallback(
    source => update(queryWorkId, { title: source }),
    [queryWorkId, update]
  )

  return (
    <div className={css.card}>
      {startsWith(WORK_CARD_LAYER, layer) && is(title) ? (
        <Helmet
          title={when(
            [count, gt(30)],
            [top(30), source => `${source} ...`]
          )(title)}
        />
      ) : null}

      {isEmpty(featureId) ? (
        <div className={css.siblings} />
      ) : (
        <div
          className={css.siblings}
          onClick={() =>
            setFocus({
              layer: WORK_CARD_SIBLINGS_LAYER,
            })
          }>
          <SimpleBar autoHide={true} style={{ height: "100%" }}>
            <SiblingsContainer
              productId={productId}
              cardId={queryWorkId}
              featureId={featureId}
              members={membersWithColors}
              onEscape={handleCardClose}
            />
          </SimpleBar>
        </div>
      )}

      <div
        className={css.fields}
        onClick={() =>
          setFocus({
            layer: WORK_CARD_FIELDS_LAYER,
          })
        }>
        <SimpleBar autoHide={true} style={{ height: "100%" }}>
          <FieldsContainer
            productId={productId}
            componentId={componentId}
            type={type}
            title={title}
            members={membersWithColors}
            assignedTo={assignedTo}
            onAssigneeChange={handleAssignedUserUpdate}
            onComponentChange={handleComponentUpdate}
            onTitleChange={handleTitleUpdate}
            onEscape={handleCardClose}
          />
        </SimpleBar>
      </div>

      <div
        className={css.comments}
        onClick={() =>
          setFocus({
            layer: WORK_CARD_COMMENTS_LAYER,
          })
        }>
        <CommentsContainer
          productId={productId}
          parentId={queryWorkId}
          members={membersWithColors}
          onEscape={handleCardClose}
        />
      </div>
    </div>
  )
}

CardContainer.propTypes = {
  productId: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
    })
  ).isRequired,
}

CardContainer.defaultProps = {}

export { CardContainer }
