const debug = require("debug")("mutant:UIVxAreaLib")

import { path } from "ramda"

/**
 * Get min and max of a dataset on both axes
 *
 * @param {Object<x, y>[]} source Array of object points with .x and .y
 *
 * @return {Object<minX, maxX, minY, maxY>}
 */
export const getDomain = source => {
  let minX = path([0, "x"])(source),
    maxX = minX,
    minY = path([0, "y"])(source),
    maxY = minY

  // find min and max for both X and Y in loop
  for (let i = 0; i < source.length; ++i) {
    const x = source[i].x

    if (x >= maxX) {
      maxX = x
    } else {
      minX = x
    }

    const y = source[i].y

    if (y >= maxY) {
      maxY = y
    } else {
      minY = y
    }
  }

  return { minX, maxX, minY, maxY }
}
