const debug = require("debug")("mutant:useProductCLI")

import { useEffect, useCallback } from "react"
import { is } from "@mutant-ws/m"
import { HTTPError } from "@mutant-ws/fetch-browser"

import { useCommands } from "/core.hooks/use-commands"

export const useProductCLI = (sectionLayer, { onMemberInvite }) => {
  const handleInvite = useCallback(
    email =>
      onMemberInvite({ email }).then(({ error }) => {
        if (is(error)) {
          throw new HTTPError(error.body.message, {
            status: error.status,
          })
        }

        return {
          message: `Invitation sent to "${email}"`,
        }
      }),
    [onMemberInvite]
  )

  const [, [addCommands, removeCommands]] = useCommands()

  useEffect(() => {
    addCommands({
      layer: sectionLayer,
      commands: [
        {
          name: "invite",
          abstract: "add a team member",
          description: "add a team member",
          params: [
            {
              name: "email",
              isRequired: true,
            },
          ],
          onFinish: handleInvite,
        },
      ],
    })

    return () => removeCommands({ layer: sectionLayer })
  }, [sectionLayer, handleInvite, addCommands, removeCommands])
}
