const debug = require("debug")("mutant:App")

import { MutantApp } from "./app"
import React from "react"
import { render } from "react-dom"
import { isEmpty } from "@asd14/m"

import { init as setupSentry } from "@sentry/browser"
import { set as setupNext } from "@mutant-ws/next"
import { set as setupFetch } from "@mutant-ws/fetch-browser"
import { stringify } from "qs"
import { setOptions as setupMarked, Renderer as MarkedRenderer } from "marked"

import { highlightSyntax } from "/core.libs/syntax"
import "./core.ui/ui.css"

setupSentry({
  dsn:
    "https://c6a8de1da1c7448bb0a7e0458388e30c@o154021.ingest.sentry.io/1207926",
  environment: process.env.NODE_ENV,
})

setupMarked({
  renderer: new MarkedRenderer(),
  highlight: (code, language) =>
    isEmpty(language) ? code : highlightSyntax(code, language),
  pedantic: false,
  gfm: true,
  breaks: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
  xhtml: false,
})

setupNext({
  host: process.env.API_URL,
})

setupFetch({
  // Prefix relative urls with API_URL
  baseURL: process.env.API_URL,

  // Transform query object into string with `qs`
  queryStringifyFn: source =>
    stringify(source, {
      allowDots: true,
      encode: false,
      arrayFormat: "brackets",
      strictNullHandling: true,
    }),
})

render(<MutantApp />, document.getElementById("root"))

if (process.env.NODE_ENV !== "production") {
  window.localStorage.setItem("debug", "mutant:*", "ReduxList:*")
}
