/* eslint-disable react/no-multi-comp */

const debug = require("debug")("mutant:FieldMarkdownUI")

import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import marked from "marked"
import AceEditor from "react-ace"
import { is, isEmpty } from "@mutant-ws/m"

import css from "./field-markdown.css"

const FieldMarkdownUI = ({
  className,
  id,
  value,
  placeholder,
  isPlainText,
  isSelect,
  isFocus,
  isEdit,
  onRender,
  onViewModeClick,
  onViewModeDoubleClick,
  onEditModeCancel,
  onEditModeSubmit,
}) => {
  const [localValue, setLocalValue] = useState(value)
  const fieldRef = useRef()

  // update local state when props change
  useEffect(() => {
    setLocalValue(value)
  }, [value])

  useEffect(() => {
    if (is(fieldRef.current) && isSelect) {
      fieldRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      })
    }
  }, [isSelect])

  const handleSubmitIfDiff = newValue => {
    if (value === newValue) {
      onEditModeCancel()
    } else {
      onEditModeSubmit(id, {
        value: newValue,
      })
    }
  }

  return (
    <div
      ref={fieldRef}
      className={cx(css.field, {
        [className]: is(className),
        [css["field--is-select"]]: isSelect,
        [css["field--is-focus"]]: isFocus,
        [css["field--is-edit"]]: isEdit,
        [css["field--is-view"]]: !isEdit,
      })}
      onDoubleClick={() => {
        !isEdit && onViewModeDoubleClick(id)
      }}
      onClick={() => {
        !isEdit && onViewModeClick(id)
      }}>
      {isEdit ? (
        <AceEditor
          mode="markdown"
          theme="clouds"
          value={localValue}
          width="100%"
          maxLines={15}
          minLines={5}
          wrapEnabled={true}
          focus={true}
          readOnly={false}
          showGutter={false}
          showPrintMargin={false}
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            showFoldWidgets: false,
            useWorker: false,
          }}
          commands={[
            {
              name: "Revert and Exit",
              bindKey: { win: "Escape", mac: "Escape" },
              exec: () => {
                setLocalValue(value)
                onEditModeCancel()
              },
            },
            {
              name: "Save and Exit",
              bindKey: { win: "Shift+Enter", mac: "Shift+Enter" },
              exec: editor => {
                handleSubmitIfDiff(editor.getValue())
              },
            },
          ]}
          onBlur={(event, editor) => handleSubmitIfDiff(editor.getValue())}
          onChange={newValue => setLocalValue(newValue)}
        />
      ) : isPlainText ? (
        onRender(
          isEmpty(value) ? (
            <span className={css.placeholder}>{placeholder}</span>
          ) : (
            value
          )
        )
      ) : (
        onRender(
          marked(
            isEmpty(value)
              ? `<span class=${css.placeholder}>${placeholder}</span>`
              : value
          )
        )
      )}
    </div>
  )
}

FieldMarkdownUI.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  isPlainText: PropTypes.bool,
  isSelect: PropTypes.bool,
  isFocus: PropTypes.bool,
  isEdit: PropTypes.bool,
  onRender: PropTypes.func,
  onViewModeClick: PropTypes.func,
  onViewModeDoubleClick: PropTypes.func,
  onEditModeCancel: PropTypes.func,
  onEditModeSubmit: PropTypes.func.isRequired,
}

FieldMarkdownUI.defaultProps = {
  className: null,
  value: "",
  placeholder: "Empty field",
  onRender: source => (
    <div
      dangerouslySetInnerHTML={{
        __html: source,
      }}
    />
  ),
  isPlainText: false,
  isSelect: false,
  isFocus: false,
  isEdit: false,
  onViewModeClick: null,
  onViewModeDoubleClick: null,
  onEditModeCancel: null,
}

const memo = React.memo(FieldMarkdownUI)

export { memo as FieldMarkdownUI }
