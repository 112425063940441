/* eslint-disable react/jsx-pascal-case */
const debug = require("debug")("mutant:MetricsView")

import React from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import { filterWith } from "@mutant-ws/m"
import { map, contains } from "ramda"

import { deepReactMemo } from "/core.hooks/use-deep"

import { FieldMetricUI } from "./ui/field-metric/field-metric"
import css from "./metrics.css"

const MetricsView = ({
  focusId,
  editId,
  metrics,
  events,
  onMetricClick,
  onMetricDoubleClick,
  onMetricSubmit,
  onMetricCancel,
}) => (
  <SimpleBar autoHide={true} style={{ width: "100%", height: "100%" }}>
    <div className={css.distancer}>
      <div className={css["placeholder-code"]} />
      <div className={css["placeholder-chart"]} />
    </div>
    <div className={css.metrics}>
      {map(({ id, code }) => (
        <FieldMetricUI
          key={id}
          id={id}
          title={code.title}
          language="json"
          code={JSON.stringify(code, null, 4)}
          type={code.type}
          dataSets={filterWith({
            id: source => contains(source, code.events),
          })(events)}
          isFocus={id === focusId}
          isEdit={id === editId}
          onClick={onMetricClick}
          onDoubleClick={onMetricDoubleClick}
          onCodeSubmit={onMetricSubmit}
          onCodeCancel={onMetricCancel}
        />
      ))(metrics)}
    </div>
  </SimpleBar>
)

MetricsView.propTypes = {
  focusId: PropTypes.string,
  editId: PropTypes.string,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      code: PropTypes.shape({
        type: PropTypes.oneOf(["area", "bar"]).isRequired,
        events: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
      position: PropTypes.number,
      isKPI: PropTypes.bool,
    })
  ),
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.instanceOf(Date),
          y: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  onMetricClick: PropTypes.func.isRequired,
  onMetricDoubleClick: PropTypes.func.isRequired,
  onMetricSubmit: PropTypes.func.isRequired,
  onMetricCancel: PropTypes.func.isRequired,
}

MetricsView.defaultProps = {
  focusId: null,
  editId: null,
  metrics: [],
  events: [],
}

const memo = deepReactMemo(MetricsView)

export { memo as MetricsView }
