const debug = require("debug")("mutant:InsightsView")

import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { map, keys } from "ramda"

import { deepReactMemo } from "../core.hooks/use-deep"

import { InsightsCardUI } from "./ui/card"

import css from "./insights.css"

const InsightsView = ({ focusId, selectId, items, onInsightClick }) => {
  return (
    <div className={cx(css.insights)}>
      {map(({ id, body, isInbox = false, aggregate: { quotesCount } }) => (
        <InsightsCardUI
          key={id}
          id={id}
          body={body}
          quotesCount={quotesCount}
          isInbox={isInbox}
          isFocus={id === focusId}
          isSelect={id === selectId}
          onClick={onInsightClick}
        />
      ))(items)}
    </div>
  )
}

InsightsView.propTypes = {
  focusId: PropTypes.string,
  selectId: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      aggregate: PropTypes.shape({
        quotesCount: PropTypes.number,
      }).isRequired,
    })
  ),
  onInsightClick: PropTypes.func.isRequired,
}

InsightsView.defaultProps = {
  focusId: null,
  selectId: null,
  items: [],
}

const memo = deepReactMemo(InsightsView, keys(InsightsView.propTypes))

export { memo as InsightsView }
