const debug = require("debug")("mutant:UIAvatar")

import React, { useState, useRef } from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { is } from "@mutant-ws/m"

import { Portal } from "/core.hooks/use-portal"

import css from "./avatar.css"

const UIAvatar = ({
  className,
  name,
  avatar,
  size,
  backgroundColor,
  foregroundColor,
  isOnline,
  isNameVisible,
  hasTooltip,
  onClick,
}) => {
  const ref = useRef()
  const [
    { tooltipTop, tooltipLeft, isTooltipVisible },
    setTooltipDetails,
  ] = useState({ tooltipTop: 0, tooltipLeft: 0, isTooltipVisible: false })

  return (
    <span
      ref={ref}
      style={{
        height: size,
        lineHeight: "1",
      }}
      className={cx(css.avatar, {
        [className]: is(className),
        [css["avatar--has-name"]]: isNameVisible,
        [css["avatar--is-online"]]: isOnline,
      })}
      onClick={onClick}
      onMouseEnter={() => {
        if (hasTooltip && isTooltipVisible === false) {
          const { top, left } = is(ref.current)
            ? ref.current.getBoundingClientRect()
            : {}

          setTooltipDetails({
            tooltipTop: top,
            tooltipLeft: left,
            isTooltipVisible: true,
          })
        }
      }}
      onMouseLeave={() => {
        if (hasTooltip && isTooltipVisible === true) {
          setTooltipDetails({ isTooltipVisible: false })
        }
      }}>
      {isTooltipVisible ? (
        <Portal id="ui-avatar-tooltip">
          <span
            style={{
              top: tooltipTop + size + 5,
              left: tooltipLeft,
            }}
            className={css.tooltip}>
            {name}
          </span>
        </Portal>
      ) : null}

      {is(avatar) ? (
        <img
          style={{
            width: size,
          }}
          src={avatar}
        />
      ) : (
        <svg
          style={{
            backgroundColor,
            width: size,
          }}
          enableBackground="new 0 0 50 50"
          version="1.1"
          viewBox="0 0 50 50"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg">
          <switch>
            <g>
              <rect
                style={{
                  color: foregroundColor,
                }}
                x="11.492"
                y="12.029"
                width="3.842"
                height="3.843"
              />
              <rect
                style={{
                  color: foregroundColor,
                }}
                x="32.932"
                y="12.029"
                width="3.842"
                height="3.843"
              />
              <path
                style={{
                  color: foregroundColor,
                }}
                d="m32.976 15.978h-3.843v3.749h-10v-3.749h-3.843v3.749h-2.499v13.768h2.499v3.818h3.843v-3.818h10v3.818h3.843v-3.818h2.449v-13.769h-2.449v-3.748zm-1.921 14.437h-13.844v-1.921h13.844v1.921zm-15.765-8.84h3.843v3.842h-3.843v-3.842zm17.686 3.842h-3.843v-3.842h3.843v3.842z"
              />
            </g>
          </switch>
        </svg>
      )}

      {isNameVisible ? (
        <span className={css.name} style={{ fontSize: Math.floor(size / 3) }}>
          {name}
        </span>
      ) : null}
    </span>
  )
}

UIAvatar.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  avatar: PropTypes.string,
  size: PropTypes.number,
  backgroundColor: PropTypes.string,
  foregroundColor: PropTypes.string,
  isOnline: PropTypes.bool,
  isNameVisible: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  onClick: PropTypes.func,
}

UIAvatar.defaultProps = {
  className: null,
  name: "?",
  avatar: null,
  size: 32,
  backgroundColor: "#000",
  foregroundColor: "#fff",
  isOnline: true,
  isNameVisible: false,
  hasTooltip: true,
  onClick: null,
}

const memo = React.memo(UIAvatar)

export { memo as UIAvatar }
