const debug = require("debug")("mutant:FeaturesPage")

import React from "react"
import cx from "classnames"

import { UIHero } from "../core.ui/hero/hero"

import planPath from "./ui/images/plan.png"
import buildPath from "./ui/images/build.png"
import measurePath from "./ui/images/measure.png"

import usePlanPath from "./ui/images/use-plan.png"
import useBuildPath from "./ui/images/use-build.png"
import useMeasurePath from "./ui/images/use-measure.png"

import css from "./feature.css"

const FeaturesPage = () => {
  return (
    <React.Fragment>
      <UIHero picture={planPath} feature="Plan">
        <h1>
          Know and tell the full story
          <small>
            Write customer requests, your ideas, user problems, quotes from user
            interviews. A place to organize your planning data before starting
            to write code.
          </small>
        </h1>
        <img className={css["use-instead"]} src={usePlanPath} />
      </UIHero>

      <div className={css.content}>
        <div className={css.texts}>
          <div>
            <h2>User story structure</h2>
            <p>
              A structured and opinionated way of writing a user story. Explain
              the context and rally the team to solve real user problems.
            </p>
          </div>
          <div>
            <h2>Template for bugs</h2>
            <p>
              Understand exactly what problem your user has and avoid confusion
              in solving it.
            </p>
          </div>
        </div>
      </div>

      <UIHero picture={buildPath} feature="Issue Tracking">
        <h1>
          Super-minimalistic issue tracker
          <small>
            Development issues are organized in an intuitive way: the 4 columns
            track cards from idea to production.
            <br />
            The most important ones sit on top. Simple, right?
          </small>
        </h1>
        <img className={css["use-instead"]} src={useBuildPath} />
      </UIHero>

      <div className={css.content}>
        <div className={css.texts}>
          <div>
            <h2>Never leave the keyboard</h2>
            <p>
              This UI, there’s nothing quite like it. This board’s interface is
              amazing, you just type a command and voila!
            </p>
          </div>
          <div>
            <h2>Kanban simple structure</h2>
            <p>
              Teams understand Kanban instinctively. Clear, urgent, important?
              See the top. New task? Everything else will go down.
            </p>
          </div>
        </div>
      </div>

      <UIHero picture={measurePath} feature="Behavior Tracking">
        <h1>
          Collect &amp; embed custom events
          <small>
            In a car, the fuel gauge isn&apos;t located in the trunk and
            checking it isn&apos;t an afterthought. So we bring the relevant
            data in front of you and your team.
          </small>
        </h1>
        <img className={css["use-instead"]} src={useMeasurePath} />
      </UIHero>

      <div className={cx(css.content, css["feature-metrics"])}>
        <div className={css.texts}>
          <div>
            <h2>Track what users do</h2>
            <p>
              Event tracking allows you to see how your users are interacting
              with your product. Collect custom events and expose them after you
              deploy the code.
            </p>
          </div>
          <div>
            <h2>Set transparent goals</h2>
            <p>
              Is the new feature you released actually successful? How much is
              it actually used? Define your expected usage and see if your new
              release is used by as many users as expected.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export { FeaturesPage }
