const debug = require("debug")("mutant:useProduct")

import { useEffect } from "react"
import { is } from "@mutant-ws/m"
import { useParams } from "react-router-dom"

import { useLiveList } from "/core.hooks/use-live-list"
import { useCallback } from "/core.hooks/use-deep"

import { ProductsList } from "./list.products"
import { MembersList } from "./list.members"

export const useProduct = () => {
  const { productId } = useParams()

  const {
    selector: { byId, isLoading: isProductLoading, isLoaded: isProductLoaded },
    read: readProducts,
  } = useLiveList(ProductsList, {
    events: { prefix: "products" },
  })
  const shouldLoadProduct = !isProductLoading() && !isProductLoaded()

  const {
    selector: {
      items: itemsMembers,
      isLoading: isMembersLoading,
      isLoaded: isMembersLoaded,
    },
    create: createMembers,
    read: readMembers,
    update: updateMembers,
  } = useLiveList(MembersList, {
    events: { prefix: "members" },
  })
  const shouldLoadMembers = !isMembersLoading() && !isMembersLoaded()

  useEffect(() => {
    if (is(productId) && shouldLoadProduct) {
      readProducts(productId)
    }

    if (is(productId) && shouldLoadMembers) {
      readMembers({ productId })
    }
  }, [
    productId,
    readProducts,
    readMembers,
    shouldLoadMembers,
    shouldLoadProduct,
  ])

  // invite user to current product workspace
  const handleMemberInvite = useCallback(
    ({ email }) => createMembers({ productId }, { email }),
    [productId, createMembers]
  )

  // update user's location/focused element on workspace
  const handleMemberLocationUpdate = useCallback(
    ({ id, layer, status, queryParams }) =>
      updateMembers({ productId }, { focusId: id, layer, status, queryParams }),
    [productId, updateMembers]
  )

  return {
    ...byId(productId, {}),
    members: itemsMembers(),
    invite: handleMemberInvite,
    updateMemberLocation: handleMemberLocationUpdate,
  }
}
