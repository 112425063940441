const debug = require("debug")("mutant:EarlyAccessContainer")

import React, { useState } from "react"
import PropTypes from "prop-types"
import { pipe, get, is, isEmpty } from "@mutant-ws/m"

import { errorMessagesByField } from "/core.libs/routes"
import { track, debounceTrack } from "/core.hooks/use-track"
import { useList } from "/core.hooks/use-list"

import { EarlyAccessList } from "./data/list.early-access"
import { EarlyAccessView } from "./early-access.view"

const EarlyAccessContainer = ({
  className,
  location,
  callToAction,
  isCenter,
}) => {
  const {
    selector: { error, isCreating, isUpdating },
    create,
    update,
  } = useList(EarlyAccessList)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [job, setJob] = useState("")
  const [teamSize, setTeamSize] = useState("")
  const [step, setStep] = useState(1)

  const handleEarlyAccessCreate = () =>
    create({ email }).then(({ error: reqError }) => {
      if (isEmpty(reqError)) {
        setStep(2)
        track(`global__request-access--${location}-submit`, {
          email,
        })
      }
    })

  const handleEarlyAccessUpdate = () =>
    update(email, { name, job, teamSize }).then(({ error: reqError }) => {
      if (isEmpty(reqError)) {
        setStep(3)

        track(`global__request-access--${location}-update`, {
          email,
        })

        setTimeout(() => {
          setStep(1)
          setName("")
          setEmail("")
          setJob(null)
          setTeamSize(null)
        }, 10000)
      }
    })

  return (
    <EarlyAccessView
      className={className}
      callToAction={callToAction}
      step={step}
      name={name}
      nameError={pipe(
        get("data"),
        errorMessagesByField,
        get("name")
      )(error("update"))}
      email={email}
      emailError={is(error("create")) ? "Invalid email" : null}
      job={job}
      jobError={pipe(
        get("data"),
        errorMessagesByField,
        get("job")
      )(error("update"))}
      teamSize={teamSize}
      teamSizeError={pipe(
        get("data"),
        errorMessagesByField,
        get("teamSize")
      )(error("update"))}
      isLoading={isCreating() || isUpdating()}
      isCenter={isCenter}
      onNameChange={event => {
        setName(event.currentTarget.value)
      }}
      onEmailChange={event => {
        setEmail(event.currentTarget.value)

        debounceTrack("global__request-access--edit", {
          text: event.currentTarget.value,
        })
      }}
      onJobChange={event => {
        setJob(event.currentTarget.value)
      }}
      onTeamSizeChange={event => {
        setTeamSize(event.currentTarget.value)
      }}
      onSubmit={handleEarlyAccessCreate}
      onSubmitFollowUp={handleEarlyAccessUpdate}
    />
  )
}

EarlyAccessContainer.propTypes = {
  className: PropTypes.string,
  location: PropTypes.string.isRequired,
  callToAction: PropTypes.string,
  isCenter: PropTypes.bool,
}

EarlyAccessContainer.defaultProps = {
  className: null,
  callToAction: "Sign me up",
  isCenter: false,
}

export { EarlyAccessContainer }
