const debug = require("debug")("mutant:ColumnsView")

import React from "react"
import PropTypes from "prop-types"
import { map, filterWith, hasWith } from "@mutant-ws/m"

import { deepReactMemo } from "/core.hooks/use-deep"

import { WorkColumnUI } from "./ui/column"
import css from "./columns.css"

const ColumnsView = ({
  featureHighlightId,
  focusId,
  editId,
  cards,
  members,
  membersPresent,
  onClick,
  onCardClick,
  onCardDoubleClick,
  onCardTitleEditUpdate,
  onCardTitleEditCancel,
}) => {
  return (
    <div className={css.board} onClick={onClick}>
      {map(({ status, label }) => {
        const workByStatus = filterWith({ status })(cards)

        return (
          <WorkColumnUI
            key={status}
            // only update if present in current column in order to benefit
            // from memoize
            featureSelectId={
              hasWith({ featureId: featureHighlightId })(workByStatus)
                ? featureHighlightId
                : null
            }
            // only update if present in current column in order to benefit
            // from memoize
            focusId={hasWith({ id: focusId })(workByStatus) ? focusId : null}
            editId={editId}
            title={label}
            members={members}
            membersPresent={filterWith({
              cardId: source => hasWith({ id: source })(workByStatus),
            })(membersPresent)}
            work={workByStatus}
            onCardClick={onCardClick}
            onCardDoubleClick={onCardDoubleClick}
            onCardTitleEditUpdate={onCardTitleEditUpdate}
            onCardTitleEditCancel={onCardTitleEditCancel}
          />
        )
      })([
        { status: "backlog", label: "Backlog" },
        { status: "doing", label: "Doing" },
        { status: "testing", label: "Testing" },
        { status: "done", label: "Done" },
      ])}
    </div>
  )
}

ColumnsView.propTypes = {
  featureHighlightId: PropTypes.string,
  focusId: PropTypes.string,
  editId: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      userId: PropTypes.string,
      parentId: PropTypes.string,
      position: PropTypes.number,
      type: PropTypes.oneOf(["work"]),
      status: PropTypes.oneOf(["backlog", "doing", "testing", "done"]),
    })
  ),
  members: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
      bgColor: PropTypes.string,
      fgColor: PropTypes.string,
      isEdit: PropTypes.bool,
      isOnline: PropTypes.bool,
    })
  ),
  membersPresent: PropTypes.arrayOf(
    PropTypes.shape({
      cardId: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
    })
  ),
  onClick: PropTypes.func,
  onCardClick: PropTypes.func.isRequired,
  onCardDoubleClick: PropTypes.func.isRequired,
  onCardTitleEditUpdate: PropTypes.func.isRequired,
  onCardTitleEditCancel: PropTypes.func.isRequired,
}

ColumnsView.defaultProps = {
  featureHighlightId: null,
  focusId: null,
  editId: null,
  cards: [],
  members: [],
  membersPresent: [],
  onClick: null,
}

const memo = deepReactMemo(ColumnsView)

export { memo as ColumnsView }
