const debug = require("debug")("mutant:LibSyntax")

import { is } from "@mutant-ws/m"
import Prism from "prismjs"

export const highlightSyntax = (source, syntax = "js") => {
  const hasLanguage = is(Prism.languages[syntax])

  if (!hasLanguage) {
    debug(`Language "${syntax}" not suported for highlighting`)
  }

  return Prism.highlight(source, Prism.languages[syntax])
}
