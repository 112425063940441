const debug = require("debug")("mutant:useQuotesKeyboard")

import { findWith, is } from "@mutant-ws/m"
import { pipe, last, propEq, head, prop } from "ramda"

import { addShortcuts, removeShortcuts } from "../core.libs/keyboard"
import { hover } from "../core.libs/positioning"
import { useEffect } from "../core.hooks/use-deep"
import { useYank } from "../core.hooks/use-yank/use-yank"
import { useFocus, FEEDBACK_INSIGHTS_LAYER } from "../core.hooks/use-focus"

export const useQuotesKeyboard = (
  sectionLayer,
  {
    productId,
    focusId,
    insightId,
    items,
    onCreate,
    onRemove,
    onUpdate,
    onVote,
    onFocus,
    onEdit,
  }
) => {
  const [{ layer }, setFocus] = useFocus(productId)
  const { id: yankId, model: yankModel, yank, paste } = useYank()
  const hasKeyboard = layer === sectionLayer

  // focus first when gaining keyboard
  useEffect(() => {
    const hasItems = items.length !== 0
    const hasFocus = is(focusId)

    if (!hasFocus && hasKeyboard && hasItems) {
      onFocus(pipe(head, prop("id"))(items))
    }
  }, [focusId, items, layer, hasKeyboard, onFocus])

  /**
   * Quotes section specific shortcuts
   */
  useEffect(() => {
    //
    const handleUpDownHover = ({ key, shiftKey }) => {
      if (shiftKey) {
        onVote(focusId, {
          value: key === "ArrowUp" ? 1 : -1,
        })
      } else {
        onFocus(
          hover(key === "ArrowUp" ? "prev" : "next", {
            id: focusId,
            items,
          })
        )
      }
    }

    const handleCreate = () =>
      onCreate({
        productId,
        insightId,
      }).then(({ error, result }) => {
        if (!is(error)) {
          onFocus(result.id)
        }
      })

    const handleEditMode = event => {
      // dont allow "i" char to register inside the input
      event.preventDefault()

      onEdit(focusId)
    }

    // Put hover quote id into Yank global state buffer
    const handleYank = ({ key, ctrlKey, metaKey }) => {
      const shouldYank =
        is(focusId) && (key === "y" || (key === "c" && (ctrlKey || metaKey)))

      if (shouldYank) {
        yank({
          id: focusId,
          model: "quote",
          onPaste: id => onUpdate(focusId, { productId, insightId: id }),
        })
      }
    }

    // Paste user quote into selected insight
    const handlePaste = ({ key, ctrlKey, metaKey }) => {
      const canPaste =
        yankModel === "quote" &&
        (key === "p" || (key === "v" && (ctrlKey || metaKey)))

      if (is(insightId) && canPaste) {
        paste(insightId)
        onFocus(yankId)
      }
    }

    // Delete focused feedback
    const handleDelete = () => {
      if (!is(focusId) || focusId === "title") {
        return
      }

      const { body } = findWith({ id: focusId })(items)

      if (window.confirm(`Delete "${body}" feedback?`)) {
        onRemove(focusId, { productId }).then(() => {
          const isLast = pipe(last, propEq("id", focusId))(items)

          onFocus(
            hover(isLast ? "prev" : "next", {
              id: focusId,
              items,
            })
          )
        })
      }
    }

    addShortcuts({
      layer: sectionLayer,
      shortcuts: {
        "ArrowUp,ArrowDown": handleUpDownHover,

        // Give keyboard to insights section
        ArrowLeft: () =>
          setFocus({
            layer: FEEDBACK_INSIGHTS_LAYER,
          }),

        "e,i": handleEditMode,

        Enter: event => {
          if (event.shiftKey) {
            handleCreate(event)
          } else {
            handleEditMode(event)
          }
        },

        "c,y": handleYank,

        "v,p": handlePaste,

        "d,Backspace,Delete": handleDelete,
      },
    })

    return () => removeShortcuts({ layer: sectionLayer })
  }, [
    productId,
    focusId,
    insightId,
    yankId,
    sectionLayer,
    items,
    yankModel,
    yank,
    paste,
    setFocus,
    onCreate,
    onUpdate,
    onRemove,
    onVote,
    onFocus,
    onEdit,
  ])
}
