const debug = require("debug")("mutant:InsightTitleUI")

import React, { useState, useEffect } from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import AceEditor from "react-ace"

import css from "./title.css"

const InsightTitleUI = ({
  body,
  score,
  quotesCount,
  isInbox,
  isFocus,
  isEdit,
  hasKeyboard,
  onClick,
  onDoubleClick,
  onEditModeCancel,
  onEditModeSubmit,
}) => {
  const [localBody, setLocalBody] = useState()

  // sync state body if props change
  useEffect(() => {
    setLocalBody(body)
  }, [body])

  const handleSubmitIfDiff = value => {
    if (value === body) {
      onEditModeCancel()
    } else {
      onEditModeSubmit("title", {
        body: value,
      })
    }
  }

  return (
    <div className={css["title-wrapper"]}>
      <div
        className={cx(css.title, {
          [css["title--is-focus"]]: isFocus && hasKeyboard,
          [css["title--is-select"]]: isFocus && !hasKeyboard,
          [css["title--is-inbox"]]: isInbox,
        })}
        onClick={() => onClick("title")}
        onDoubleClick={() => !isEdit && !isInbox && onDoubleClick("title")}>
        <div>
          <div className={css.score}>{isInbox ? "★" : score}</div>
        </div>
        <div>
          {isEdit ? (
            <AceEditor
              mode="markdown"
              theme="clouds"
              value={localBody}
              width="100%"
              maxLines={15}
              minLines={3}
              wrapEnabled={true}
              focus={true}
              readOnly={false}
              showGutter={false}
              showPrintMargin={false}
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                showFoldWidgets: false,
                useWorker: false,
              }}
              commands={[
                {
                  name: "Revert and Exit",
                  bindKey: { win: "Escape", mac: "Escape" },
                  exec: () => {
                    setLocalBody(body)
                    onEditModeCancel()
                  },
                },
                {
                  name: "Save and Exit",
                  bindKey: { win: "Shift+Enter", mac: "Shift+Enter" },
                  exec: editor => {
                    handleSubmitIfDiff(editor.getValue())
                  },
                },
              ]}
              onBlur={(event, editor) => handleSubmitIfDiff(editor.getValue())}
              onChange={value => setLocalBody(value)}
            />
          ) : (
            <h2 className={css.body}>{body}</h2>
          )}
          <div className={css["quotes-count"]}>
            {isInbox
              ? `${quotesCount} unsorted quotes`
              : `${quotesCount} quotes`}
          </div>
        </div>
      </div>
    </div>
  )
}

InsightTitleUI.propTypes = {
  body: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  quotesCount: PropTypes.number.isRequired,
  isInbox: PropTypes.bool.isRequired,
  isFocus: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  hasKeyboard: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onEditModeCancel: PropTypes.func.isRequired,
  onEditModeSubmit: PropTypes.func.isRequired,
}

InsightTitleUI.defaultProps = {}

const memo = React.memo(InsightTitleUI)

export { memo as InsightTitleUI }
