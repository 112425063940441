const debug = require("debug")("mutant:EarlyAccessList")

import { buildList } from "@mutant-ws/redux-list"
import { POST, PATCH } from "@mutant-ws/fetch-browser"

export const EarlyAccessList = buildList({
  name: "HOME.EARLY-ACCESS.SUBMISSIONS",

  create: ({ email }) =>
    POST("/early-access", {
      body: { email },
    }).then(() => ({
      id: email,
    })),

  update: (email, { name, job, teamSize }) =>
    PATCH("/early-access", {
      body: { name, email, job, teamSize },
    }).then(() => ({
      id: email,
    })),
})
