const debug = require("debug")("mutant:VotesList")

import { buildList } from "@mutant-ws/redux-list"
import { GET, POST } from "@mutant-ws/fetch-browser"

export const VotesList = buildList({
  name: "PRODUCT.FEEDBACK.QUOTES.VOTES",

  create: ({ productId, quoteId, value }) =>
    POST("/votes", {
      body: {
        productId,
        resourceId: quoteId,
        resourceModel: "quotes",
        value,
      },
    }),

  // by loggedin user and product
  read: ({ productId }) =>
    GET("/votes", {
      query: {
        filter: {
          productId,
        },
        pluck: ["resourceId", "value"],
      },
    }),
})
