const debug = require("debug")("mutant:ProfilePage")

import React, { useEffect, useCallback, useState, useRef } from "react"
import { is } from "@mutant-ws/m"

import { addShortcuts, removeShortcuts } from "/core.libs/keyboard"
import { track } from "/core.hooks/use-track"
import { useCommands, byLayer } from "/core.hooks/use-commands"

import { UICommandLine } from "/core.ui/command-line/command-line"

import { MeContainer } from "./_.me/me.container"
import { ProductsContainer } from "./_.products/products.container"
import { UIUserMenu } from "./ui/usermenu/usermenu"

import css from "./profile.css"

const ProfilePage = () => {
  const [isCmdLineVisible, setIsCmdLineVisible] = useState(false)
  const commandRef = useRef()
  const [commands] = useCommands()

  // focus inner input when displaying CommandLine
  useEffect(() => {
    if (is(commandRef.current)) {
      commandRef.current.focus()
    }
  }, [isCmdLineVisible])

  // keyboard shortcuts

  useEffect(() => {
    addShortcuts({
      // "base" is the fallback layer if key does not have a handler on
      // different layer
      layer: "base",
      shortcuts: {
        Escape: () => {
          setIsCmdLineVisible(false)
        },
        ":,/": event => {
          // prevent ":" get registered by input
          event.preventDefault()

          setIsCmdLineVisible(true)
        },
      },
    })

    return () => removeShortcuts({ layer: "base" })
  }, [])

  const handleCmdFocus = useCallback(() => setIsCmdLineVisible(true), [])

  const handleCmdBlur = useCallback(() => setIsCmdLineVisible(false), [])

  return (
    <React.Fragment>
      <UIUserMenu
        onClick={linkName => () => {
          track(`loggedin__top-menu--${linkName}-click`, {})
        }}
      />
      <div className={css.content}>
        <MeContainer />
        <ProductsContainer />
      </div>

      {isCmdLineVisible ? (
        <UICommandLine
          className={css["command-line"]}
          ref={commandRef}
          commands={byLayer("base", commands)}
          isSuggestionsVisible={isCmdLineVisible}
          onFocus={handleCmdFocus}
          onBlur={handleCmdBlur}
        />
      ) : null}
    </React.Fragment>
  )
}

export { ProfilePage }
