const debug = require("debug")("mutant:Product.Work.Board.FeaturesContainer")

import React, { useCallback } from "react"
import PropTypes from "prop-types"
import {
  flatten,
  pluck,
  distinct,
  pipe,
  filterWith,
  not,
  equals,
  map,
  read,
  remove,
  findWith,
  hasWith,
  is,
  isEmpty,
  isNothing,
} from "@asd14/m"

import { addShortcuts, removeShortcuts } from "/core.libs/keyboard"
import { hover, move } from "/core.libs/positioning"
import { useLiveList } from "/core.hooks/use-live-list"
import { useList } from "/core.hooks/use-list"
import { useQuery } from "/core.hooks/use-query"
import { useEffect } from "/core.hooks/use-deep"
import { useColorScale } from "/core.hooks/use-color-scale"
import { useAuth } from "/core.hooks/use-auth/use-auth"
import {
  useFocus,
  WORK_BOARD_COLUMNS_LAYER,
  WORK_BOARD_FEATURES_LAYER,
  WORK_CARD_LAYER,
} from "/core.hooks/use-focus"

import { FeaturesList } from "./data/list.features"
import { MetricsList } from "./data/list.metrics"
import { EventsList } from "./data/list.events"

import { FeaturesView } from "./features.view"

const FeaturesContainer = ({
  productId,
  hightlightId,
  members,
  onCardFocus,
  onCardToggle,
  onCardOpen,
}) => {
  const { id: userId } = useAuth()
  const [{ "feature-id": featureQueryId }, setQuery] = useQuery()
  const [colorPicker, reverseColorPicker] = useColorScale(
    pluck("userId")(members)
  )

  //
  // Features - data fetch
  //

  const {
    selector: { items: selectFeatureItems },
    read: readFeatures,
    update: updateFeature,
    remove: removeFeature,
  } = useLiveList(FeaturesList, {
    events: {
      prefix: "cards",
      shouldAcceptFn: useCallback(({ type } = {}) => type === "feature", []),
    },
  })

  useEffect(() => {
    readFeatures({ productId })
  }, [productId, readFeatures])

  const cards = selectFeatureItems()

  //
  // Metrics - data fetch
  //

  const {
    selector: { items: selectMetricItems },
    read: readMetrics,
  } = useLiveList(MetricsList, { events: { prefix: "metrics" } })

  const metricIds = pipe(
    map(read("coverMetricId")),
    remove(isNothing),
    distinct
  )(cards)

  const metrics = selectMetricItems()

  useEffect(() => {
    if (!isEmpty(metricIds)) {
      readMetrics({ id: metricIds, productId })
    }
  }, [productId, metricIds, readMetrics])

  //
  // Events - data fetch
  //

  const {
    selector: { items: selectEventItems },
    read: readEvents,
  } = useList(EventsList)

  const eventNames = pipe(
    map(read(["code", "events"])),
    flatten,
    distinct
  )(metrics)

  const events = selectEventItems()

  useEffect(() => {
    if (!isEmpty(eventNames)) {
      readEvents({ productId, names: eventNames })
    }
  }, [productId, eventNames, readEvents])

  //
  // Feature keyboard shortcuts
  //

  const [
    { id: focusId, layer: focusLayer, status: focusStatus },
    setFocus,
  ] = useFocus()
  const hasKeyboard = focusLayer === WORK_BOARD_FEATURES_LAYER

  const handleCardChange = useCallback(
    id => {
      setFocus({ id, layer: WORK_BOARD_FEATURES_LAYER })
      onCardFocus(id)
    },
    [onCardFocus, setFocus]
  )

  useEffect(() => {
    addShortcuts({
      layer: WORK_BOARD_FEATURES_LAYER,
      shortcuts: {
        Enter: () => {
          if (is(focusId)) {
            setFocus({
              layer: WORK_CARD_LAYER,
            })
            setQuery({
              "work-id": focusId,
            })
          }
        },
        // Escape: () => {
        //   if (is(featureQueryId)) {
        //     onCardToggle(undefined)
        //   }
        // },

        ArrowDown: () => {
          // give keyboard focus to Board Columns
          setFocus({ layer: WORK_BOARD_COLUMNS_LAYER })
        },

        "ArrowLeft,ArrowRight": ({ key, shiftKey }) => {
          if (!shiftKey) {
            const id = hover(key === "ArrowLeft" ? "up" : "down", {
              id: focusId,
              items: cards,
            })

            handleCardChange(id)
          }

          if (shiftKey && is(focusId)) {
            return updateFeature(focusId, {
              position: move(key === "ArrowLeft" ? "up" : "down", {
                id: focusId,
                items: cards,
              }),
            })
          }
        },

        " ": () => {
          if (focusId) {
            onCardToggle(focusId)
          }
        },

        "d,D": () => {
          if (is(focusId)) {
            const title = pipe(
              findWith({
                id: focusId,
              }),
              read("title")
            )(cards)

            if (window.confirm(`Delete "${title}"?`)) {
              removeFeature(focusId)

              setQuery({
                "feature-id": undefined,
              })
            }
          }
        },

        "e,i": event => {
          if (is(focusId)) {
            // prevent "i" or "e" getting in the input
            event.preventDefault()

            setFocus({ status: "update" })
          }
        },
      },
    })

    return () => removeShortcuts({ layer: WORK_BOARD_FEATURES_LAYER })
  }, [
    cards,
    focusId,
    featureQueryId,
    onCardToggle,
    removeFeature,
    updateFeature,
    setFocus,
    setQuery,
    handleCardChange,
  ])

  //
  // Focus first when gaining keyboard
  //

  useEffect(() => {
    const isFocusOfTypeFeature = hasWith({ id: focusId })(cards)

    if (hasKeyboard && !isFocusOfTypeFeature && !isEmpty(cards)) {
      handleCardChange(is(hightlightId) ? hightlightId : read([0, "id"])(cards))
    }
  }, [focusId, hightlightId, hasKeyboard, cards, handleCardChange])

  //
  // Editable card.title
  //

  const handleCardTitleEditUpdate = useCallback(
    nextTitle => {
      const prevTitle = pipe(findWith({ id: focusId }), read("title"))(cards)

      setFocus({ status: "read" })

      if (nextTitle !== prevTitle) {
        updateFeature(focusId, {
          title: nextTitle,
        })
      }
    },
    [focusId, cards, updateFeature, setFocus]
  )

  const handleCardTitleEditCancel = useCallback(() => {
    setFocus({ status: "read" })
  }, [setFocus])

  return (
    <FeaturesView
      hightlightId={hightlightId}
      featureQueryId={featureQueryId}
      focusId={hasKeyboard ? focusId : null}
      editId={hasKeyboard && focusStatus === "update" ? focusId : null}
      cards={cards}
      metrics={metrics}
      events={events}
      members={map(item => ({
        ...item,
        bgColor: colorPicker(item.userId),
        fgColor: reverseColorPicker(item.userId),
        isEdit: pipe(read(["location", "status"]), equals("edit"))(item),
      }))(members)}
      membersPresent={pipe(
        filterWith({
          isOnline: true,
          userId: not(equals(userId)),
          location: pipe(read("layer"), equals(WORK_BOARD_FEATURES_LAYER)),
        }),
        map(item => ({
          userId: read("userId")(item),
          cardId: read(["location", "focusId"])(item),
        }))
      )(members)}
      hasFocus={hasKeyboard}
      onClick={useCallback(() => {
        setFocus({ layer: WORK_BOARD_FEATURES_LAYER })
      }, [setFocus])}
      onCardClick={useCallback(
        id => {
          if (focusId === id && focusStatus === "read") {
            onCardOpen(id)
          }

          if (id !== focusId) {
            handleCardChange(id)
          }
        },
        [focusId, focusStatus, handleCardChange, onCardOpen]
      )}
      onCardDoubleClick={onCardOpen}
      onCardTitleEditUpdate={handleCardTitleEditUpdate}
      onCardTitleEditCancel={handleCardTitleEditCancel}
    />
  )
}

FeaturesContainer.propTypes = {
  productId: PropTypes.string.isRequired,
  hightlightId: PropTypes.string,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
      isOnline: PropTypes.bool,
    })
  ),
  onCardFocus: PropTypes.func.isRequired,
  onCardToggle: PropTypes.func.isRequired,
  onCardOpen: PropTypes.func.isRequired,
}

FeaturesContainer.defaultProps = {
  hightlightId: null,
  members: [],
}

export { FeaturesContainer }
