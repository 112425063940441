const debug = require("debug")("mutant:CommandLineTokenizer")

// prettier-ignore
const allowedChars = [
  "A", "a", "B", "b", "C", "c", "D", "d", "E", "e", "F", "f", "G", "g",
  "H", "h", "I", "i", "J", "j", "K", "k", "L", "l", "M", "m", "N", "n",
  "O", "o", "P", "p", "Q", "q", "R", "r", "S", "s", "T", "t", "U", "u",
  "V", "v", "W", "w", "X", "x", "Y", "y", "Z", "z",

  "0", "1", "2", "3", "4", "5", "6", "7", "8", "9",

  " ", "@", ".", ",", "-", "_", "\""
].reduce(
  // convert into object for fast lookups
  (acc, item) => ({...acc, [item]: true}),
  {}
)

/**
 * Split string into valid atomic parts
 *
 * @param  {string}  source  Input string
 *
 * @return {string[]}
 */
export const tokenize = source => {
  const tokens = []

  let buffer = ""

  let isInsideQoute = false

  for (let i = 0, length = source.length; i < length; ++i) {
    const char = source[i]
    const lookBehind = i === 0 ? null : source[i - 1]
    // const lookAhead = i === length - 1 ? null : source[i + 1]

    if (allowedChars[char] !== true) {
      throw new Error(`"${char}" not allowed`)
    }

    switch (char) {
      case " ": {
        const shouldConcat = isInsideQoute || lookBehind === " "

        if (shouldConcat) {
          buffer = buffer + char
        } else {
          tokens.push(buffer)
          buffer = ""
        }

        break
      }
      case '"':
        isInsideQoute = !isInsideQoute
        break
      default:
        buffer = buffer + char
    }
  }

  if (isInsideQoute) {
    throw new Error("Quote not closed")
  }

  // this will make sure that "lorem ipsum " => ["lorem", "ipsum", ""]
  tokens.push(buffer)

  return tokens
}
