const debug = require("debug")("mutant:UIDemoLogin")

import React, { forwardRef } from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { is } from "@asd14/m"

import { deepReactMemo } from "/core.hooks/use-deep"
import { UIInput } from "/core.ui/input/input"

import css from "./demo-login.css"

const UIDemoLogin = forwardRef(
  (
    {
      className,
      name,
      nameError,
      email,
      emailError,
      isLoading,
      onNameChange,
      onEmailChange,
      onSubmit,
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={cx(css.form, {
          [className]: is(className),
        })}>
        <UIInput
          className={css.input}
          value={name}
          placeholder="Name"
          type="text"
          name="name"
          error={nameError}
          isLoading={isLoading}
          isTabDisabled={false}
          onChange={event => onNameChange(event.currentTarget.value)}
          onSubmit={onSubmit}
        />
        <UIInput
          className={css.input}
          value={email}
          placeholder="Email"
          type="text"
          name="email"
          error={emailError}
          isLoading={isLoading}
          isTabDisabled={false}
          onChange={event => onEmailChange(event.currentTarget.value)}
          onSubmit={onSubmit}
        />
      </div>
    )
  }
)

UIDemoLogin.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  nameError: PropTypes.string,
  email: PropTypes.string.isRequired,
  emailError: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

UIDemoLogin.defaultProps = {
  className: null,
  nameError: null,
  emailError: null,
}

const memo = deepReactMemo(UIDemoLogin)

export { memo as UIDemoLogin }
