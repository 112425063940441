const debug = require("debug")("mutant:FieldImageOverlayUI")

import React, { useRef, useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { pipe, split, last, is } from "@mutant-ws/m"

import { Portal } from "/core.hooks/use-portal"

import css from "./overlay.css"

const FieldImageOverlayUI = ({ url, onClose }) => {
  const ref = useRef()
  const [isLoaded, setIsLoaded] = useState(false)
  const [{ name, width, height }, setImageDetails] = useState({
    name: "",
    width: "-",
    height: "-",
  })

  useEffect(() => {
    if (is(ref.current) && isLoaded) {
      setImageDetails({
        name: pipe(split("/"), last)(ref.current.src),
        width: ref.current.naturalWidth,
        height: ref.current.naturalHeight,
      })
    }
  }, [ref, isLoaded])

  return (
    <Portal id="field-image-overlay">
      <div className={cx(css.overlay)}>
        <div className={css.background} onClick={onClose} />
        <div className={css.content}>
          <div className={css.props}>
            {isLoaded ? (
              <React.Fragment>
                <span>{name}</span>
                <span>
                  {width}x{height}
                </span>
              </React.Fragment>
            ) : (
              <span>Loading image ...</span>
            )}
          </div>
          <img
            ref={ref}
            className={css.image}
            style={{ opacity: isLoaded ? 1 : 0 }}
            src={url}
            onClick={useCallback(event => event.preventDefault(), [])}
            onLoad={() => setIsLoaded(true)}
          />
        </div>
      </div>
    </Portal>
  )
}

FieldImageOverlayUI.propTypes = {
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

FieldImageOverlayUI.defaultProps = {}

const memo = React.memo(FieldImageOverlayUI)

export { memo as FieldImageOverlayUI }
