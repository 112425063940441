const debug = require("debug")("mutant:UIErrorBoundary")

import * as React from "react"
import PropTypes from "prop-types"
import * as Sentry from "@sentry/browser"
import { is } from "@mutant-ws/m"

import { UIButton } from "../button/button"

import css from "./error-boundary.css"

class UIErrorBoundary extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      errorInfo: null,
      eventId: null,
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    })

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)

      this.setState({ eventId })
    })
  }

  render() {
    const { children, message } = this.props
    const { error, errorInfo, eventId } = this.state

    return is(errorInfo) ? (
      <div className={css.error}>
        <h3>{message}</h3>

        <p>Seriously, an error report has been sent. We&apos;re on it.</p>

        <UIButton
          size="small"
          label="Report feedback"
          onClick={() => Sentry.showReportDialog({ eventId })}
        />

        <details style={{ display: "none", whiteSpace: "pre-wrap" }}>
          {is(error) ? error.toString() : message}
          <br />
          {errorInfo.componentStack}
        </details>
      </div>
    ) : (
      children
    )
  }
}

UIErrorBoundary.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node.isRequired,
}

UIErrorBoundary.defaultProps = {
  message: "Uuuupsilon",
}

export { UIErrorBoundary }
