const debug = require("debug")("mutant:useFieldsKeyboard")

import { useCallback } from "react"
import { findWith, removeWith, gt, hasWith, is } from "@mutant-ws/m"
import {
  pipe,
  when,
  propSatisfies,
  pathOr,
  take,
  append,
  join,
  last,
  propEq,
} from "ramda"

import { move, hover, positionAfter } from "/core.libs/positioning"
import { addShortcuts, removeShortcuts } from "/core.libs/keyboard"
import { useEffect } from "/core.hooks/use-deep"
import { useYank } from "/core.hooks/use-yank/use-yank"
import {
  useFocus,
  WORK_CARD_SIBLINGS_LAYER,
  WORK_CARD_COMMENTS_LAYER,
} from "/core.hooks/use-focus"

export const useFieldsKeyboard = (
  sectionLayer,
  {
    productId,
    cardId,
    overlayId,
    type,
    imageRefs,
    fieldsList: { create, update, remove, items },
    setOverlayId,
    onEscape,
  }
) => {
  const [{ id: focusId, layer, status: focusStatus }, setFocus] = useFocus()
  const { id: yankId, model: yankModel, clear: clearYank } = useYank()
  const hasKeyboard = layer === sectionLayer

  //
  // Focus first when gaining keyboard
  //

  const hasItems = items.length !== 0
  const hasFocus = hasWith({ id: focusId })(items)

  useEffect(() => {
    if (!hasFocus && hasKeyboard && hasItems) {
      setFocus({ id: `${cardId}-title` })
    }
  }, [focusId, cardId, layer, hasItems, hasFocus, hasKeyboard, setFocus])

  //
  // Fields section specific shortcuts
  //

  const handleUploadTrigger = useCallback(() => {
    if (is(focusId) && is(imageRefs[focusId].current)) {
      imageRefs[focusId].current.click()
    }
  }, [focusId, imageRefs])

  useEffect(() => {
    addShortcuts({
      layer: sectionLayer,
      shortcuts: {
        //
        // Close order: overlay, edit mode, card
        //
        Escape: () => {
          if (is(overlayId)) {
            return setOverlayId(null)
          }

          if (focusStatus === "update") {
            return setFocus({ status: "read" })
          }

          return onEscape()
        },

        //
        // Give keyboard to Siblings section
        //
        ArrowLeft: () =>
          setFocus({
            layer: WORK_CARD_SIBLINGS_LAYER,
          }),

        //
        // Give keyboard to Comments section
        //
        ArrowRight: () =>
          setFocus({
            layer: WORK_CARD_COMMENTS_LAYER,
          }),

        //
        // Mode up and down
        //
        "ArrowUp,ArrowDown": event => {
          event.preventDefault()

          const shouldUpdate =
            event.shiftKey &&
            is(focusId) &&
            focusId !== "title" &&
            focusId !== "component"
          const shouldHover = !event.shiftKey

          if (shouldUpdate) {
            update(focusId, {
              cardId,
              position: move(event.key === "ArrowUp" ? "up" : "down", {
                id: focusId,
                items: removeWith({
                  id: source => source === "title" || source === "component",
                })(items),
              }),
            })
          }

          if (shouldHover) {
            const newFocusId = hover(event.key === "ArrowUp" ? "up" : "down", {
              id: focusId,
              items: removeWith({
                id: source =>
                  type === "feature" ? false : source === "component",
              })(items),
            })

            setFocus({ id: newFocusId })
          }
        },

        //
        // Shift+Enter: Add new field under focused
        // Enter: Switch to edit mode
        //
        Enter: ({ shiftKey }) => {
          if (shiftKey) {
            return create({
              productId,
              cardId,
              type: "md",
              position: positionAfter({ id: focusId, items }),
            }).then(({ error, result }) => {
              if (!is(error)) {
                setFocus({
                  id: result.id,
                  status: "update",
                })
              }
            })
          }

          if (is(focusId)) {
            event.preventDefault()

            setFocus({ status: "update" })
          }
        },

        //
        // Paste from yank buffer
        //
        "v,p": ({ key, ctrlKey, metaKey }) => {
          const shouldPaste =
            is(yankId) && (key === "p" || (key === "v" && (ctrlKey || metaKey)))
          const hasAcceptedModel =
            yankModel === "metric" || yankModel === "quote"

          if (shouldPaste && hasAcceptedModel) {
            create({
              productId,
              cardId,
              type: yankModel,
              position: positionAfter({ id: focusId, items }),
              data: {
                [`${yankModel}Id`]: yankId,
              },
            }).then(({ error, result }) => {
              if (!is(error)) {
                clearYank()

                setFocus({ id: result.id })
              }
            })
          }
        },

        //
        // Switch edit mode
        //
        "i,e": event => {
          if (is(focusId)) {
            event.preventDefault()

            setFocus({ status: "update" })
          }
        },

        // Open file browser for image fields
        u: handleUploadTrigger,

        // Delete selected field
        "d,Backspace": () => {
          if (
            is(focusId) &&
            focusId !== `${cardId}-title` &&
            focusId !== `${cardId}-component`
          ) {
            const field = findWith({ id: focusId })(items)
            const fieldTitle = pipe(
              pathOr("", ["data", "value"]),
              when(
                propSatisfies(gt(50), "length"),
                pipe(take(50), append("…"), join(""))
              )
            )(field)

            if (
              window.confirm(`Delete "${field.type}" field "${fieldTitle}"?`)
            ) {
              const isLast = pipe(last, propEq("id", focusId))(items)
              const newFocusId = hover(isLast ? "up" : "down", {
                id: focusId,
                items,
              })

              setFocus({ id: newFocusId })

              remove(focusId).then(({ error }) => {
                if (is(error)) {
                  setFocus({ id: focusId })
                }
              })
            }
          }
        },
      },
    })

    return () => removeShortcuts({ layer: sectionLayer })
  }, [
    productId,
    cardId,
    overlayId,
    focusId,
    yankId,
    yankModel,
    focusStatus,
    sectionLayer,
    type,
    items,
    clearYank,
    setFocus,
    setOverlayId,
    create,
    update,
    remove,
    handleUploadTrigger,
    onEscape,
  ])
}
