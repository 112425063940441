const debug = require("debug")("mutant:LoginPage")

import React from "react"
import { isEmpty } from "@mutant-ws/m"
import { useHistory } from "react-router-dom"

import { useQuery } from "/core.hooks/use-query"
import { useAuth } from "/core.hooks/use-auth/use-auth"
import { getPath } from "/core.libs/routes"

// Entry point from email
const LoginPage = () => {
  const history = useHistory()
  const [{ token }] = useQuery()
  const { loginWithOneTimeToken } = useAuth()

  if (isEmpty(token)) {
    history.push(getPath("home"))
  } else {
    // Do not trigger any other actions before this.
    // It will go into infinite loop since this method is triggered before
    // rendering the App.
    loginWithOneTimeToken({ token })
      .then(() => {
        history.push(getPath("users:profile"))
      })
      .catch(error => {
        debug({ error })
        history.push(getPath("home"))
      })
  }

  return <center>Loggin in ...</center>
}

export { LoginPage }
