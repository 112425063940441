const debug = require("debug")("mutant:UISelect")

import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { is } from "@mutant-ws/m"
import { keys, map } from "ramda"

import { deepReactMemo } from "../../core.hooks/use-deep"

import css from "./select.css"

const UISelect = ({
  value,
  placeholder,
  error,
  options,
  isDisabled,
  onChange,
}) => (
  <select
    className={cx(css.select, {
      [css["is-error"]]: is(error),
    })}
    value={value}
    disabled={isDisabled}
    onChange={onChange}>
    {is(placeholder) && <option value="">{placeholder}</option>}
    {map(({ label, value: optionValue }) => (
      <option key={optionValue} value={optionValue}>
        {is(label) ? label : optionValue}
      </option>
    ))(options)}
  </select>
)

UISelect.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
    })
  ),
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
}

UISelect.defaultProps = {
  value: null,
  placeholder: null,
  error: null,
  options: [],
  isDisabled: false,
  onChange: null,
}

const memo = deepReactMemo(UISelect, keys(UISelect.propTypes))

export { memo as UISelect }
