const debug = require("debug")("mutant:UISuggestions")

import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { get, head, map, pipe, count, isEmpty, is } from "@mutant-ws/m"
import { groupBy } from "ramda"

import { deepReactMemo } from "/core.hooks/use-deep"

import { UISuggestionsItem } from "./suggestions-item"
import { UISuggestionsItemExtended } from "./suggestions-item--extended"

import css from "./suggestions.css"

const UISuggestions = ({
  className,
  commands,
  focusCmdName,
  paramIndex,
  onClick,
  onMouseEnter,
}) => {
  return isEmpty(commands) ? null : (
    <ul
      className={cx(css.list, {
        [className]: is(className),
        [css["list--has-multiple"]]: count(commands) > 1,
      })}>
      {count(commands) === 1
        ? pipe(head, ({ name, description, params }) => {
            return (
              <UISuggestionsItemExtended
                name={name}
                description={description}
                params={params}
                paramIndex={paramIndex}
                isFocus={focusCmdName === name}
                onMouseDown={onClick}
                onMouseEnter={onMouseEnter}
              />
            )
          })(commands)
        : pipe(
            groupBy(get("layer")),
            Object.entries,
            map(([layer, layerCommands], index) => (
              <React.Fragment key={layer}>
                {index >= 1 ? <li className={css["group-name"]} /> : null}

                {map(({ name, abstract, params, currentParamIndex }) => (
                  <UISuggestionsItem
                    key={name}
                    name={name}
                    description={abstract}
                    params={params}
                    paramIndex={currentParamIndex}
                    isFocus={focusCmdName === name}
                    onMouseDown={onClick}
                    onMouseEnter={onMouseEnter}
                  />
                ))(layerCommands)}
              </React.Fragment>
            ))
          )(commands)}
    </ul>
  )
}

const CommandPropType = PropTypes.shape({
  layer: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  abstract: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  params: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["text", "password"]),
    })
  ),
  onFinish: PropTypes.func.isRequired,
})

UISuggestions.propTypes = {
  className: PropTypes.string,
  commands: PropTypes.arrayOf(CommandPropType),
  focusCmdName: PropTypes.string,
  paramIndex: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
}

UISuggestions.defaultProps = {
  className: null,
  focusCmdName: null,
  commands: [],
  paramIndex: null,
}

const memo = deepReactMemo(UISuggestions)

export { memo as UISuggestions }
