const debug = require("debug")("mutant:FieldsContainer")

import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import {
  pipe,
  remove,
  reduce,
  flatten,
  map,
  not,
  read,
  equals,
  endsWith,
  filterWith,
  findWith,
  minBy,
  distinct,
  sort,
  sortWith,
  isEmpty,
  isNothing,
} from "@asd14/m"

import { useLiveList } from "/core.hooks/use-live-list"
import { useList } from "/core.hooks/use-list"
import { useQuery } from "/core.hooks/use-query"
import { useAuth } from "/core.hooks/use-auth/use-auth"
import { useEffect, useMemo } from "/core.hooks/use-deep"
import { useFocus, WORK_CARD_FIELDS_LAYER } from "/core.hooks/use-focus"

import { FieldsList } from "./data/list.fields"
import { EventsList } from "./data/list.events"
import { MetricsList } from "./data/list.metrics"
import { QuotesList } from "./data/list.quotes"

import { useFieldsKeyboard } from "./fields.keyboard"
import { useFieldsCLI } from "./fields.cli"
import { FieldsView } from "./fields.view"

const FieldsContainer = ({
  productId,
  componentId,
  type,
  title,
  members,
  assignedTo,
  onAssigneeChange,
  onComponentChange,
  onTitleChange,
  onEscape,
}) => {
  const [{ "work-id": cardQueryId }] = useQuery()
  const [overlayId, setOverlayId] = useState()
  const { id: userId } = useAuth()

  //
  // Fields - data fetching
  //

  const {
    selector: { items },
    create,
    read: readFields,
    update,
    remove: removeField,
  } = useLiveList(FieldsList, {
    events: {
      prefix: "fields",
      shouldAcceptFn: useCallback((data = {}) => data.cardId === cardQueryId, [
        cardQueryId,
      ]),
    },
  })

  useEffect(() => {
    readFields({ productId, cardId: cardQueryId })
  }, [productId, cardQueryId, readFields])

  const fields = sortWith("position")(items())
  const fieldsWithOthers = pipe(source => {
    const minPosition = pipe(minBy(read("position")), read("position"))(source)

    return [
      ...(type === "feature"
        ? [
            {
              id: `${cardQueryId}-component`,
              data: { value: componentId },
              type: "text",
              position: minPosition - 4,
            },
          ]
        : []),
      {
        id: `${cardQueryId}-title`,
        data: { value: title },
        type: "text",
        position: minPosition - 2,
      },
      ...source,
    ]
  }, sortWith("position"))(items())

  //
  // Quotes - data fetching
  //

  const {
    selector: { items: quoteItems },
    read: readQuotes,
  } = useList(QuotesList)

  const quoteIds = pipe(
    filterWith({ type: "quote" }),
    map(read(["data", "quoteId"])),
    distinct,
    remove(isNothing),
    sort((a, b) => a > b)
  )(fields)

  useEffect(() => {
    if (!isEmpty(quoteIds)) {
      readQuotes({ id: quoteIds, productId })
    }
  }, [productId, quoteIds, readQuotes])

  //
  // Metrics - data fetching
  //

  const {
    selector: { items: metricItems },
    read: readMetrics,
  } = useLiveList(MetricsList, { events: { prefix: "metrics" } })

  const metricIds = pipe(
    filterWith({ type: "metric" }),
    map(read(["data", "metricId"])),
    distinct,
    remove(isNothing),
    sort((a, b) => a > b)
  )(fields)

  useEffect(() => {
    if (!isEmpty(metricIds)) {
      readMetrics({ id: metricIds, productId })
    }
  }, [productId, metricIds, readMetrics])

  //
  // Events - data fetching
  //

  const {
    selector: { items: eventItems },
    read: readEvents,
  } = useList(EventsList)

  const eventNames = pipe(
    map(read(["code", "events"])),
    flatten,
    distinct,
    sort((a, b) => a > b)
  )(metricItems())

  useEffect(() => {
    if (!isEmpty(eventNames)) {
      readEvents({ productId, names: eventNames })
    }
  }, [productId, eventNames, readEvents])

  //
  // Create a ref for each image field.
  // Allows triggering of file browser from keyboard shortcut.
  //

  const refs = useMemo(
    () =>
      pipe(
        filterWith({ type: "image" }),
        reduce(
          (acc, item) => ({
            ...acc,
            [item.id]: React.createRef(),
          }),
          {}
        )
      )(fields),
    [fields]
  )

  //
  // Fields keyboard shortcuts
  //

  const [{ id: focusId, layer, status: focusStatus }, setFocus] = useFocus()
  const hasKeyboard = layer === WORK_CARD_FIELDS_LAYER

  useFieldsKeyboard(WORK_CARD_FIELDS_LAYER, {
    productId,
    cardId: cardQueryId,
    overlayId,
    type,
    fieldsList: {
      create,
      update,
      remove: removeField,
      items: fieldsWithOthers,
    },
    imageRefs: refs,
    setOverlayId,
    onEscape,
  })

  //
  // Fields CLI commands
  //

  useFieldsCLI(WORK_CARD_FIELDS_LAYER, {
    productId,
    cardId: cardQueryId,
    focusId,
    fieldsList: { create, items: fieldsWithOthers },
    onFocus: useCallback(
      id => setFocus({ id, layer: WORK_CARD_FIELDS_LAYER }),
      [setFocus]
    ),
  })

  //
  // Action handlers
  //

  const handleTextFieldsUpdate = useCallback(
    (id, { value }) => {
      setFocus({ status: "read" })

      if (endsWith("component")(id)) {
        onComponentChange(value)
      } else if (endsWith("title")(id)) {
        onTitleChange(value)
      } else {
        update(id, {
          cardId: cardQueryId,
          value,
        })
      }
    },
    [cardQueryId, onTitleChange, onComponentChange, setFocus, update]
  )

  const handleEditMode = useCallback(
    id => {
      const isImage = pipe(
        findWith({ id }),
        read("type"),
        equals("image")
      )(fields)

      if (isImage) {
        refs[id].current.click()
      } else {
        setFocus({ id, layer: WORK_CARD_FIELDS_LAYER, status: "update" })
      }
    },
    [fields, refs, setFocus]
  )

  return (
    <FieldsView
      ref={refs}
      cardId={cardQueryId}
      componentId={componentId}
      editId={hasKeyboard && focusStatus === "update" ? focusId : null}
      focusId={hasKeyboard && focusStatus === "read" ? focusId : null}
      overlayId={overlayId}
      type={type}
      title={title}
      assignedTo={assignedTo}
      members={members}
      membersPresent={pipe(
        filterWith({
          isOnline: true,
          userId: not(equals(userId)),
          location: pipe(read("layer"), equals(WORK_CARD_FIELDS_LAYER)),
        }),
        map(item => ({
          userId: read("userId")(item),
          fieldId: read(["location", "focusId"])(item),
        }))
      )(members)}
      fields={fields}
      metrics={metricItems()}
      events={eventItems()}
      quotes={quoteItems()}
      onAssigneeChange={onAssigneeChange}
      onHighlight={useCallback(
        id => {
          setFocus({ id, layer: WORK_CARD_FIELDS_LAYER })
        },
        [setFocus]
      )}
      onEditMode={handleEditMode}
      onEditModeCancel={useCallback(() => {
        setFocus({ status: "read" })
      }, [setFocus])}
      onTextFieldUpdate={handleTextFieldsUpdate}
      onImageFieldChoose={useCallback(
        ({ id, event }) => {
          update(
            id,
            { cardId: cardQueryId, file: event.target.files[0] },
            { action: "upload" }
          )
        },
        [cardQueryId, update]
      )}
      onImageFieldClick={useCallback(
        id => {
          setFocus({ id, layer: WORK_CARD_FIELDS_LAYER })
        },
        [setFocus]
      )}
      onImageFieldImgClick={useCallback(id => {
        setOverlayId(id)
      }, [])}
    />
  )
}

FieldsContainer.propTypes = {
  productId: PropTypes.string.isRequired,
  componentId: PropTypes.string,
  type: PropTypes.oneOf(["feature", "work"]),
  title: PropTypes.string,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
      bgColor: PropTypes.string,
      fgColor: PropTypes.string,
    })
  ).isRequired,
  assignedTo: PropTypes.string,
  onAssigneeChange: PropTypes.func.isRequired,
  onComponentChange: PropTypes.func.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onEscape: PropTypes.func.isRequired,
}

FieldsContainer.defaultProps = {
  componentId: "",
  type: null,
  title: null,
  assignedTo: null,
}

export { FieldsContainer }
