const debug = require("debug")("mutant:useProduct.ProductsList")

import { buildList } from "@mutant-ws/redux-list"
import { GET } from "@mutant-ws/fetch-browser"

const ProductsList = buildList({
  name: "GLOBAL.PRODUCTS",

  read: id =>
    GET(`/products/${id}`, {
      query: {
        pluck: ["name", "createdAt"],
      },
    }),
})

export { ProductsList }
