const debug = require("debug")("mutant:UIVxBarView")

import React from "react"
import PropTypes from "prop-types"
import { DateTime } from "luxon"
import { reduce, read, flatten, findWith, max, pipe, map, is } from "@asd14/m"
import { mergeAll } from "ramda"

import { BarGroup } from "@vx/shape"
import { Group } from "@vx/group"
import { AxisBottom, AxisLeft } from "@vx/axis"

import { scaleLinear, scaleBand } from "d3-scale"
import { timeFormat } from "d3-time-format"
import { format } from "d3-format"

import { deepReactMemo } from "../../core.hooks/use-deep"

const UIVxBarView = ({
  width,
  height,
  color,
  dataSets,
  numTicksRows,
  leftAxisWidth,
  bottomAxisHeight,
  xDomain,
  xGroupScale,
  hasAxisLeft,
  hasAxisBottom,
  onPointHover,
}) => {
  const eventIds = map(read("id"), dataSets)

  const chartWidth = hasAxisLeft ? width - leftAxisWidth : width
  const chartHeight = hasAxisBottom ? height - bottomAxisHeight : height

  const xBarScale = scaleBand()
    // no of bars in a group = no of event data sets
    .domain(eventIds)
    // spread on the width of one group
    .range([0, xGroupScale.bandwidth()])
    .padding(0.2)

  /**
   * Turn all data sets into a single array containing all .y, grouped by .x
   *
   * @typedef  MergedDataSet
   * @type     {Object[]}
   *
   * @property {Data}   x
   * @property {Number} [eventId] y value for eventId
   *
   * @example
   * // input
   * [
   *  {id: "event1", points: [{x: Date, y: 2}]},
   *  {id: "event2", points: [{x: Date, y: 6}]}
   * ]
   *
   * // output
   * [{
   *  x: Date,
   *  event1: 2,
   *  event2: 6,
   * }]
   */
  const mergedDataSet = map(item => {
    const yValues = map(
      ({ id, points }) => ({
        [id]: pipe(
          findWith({
            x: source => source.getTime() === item.getTime(),
          }),
          read("y")
        )(points),
      }),
      dataSets
    )

    return mergeAll([{ x: item }, ...yValues])
  }, xDomain)

  const maxYFromAllDataSets = pipe(
    map([read("points"), map(read("y"))]),
    flatten,
    max
  )(dataSets)

  const yScale = scaleLinear()
    .domain([0, maxYFromAllDataSets])
    .range([chartHeight, 0])

  return (
    <React.Fragment>
      {hasAxisLeft ? (
        <AxisLeft
          top={0}
          left={leftAxisWidth}
          scale={yScale}
          numTicks={numTicksRows}
          stroke="rgba(0,0,0,.1)"
          tickStroke="rgba(0,0,0,0.1)"
          tickLabelProps={() => ({
            fontSize: "12px",
            textAnchor: "end",
            dy: "0.25em",
          })}
          tickFormat={format("~s")}
          hideZero={true}
        />
      ) : null}

      <BarGroup
        data={mergedDataSet}
        keys={eventIds}
        width={chartWidth}
        height={chartHeight}
        x0={read("x")}
        x0Scale={xGroupScale}
        x1Scale={xBarScale}
        yScale={yScale}
        color={color}>
        {map(({ index: groupIndex, x0, bars }) => {
          const handleHover = () => {
            if (is(onPointHover)) {
              onPointHover(xDomain[groupIndex])
            }
          }

          return (
            <Group
              key={`group-${groupIndex}-${x0}`}
              left={hasAxisLeft ? leftAxisWidth + x0 : x0}>
              <rect
                width={xGroupScale.bandwidth()}
                height={chartHeight}
                fill="transparent"
                onTouchStart={handleHover}
                onTouchMove={handleHover}
                onMouseEnter={handleHover}
              />
              {map(
                ({
                  index: barIndex,
                  color: barColor,
                  width: barWidth,
                  height: barHeight,
                  key,
                  value,
                  x,
                  y,
                }) => (
                  <rect
                    key={`bar-${groupIndex}-${barIndex}-${value}-${key}`}
                    x={x}
                    y={y}
                    width={barWidth}
                    height={barHeight}
                    fill={barColor}
                    rx={2}
                  />
                )
              )(bars)}
            </Group>
          )
        })}
      </BarGroup>

      {hasAxisBottom ? (
        <AxisBottom
          top={chartHeight}
          left={hasAxisLeft ? leftAxisWidth : 0}
          scale={xGroupScale}
          stroke="rgba(0,0,0,.1)"
          tickValues={reduce(
            // only show Monday ticks
            (acc, item) =>
              DateTime.fromJSDate(item).weekday === 1 ? [...acc, item] : acc,
            []
          )(xDomain)}
          tickStroke="rgba(0,0,0,0.1)"
          tickFormat={timeFormat("%b %d, '%y")}
          tickLabelProps={() => ({
            fontSize: "12px",
            textAnchor: "middle",
            dy: "10",
          })}
        />
      ) : null}
    </React.Fragment>
  )
}

UIVxBarView.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.func.isRequired,
  leftAxisWidth: PropTypes.number.isRequired,
  bottomAxisHeight: PropTypes.number.isRequired,
  dataSets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.instanceOf(Date).isRequired,
          y: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  xDomain: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  xGroupScale: PropTypes.func.isRequired,
  numTicksRows: PropTypes.number,
  hasAxisLeft: PropTypes.bool,
  hasAxisBottom: PropTypes.bool,
  onPointHover: PropTypes.func,
}

UIVxBarView.defaultProps = {
  dataSets: [],
  numTicksRows: 3,
  hasAxisLeft: true,
  hasAxisBottom: true,
  onPointHover: null,
}

const memo = deepReactMemo(UIVxBarView)

export { memo as UIVxBarView }
