const debug = require("debug")("mutant:UIUserMenu")

import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import { getPath } from "/core.libs/routes"

import { UILogo } from "/core.ui/logo/logo"

import css from "./usermenu.css"

const UIUserMenu = ({ onClick }) => (
  <nav className={css.menu}>
    <ul className={css.content}>
      <li className={css.menu__logo}>
        <Link to={getPath("home")} onClick={onClick("logo")}>
          <UILogo height="27px" />
        </Link>
      </li>
    </ul>
  </nav>
)

UIUserMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const memo = React.memo(UIUserMenu)

export { memo as UIUserMenu }
