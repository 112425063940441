const debug = require("debug")("mutant:UserLayout")

import React, { useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"

import { getPath } from "/core.libs/routes"
import { useAuth } from "/core.hooks/use-auth/use-auth"
import { useCommands } from "/core.hooks/use-commands"

import { BaseLayout } from "/layout.base/base"

const UserLayout = ({ children }) => {
  const history = useHistory()
  const { logout } = useAuth()
  const [, [addCommands, removeCommands]] = useCommands()

  const handleCmdMe = useCallback(
    () => history.push(getPath("users:profile")),
    [history]
  )

  useEffect(() => {
    addCommands({
      layer: "base",
      commands: [
        {
          name: "logout",
          abstract: "ok, bye!",
          description: "ok, bye!",
          onFinish: logout,
        },
        {
          name: "me",
          abstract: "go to your profile page",
          description: "go to your profile page",
          onFinish: handleCmdMe,
        },
      ],
    })

    return () => removeCommands({ layer: "base" })
  }, [logout, handleCmdMe, addCommands, removeCommands])

  return <BaseLayout>{children}</BaseLayout>
}

UserLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { UserLayout }
