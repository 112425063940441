const debug = require("debug")("mutant:useColorScale")

import { scaleOrdinal } from "d3-scale"

import { useEffect, useMemo } from "/core.hooks/use-deep"

const bgColorScale = scaleOrdinal().range([
  "#FF6464",
  "#FFFF9B",
  "#29E4A1",
  "#E6E6FF",
  "#6464C8",
  "#FFBCBF",
  "#A9F4D9",
])

const fgColorScale = scaleOrdinal().range([
  "#FFFF9B",
  "#29E4A1",
  "#E6E6FF",
  "#FF6464",
  "#FFBCBF",
  "#A9F4D9",
  "#6464C8",
])

export const useColorScale = (userIds = []) => {
  useEffect(() => {
    bgColorScale.domain([null, ...userIds])
    fgColorScale.domain([null, ...userIds])
  }, [userIds])

  return [bgColorScale, fgColorScale]
}

export const useHeavyColorScale = domain =>
  useMemo(
    () =>
      scaleOrdinal()
        .domain(domain)
        .range([
          "#6d76ca",
          "#ff6347",
          "#913596",
          "#ffa000",
          "#22b573",
          "#fc4b96",
          "#29abe2",
          "#90c300",
          "#a67c52",
          "#999999",
        ]),
    [domain]
  )
