const debug = require("debug")("mutant:FieldMetricUI")

import React, { useEffect, useRef, useState, useCallback } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { map, prop } from "ramda"
import { is, isEmpty } from "@mutant-ws/m"
import { ParentSize } from "@vx/responsive"
import { scaleOrdinal } from "d3-scale"

import { deepReactMemo, useMemo } from "/core.hooks/use-deep"

import { UITag } from "/core.ui/tag/tag"
import { UIFocusers } from "/core.ui/focusers/focusers"
import { UIVxLegend } from "/core.ui/vx-legend/vx-legend"
import { UIVxArea } from "/core.ui/vx-area/vx-area"
import { UIVxBar } from "/core.ui/vx-bar/vx-bar"

import css from "./field-metric.css"

const FieldMetricUI = ({
  className,
  id,
  title,
  type,
  dataSets,
  usersFocusing,
  isFocus,
  onClick,
}) => {
  const [highlightX, setHighlightX] = useState()
  const fieldRef = useRef()

  const handleHightlightPoint = useCallback(source => {
    setHighlightX(is(source) ? source : null)
  }, [])

  const eventNames = map(prop("id"))(dataSets)
  const color = useMemo(
    () =>
      scaleOrdinal()
        .domain(eventNames)
        .range([
          "#6d76ca",
          "#ff6347",
          "#913596",
          "#ffa000",
          "#22b573",
          "#fc4b96",
          "#29abe2",
          "#90c300",
          "#a67c52",
          "#999999",
        ]),
    [eventNames]
  )

  useEffect(() => {
    if (is(fieldRef.current) && isFocus) {
      // fieldRef.current.scrollIntoView({
      //   block: "center",
      //   behavior: "smooth",
      // })
    }
  }, [isFocus])

  return (
    <div
      ref={fieldRef}
      className={cx(css.metric, {
        [className]: is(className),
        [css["is-focus"]]: isFocus,
      })}
      onClick={event => {
        if (is(onClick)) {
          onClick(id, event)
        }
      }}>
      <UIFocusers users={usersFocusing} />

      <UITag className={css.tag} text="analytics" color="blue" size="small" />

      {isEmpty(title) ? null : <div className={css.title}>{title}</div>}

      <div className={css.legend}>
        <UIVxLegend size={15} color={color} />
      </div>

      {isEmpty(dataSets) ? (
        <div className={css["chart--empty"]}>
          ¯\_(ツ)_/¯
          <small>
            You need to measure to <strong>know</strong>.
          </small>
        </div>
      ) : (
        <div className={css.chart}>
          <ParentSize>
            {({ width, height }) => {
              if (type === "bar") {
                return (
                  <UIVxBar
                    width={width}
                    height={height}
                    highlightX={highlightX}
                    color={color}
                    dataSets={dataSets}
                    hasAxisBottom={true}
                    hasTooltip={true}
                    onPointHover={handleHightlightPoint}
                  />
                )
              }

              return (
                <UIVxArea
                  width={width}
                  height={height}
                  highlightX={highlightX}
                  color={color}
                  dataSets={dataSets}
                  isSmooth={true}
                  hasAxisBottom={true}
                  hasTooltip={true}
                  onPointHover={handleHightlightPoint}
                />
              )
            }}
          </ParentSize>
        </div>
      )}
    </div>
  )
}

FieldMetricUI.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  dataSets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.instanceOf(Date).isRequired,
          y: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  usersFocusing: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
      isOnline: PropTypes.bool,
      location: PropTypes.shape({
        status: PropTypes.oneOf(["read", "update"]),
      }),
    })
  ),
  isFocus: PropTypes.bool,
  onClick: PropTypes.func,
}

FieldMetricUI.defaultProps = {
  className: null,
  title: null,
  type: "area",
  dataSets: [],
  usersFocusing: [],
  isFocus: false,
  onClick: null,
}

const memo = deepReactMemo(FieldMetricUI)

export { memo as FieldMetricUI }
