const debug = require("debug")("mutant:SiblingUI")

import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { isEmpty, is } from "@mutant-ws/m"

import { UIAvatar } from "/core.ui/avatar/avatar"

import css from "./sibling.css"

const SiblingUI = ({
  id,
  title,
  userName,
  userAvatarURL,
  avatarBackgroundColor,
  avatarForegroundColor,
  isSelect,
  isFocus,
  onClick,
}) => {
  const siblingRef = useRef()

  useEffect(() => {
    if (is(siblingRef.current) && isFocus) {
      siblingRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      })
    }
  }, [isFocus])

  return (
    <div ref={siblingRef} className={css["sibling-wrapper"]}>
      <div
        className={cx(css.sibling, {
          [css["sibling--is-select"]]: isSelect,
          [css["sibling--is-focus"]]: isFocus,
        })}
        onClick={() => onClick(id)}>
        {is(userName) ? (
          <UIAvatar
            className={css.avatar}
            name={userName}
            avatar={userAvatarURL}
            size={20}
            backgroundColor={avatarBackgroundColor}
            foregroundColor={avatarForegroundColor}
          />
        ) : null}
        {isEmpty(title) ? <i>Empty title value</i> : title}
      </div>
    </div>
  )
}

SiblingUI.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  userName: PropTypes.string,
  userAvatarURL: PropTypes.string,
  avatarBackgroundColor: PropTypes.string,
  avatarForegroundColor: PropTypes.string,
  isSelect: PropTypes.bool.isRequired,
  isFocus: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

SiblingUI.defaultProps = {
  userName: null,
  userAvatarURL: null,
  avatarBackgroundColor: "",
  avatarForegroundColor: "",
}

const memo = React.memo(SiblingUI)

export { memo as SiblingUI }
