/* eslint-disable camelcase */
const debug = require("debug")("mutant:Redux")

import { createStore, combineReducers } from "redux"

// GLOBAL
import * as useAuth from "/core.hooks/use-auth/reducers"
import * as useYank from "/core.hooks/use-yank/reducers"
import * as useSocket from "/core.hooks/use-socket"
import * as useFocus from "/core.hooks/use-focus"
import * as useCommands from "/core.hooks/use-commands"
import { ProductsList } from "/core.hooks/use-product/list.products"
import { MembersList } from "/core.hooks/use-product/list.members"

// HOMEPAGE
import { EarlyAccessList } from "/page.home/_.early-access/data/list.early-access"

// PROFILE
import { UsersList as ProfileUsersList } from "/page.profile/_.me/data/list.users"
import { ProductsList as ProfileProductsList } from "/page.profile/_.products/data/list.products"

// PRODUCT FEEDBACK
import { QuotesList as Plan_QuotesList } from "/product.feedback.quotes/data/list.quotes"
import { VotesList as Plan_VotesList } from "/product.feedback.quotes/data/list.votes"
import { InsightsList as Plan_InsightsList } from "/product.feedback.insights/data/list.insights"

// PRODUCT WORK BOARD
import { CardsList as WorkBoard_CardsList } from "/product.work.board/_.columns/data/list.cards"
import { FeaturesList as WorkBoard_FeaturesList } from "/product.work.board/_.features/data/list.features"
import { MetricsList as WorkBoard_MetricsList } from "/product.work.board/_.features/data/list.metrics"
import { EventsList as WorkBoard_EventsList } from "/product.work.board/_.features/data/list.events"

// PRODUCT WORK CARD
import { EventsList as WorkCard_EventsList } from "/product.work.card/_.fields/data/list.events"
import { FieldsList as WorkCard_FieldsList } from "/product.work.card/_.fields/data/list.fields"
import { MetricsList as WorkCard_MetricsList } from "/product.work.card/_.fields/data/list.metrics"
import { QuotesList as WorkCard_QuotesList } from "/product.work.card/_.fields/data/list.quotes"
import { CardsList as WorkCard_CardsList } from "/product.work.card/data/list.cards"
import { SiblingsList as WorkCard_SiblingsList } from "/product.work.card/_.siblings/data/list.siblings"
import { CommentsList as WorkCard_CommentsList } from "/product.work.card/_.comments/data/list.comments"

// PRODUCT MEASURE
import { EventsList as MeasureEventsList } from "/product.measure.metrics/data/list.events"
import { MetricsList as MeasureMetricsList } from "/product.measure.metrics/data/list.metrics"

const appReducer = combineReducers({
  [useAuth.STORE_KEY]: useAuth.reducer,
  [useYank.STORE_KEY]: useYank.reducer,
  [useSocket.STORE_KEY]: useSocket.reducer,
  [useFocus.STORE_KEY]: useFocus.reducer,
  [useCommands.STORE_KEY]: useCommands.reducer,
  [ProductsList.name]: ProductsList.reducer,
  [MembersList.name]: MembersList.reducer,

  [EarlyAccessList.name]: EarlyAccessList.reducer,

  [ProfileUsersList.name]: ProfileUsersList.reducer,
  [ProfileProductsList.name]: ProfileProductsList.reducer,

  [Plan_QuotesList.name]: Plan_QuotesList.reducer,
  [Plan_InsightsList.name]: Plan_InsightsList.reducer,
  [Plan_VotesList.name]: Plan_VotesList.reducer,

  [WorkBoard_CardsList.name]: WorkBoard_CardsList.reducer,
  [WorkBoard_FeaturesList.name]: WorkBoard_FeaturesList.reducer,
  [WorkBoard_MetricsList.name]: WorkBoard_MetricsList.reducer,
  [WorkBoard_EventsList.name]: WorkBoard_EventsList.reducer,

  [WorkCard_CardsList.name]: WorkCard_CardsList.reducer,
  [WorkCard_FieldsList.name]: WorkCard_FieldsList.reducer,
  [WorkCard_EventsList.name]: WorkCard_EventsList.reducer,
  [WorkCard_MetricsList.name]: WorkCard_MetricsList.reducer,
  [WorkCard_QuotesList.name]: WorkCard_QuotesList.reducer,
  [WorkCard_SiblingsList.name]: WorkCard_SiblingsList.reducer,
  [WorkCard_CommentsList.name]: WorkCard_CommentsList.reducer,

  [MeasureMetricsList.name]: MeasureMetricsList.reducer,
  [MeasureEventsList.name]: MeasureEventsList.reducer,
})

// Provide `appReducer` undefined as `state` param to force revert to default
// state when logging out
const rootReducer = (state, action) =>
  appReducer(action.type === "LOGOUT" ? undefined : state, action)

export const mutantStore = createStore(
  rootReducer,

  // Firefox redux extension
  process.env.NODE_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
)
