const debug = require("debug")("mutant:UISimpleHist")

import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { map, is } from "@mutant-ws/m"

import css from "./hist.css"

const UISimpleHist = ({ className, total, items }) => {
  return (
    <div
      className={cx(css.summary, {
        [className]: is(className),
      })}>
      {map((item, index) => (
        <div key={`bar-${index}`}>
          <div
            className={css.summary__complete}
            style={{
              height: `${total === 0 ? 0 : (item / total) * 100}%`,
            }}
          />
        </div>
      ))(items)}
    </div>
  )
}

UISimpleHist.propTypes = {
  className: PropTypes.string,
  total: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.number),
}

UISimpleHist.defaultProps = {
  className: null,
  items: 0,
}

const memo = React.memo(UISimpleHist)

export { memo as UISimpleHist }
