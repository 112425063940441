const debug = require("debug")("mutant:CardsList")

import { buildList } from "@mutant-ws/redux-list"
import { GET } from "@mutant-ws/fetch-browser"
import { sortWith, ascend, prop } from "ramda"

export const SiblingsList = buildList({
  name: "PRODUCT.WORK.CARD.SIBLINGS",

  // get cards attached to feature
  read: ({ productId, featureId }) =>
    GET("/cards", {
      query: {
        filter: {
          productId,
          featureId,
          type: ["work"],
        },
        pluck: ["title", "assignedTo", "type", "status", "position", "isBug"],
      },
    }),

  // get feature
  readOne: id =>
    GET(`/cards/${id}`, {
      query: {
        pluck: ["componentId", "coverURL", "assignedTo", "title", "aggregate"],
      },
    }).then(card => {
      return {
        ...card,
        position: Number.NEGATIVE_INFINITY,
      }
    }),

  // sort based on status and position field, keep feature card first (does
  // not have status field)
  onChange: sortWith([
    ascend(({ status }) =>
      status === "backlog"
        ? 1
        : status === "doing"
        ? 2
        : status === "testing"
        ? 3
        : status === "done"
        ? 4
        : -1
    ),
    ascend(prop("position")),
  ]),
})
