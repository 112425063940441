const debug = require("debug")("mutant:UITag")

import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { is } from "@mutant-ws/m"

import css from "./tag.css"

const UITag = ({
  className,
  placeholder,
  text,
  color,
  size,
  hasShadow,
  onClick,
}) =>
  is(text) ? (
    <span
      className={cx(css.tag, {
        [className]: is(className),
        [css["tag--has-shadow"]]: hasShadow,
        [css[`tag--color-${color}`]]: is(color),
        [css[`tag--size-${size}`]]: is(size),
      })}
      onClick={onClick}>
      {text}
    </span>
  ) : (
    <span
      className={cx(css.tag, css.placeholder, {
        [className]: is(className),
        [css[`tag--size-${size}`]]: is(size),
      })}
      onClick={onClick}>
      {is(placeholder) ? placeholder : <>&nbsp;</>}
    </span>
  )

UITag.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["default", "small"]),
  text: PropTypes.string,
  color: PropTypes.oneOf(["black", "blue", "red"]),
  placeholder: PropTypes.string,
  hasShadow: PropTypes.bool,
  onClick: PropTypes.func,
}

UITag.defaultProps = {
  className: null,
  size: "default",
  placeholder: null,
  text: null,
  color: "black",
  hasShadow: false,
  onClick: null,
}

const memo = React.memo(UITag)

export { memo as UITag }
