const debug = require("debug")("mutant:UILogo")

import React from "react"
import PropTypes from "prop-types"

const UILogo = ({ className, width, height, type }) =>
  type === "black" ? (
    <svg
      className={className}
      width={width}
      height={height}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 150 27"
      enableBackground="new 0 0 150 27"
      xmlSpace="preserve">
      <g>
        <path
          d="M69.61,26.102h-7.25v-2.505h-0.075c-1.405,1.974-3.379,2.998-6.415,2.998c-3.529,0-6.756-2.163-6.756-6.68V6.402h7.516
		v10.324c0,2.277,0.228,3.871,2.467,3.871c1.328,0,2.998-0.645,2.998-3.795v-10.4h7.516V26.102z"
        />
        <path
          d="M82.744,6.402h4.023v4.631h-4.023v6.87c0,1.821,0.379,2.619,2.314,2.619c0.57,0,1.139-0.038,1.709-0.114v5.693
		c-1.52,0-3.189,0.228-4.822,0.228c-3.188,0-6.717-0.493-6.717-5.883v-9.413h-3.34V6.402h3.34V0.405h7.516V6.402z"
        />
        <path
          d="M108.363,21.698c0,1.48,0.039,3.15,0.912,4.403h-7.668c-0.189-0.569-0.305-1.29-0.266-1.859h-0.076
		c-1.633,1.821-3.91,2.353-6.338,2.353c-3.797,0-6.947-1.821-6.947-5.959c0-6.263,7.326-5.959,11.121-6.718
		c1.025-0.19,2.051-0.493,2.051-1.746c0-1.328-1.252-1.822-2.467-1.822c-2.277,0-2.809,1.177-2.809,2.012h-6.984
		c0.189-5.579,5.504-6.452,10.135-6.452c9.412,0,9.336,3.909,9.336,7.705V21.698z M101.152,17.258
		c-0.912,0.455-1.936,0.684-2.961,0.949c-1.67,0.417-2.543,0.797-2.543,2.125c0,0.911,0.986,1.822,2.354,1.822
		c1.707,0,3.035-0.987,3.15-3.074V17.258z"
        />
        <path
          d="M112.35,6.402h7.248v2.505h0.076c1.404-1.974,3.379-2.998,6.414-2.998c3.531,0,6.756,2.163,6.756,6.681v13.512h-7.514
		V15.777c0-2.277-0.266-3.871-2.467-3.871c-1.33,0-3,0.646-3,3.796v10.399h-7.514V6.402z"
        />
        <path
          d="M145.977,6.402H150v4.631h-4.023v6.87c0,1.821,0.381,2.619,2.316,2.619c0.568,0,1.139-0.038,1.707-0.114v5.693
		c-1.518,0-3.188,0.228-4.82,0.228c-3.188,0-6.717-0.493-6.717-5.883v-9.413h-3.34V6.402h3.34V0.405h7.514V6.402z"
        />
        <path
          d="M38.335,5.909c-4.625,0-6.235,2.572-6.656,3.018c-0.967-1.728-2.801-3.018-5.869-3.018c-4.896,0-6.414,2.885-6.718,3.074
		c-0.911-1.974-3.188-3.074-5.313-3.074c-2.733,0-4.935,0.835-6.453,3.112H7.25V6.402H0v19.699h7.516V15.436
		c0-2.087,0.607-3.529,2.543-3.529c2.277,0,2.467,1.67,2.467,3.529v10.666h7.516V15.436c0-2.087,0.607-3.529,2.543-3.529
		c2.277,0,2.467,1.67,2.467,3.529v10.666h7.516V15.333c0.02-2.031,0.639-3.427,2.542-3.427c2.277,0,2.467,1.67,2.467,3.529v10.666
		h7.516V12.59C45.091,9.401,43.383,5.909,38.335,5.909z"
        />
      </g>
    </svg>
  ) : type === "white" ? (
    <svg
      className={className}
      width={width}
      height={height}
      enableBackground="new 0 0 70 13"
      version="1.1"
      viewBox="0 0 70 13"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg">
      <g fill="#fff">
        <path d="m29.444 12.153v-1.14c-0.471 0.665-1.741 1.374-3.35 1.374-2.142 0-3.196-1.236-3.196-2.965v-6.617h3.271v5.363c0 1.157 0.54 1.552 1.52 1.552 0.864 0 1.284-0.471 1.755-0.922v-5.993h2.805v9.349h-2.805z" />
        <path d="m35.518 9.6v-4.457h-1.866v-2.338h1.866v-2.805h2.806v2.805h1.866v2.338h-1.866v3.614c0 0.551 0.316 0.963 0.825 0.963 0.333 0 0.665-0.12 0.764-0.235l0.59 2.274c-0.372 0.354-1.011 0.629-2.131 0.629-1.921 0-2.854-0.962-2.854-2.788z" />
        <path d="m47.202 12.153v-0.944c-0.467 0.71-1.662 1.179-2.883 1.179-1.446 0-3.222-1.002-3.222-3.121 0-2.296 1.757-3.058 3.21-3.058 1.254 0 2.428 0.412 2.895 1.116v-0.943c0-0.82-0.643-1.393-1.86-1.393-0.941 0-1.85 0.373-2.617 1.02l-1.103-2.096c1.219-1.043 2.915-1.472 4.365-1.472 2.277 0 4.482 0.842 4.482 3.786v5.927h-3.267zm0-2.433v-0.843c0-0.452-0.956-0.669-1.624-0.669-0.784 0-1.477 0.354-1.477 1.1 0 0.744 0.677 1.1 1.46 1.1 0.669-1e-3 1.641-0.237 1.641-0.688z" />
        <path d="m58.883 12.153v-5.474c0-1.157-0.546-1.569-1.487-1.569-0.904 0-1.318 0.486-1.783 0.922v6.122h-3.27v-9.349h3.27v1.028c0.465-0.665 1.753-1.392 3.361-1.392 2.138 0 3.183 1.254 3.183 3.001v6.711h-3.274z" />
        <path d="m64.958 9.6v-4.457h-1.868v-2.338h1.868v-2.805h2.806v2.805h1.87v2.338h-1.87v3.614c0 0.551 0.345 0.963 0.855 0.963 0.336 0 0.683-0.12 0.783-0.235l0.598 2.274c-0.372 0.354-1.041 0.629-2.157 0.629-1.925 0-2.885-0.962-2.885-2.788z" />
        <path d="m18.07 2.456c-1.482 0-2.774 0.857-3.323 1.681-0.373-1.139-1.36-1.681-2.704-1.681-1.494 0-2.744 0.865-3.293 1.689-0.392-1.116-1.383-1.689-2.756-1.689-1.494 0-2.724 0.848-3.189 1.396v-1.047h-2.805v9.349h2.805v-6.107c0.465-0.39 1.045-0.921 1.871-0.921 0.904 0 1.399 0.549 1.399 1.276v5.751h2.804v-6.106c0.469-0.39 0.806-0.921 1.629-0.921 0.9 0 1.175 0.549 1.175 1.276v5.751h3.271v-6.127c0-0.392 0.812-0.901 1.617-0.901 0.907 0 1.185 0.549 1.185 1.276v5.751h3.276v-6.85c0-1.941-1.215-2.846-2.962-2.846z" />
      </g>
    </svg>
  ) : null

UILogo.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  type: PropTypes.oneOf(["black", "white"]),
}

UILogo.defaultProps = {
  className: "",
  width: undefined,
  height: undefined,
  type: "black",
}

const memo = React.memo(UILogo)

export { memo as UILogo }
