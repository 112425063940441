/* eslint-disable react/no-multi-comp  */
const debug = require("debug")("mutant:WorkCardUI")

import React, { useEffect, useRef, useState, useCallback } from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { is, isEmpty } from "@mutant-ws/m"

import { deepReactMemo } from "/core.hooks/use-deep"

import { UIAvatar } from "/core.ui/avatar/avatar"
import { UIAvatarGroup } from "/core.ui/avatar/group"
import { UITag } from "/core.ui/tag/tag"
import { UITextarea } from "/core.ui/textarea/textarea"
import { UIErrorBoundary } from "/core.ui/error-boundary/error-boundary"

import css from "./card.css"

const WorkCardUI = ({
  className,
  id,
  title,
  userId,
  userName,
  userAvatarURL,
  usersFocusing,
  userBgColor,
  userFgColor,
  isEdit,
  isBug,
  isSelect,
  isFocus,
  onClick,
  onDoubleClick,
  onTitleEditUpdate,
  onTitleEditCancel,
}) => {
  const cardRef = useRef()

  useEffect(() => {
    if (is(cardRef.current) && isFocus === true) {
      // cardRef.current.scrollIntoView()
    }
  }, [isFocus])

  //
  // Editable title field
  //

  const [localTitle, setLocalTitle] = useState(title)

  useEffect(() => {
    if (!isEdit) {
      setLocalTitle(title)
    }
  }, [title, isEdit])

  const handleLocalTitleChange = useCallback(event => {
    setLocalTitle(event.currentTarget.value)
  }, [])

  const handleLocalTitleSave = useCallback(() => {
    onTitleEditUpdate(localTitle)
  }, [localTitle, onTitleEditUpdate])

  const handleLocalTitleShortcuts = useCallback(
    event => {
      if (event.key === "Tab") {
        // random focus next element
        event.preventDefault()

        onTitleEditUpdate(localTitle)
      }

      if (event.key === "Enter") {
        // prevent new line
        event.preventDefault()

        onTitleEditUpdate(localTitle)
      }

      if (event.key === "Escape") {
        // dont trigger blur my mistake
        event.preventDefault()

        onTitleEditCancel()
      }
    },
    [localTitle, onTitleEditUpdate, onTitleEditCancel]
  )

  return (
    <article ref={cardRef} className={css.wrapper}>
      <div
        className={cx(className, css.card, {
          [css["card--is-select"]]: isSelect,
          [css["card--is-focus"]]: isFocus,
        })}
        onClick={event => {
          event.stopPropagation()

          onClick(id)
        }}
        onDoubleClick={() => {
          event.stopPropagation()

          !isEdit && onDoubleClick(id)
        }}>
        {isEdit ? (
          <UITextarea
            className={css["title-textarea"]}
            id={`work-${id}-title`}
            value={localTitle}
            placeholder="Placeholder for card"
            shouldFocus={true}
            shouldSelectAll={true}
            onChange={handleLocalTitleChange}
            onKeyDown={handleLocalTitleShortcuts}
            onBlur={handleLocalTitleSave}
          />
        ) : (
          <h3
            className={cx(css.title, {
              [css["title--is-empty"]]: isEmpty(title),
            })}>
            {isEmpty(title) ? "Placeholder for card" : title}
          </h3>
        )}
        {isBug || is(userId) ? (
          <div className={css.options}>
            {is(userId) ? (
              <UIAvatar
                className={css.avatar}
                name={userName}
                avatar={userAvatarURL}
                size={20}
                backgroundColor={userBgColor}
                foregroundColor={userFgColor}
              />
            ) : null}

            {isBug ? (
              <UITag className={css.tag} text="bug" color="red" size="small" />
            ) : null}
          </div>
        ) : null}

        <UIAvatarGroup
          className={css.focusers}
          users={usersFocusing}
          limit={2}
        />
      </div>
    </article>
  )
}

WorkCardUI.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  userId: PropTypes.string,
  userName: PropTypes.string,
  userAvatarURL: PropTypes.string,
  usersFocusing: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
      isOnline: PropTypes.bool,
      location: PropTypes.shape({
        status: PropTypes.oneOf(["read", "update"]),
      }),
    })
  ),
  userBgColor: PropTypes.string,
  userFgColor: PropTypes.string,
  isBug: PropTypes.bool,
  isSelect: PropTypes.bool,
  isFocus: PropTypes.bool,
  isEdit: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onTitleEditUpdate: PropTypes.func.isRequired,
  onTitleEditCancel: PropTypes.func.isRequired,
}

WorkCardUI.defaultProps = {
  className: null,
  title: null,
  userId: null,
  userName: null,
  userAvatarURL: null,
  usersFocusing: [],
  userBgColor: null,
  userFgColor: null,
  isBug: false,
  isSelect: false,
  isFocus: false,
  isEdit: false,
}

const MemoWorkCardUI = deepReactMemo(WorkCardUI)

const WorkCardUIWithBoundary = props => {
  return (
    <UIErrorBoundary message="Something inside this card has gone wrong. We put our best middle manager on it!">
      <MemoWorkCardUI {...props} />
    </UIErrorBoundary>
  )
}

export { WorkCardUIWithBoundary as WorkCardUI }
