const debug = require("debug")("mutant:FAQPage")

import React from "react"

import { UIHero } from "../core.ui/hero/hero"

import css from "./faq.css"

const FAQPage = () => {
  return (
    <React.Fragment>
      <UIHero>
        <h1>Frequently asked questions</h1>
      </UIHero>

      <div className={css.content}>
        <h2>Another Agile software development planner?</h2>
        <p />

        <h2>Why do you combine analytics and task management?</h2>
        <p>
          After you organize post-its &amp; do the work, Mutant makes the
          effects visible. Was it worth it? Did I solve someone&apos;s problem
          or just closed a ticket?
        </p>
        <p>
          There&apos;s a reason why the speedometer is in front of the steering
          wheel - and not in the trunk. You can see how fast feels too fast for
          you. You can adapt your speed to the fuel you have left. This is what
          being exposed to that data means.
        </p>
        <p>
          Mutant trains the same kind of intuition: you ask better questions,
          you get more insights, you have the confidence of data that backs up
          your gut feeling.
        </p>

        <h2>
          Why not a plugin that gets data from Google Analytics and works with
          Jira?
        </h2>
        <p>
          We don’t believe in building on rented land, especially adding
          complexity on top of already complex products. We want to rethink a
          this planning business and we believe a zoom-out is order.
        </p>

        <h2>
          Who is this for? What team size or dev stage will this help the most?
        </h2>
        <p>
          Mutant is designed for software development teams. We find Mutant
          ideal for small (5-10 persons), cross-functional teams, in the race
          for product-market fit.
        </p>

        <h2>
          Switching the analytics setup is a huge ask. Why should I do it?
        </h2>
        <p>
          You don&apos;t, not right now. We&apos;re not targeting teams that
          already have a deeply rooted planning &apos; measuring stack.
        </p>
        <p>
          BUT. Tech teams start projects and startups all the time, it&apos;s
          not like anyone loves their planning software. (OK maybe you do, but
          you&apos;re missing out)
        </p>
        <p>
          BUT No.2. Our API takes about 2 minutes to install. It adds no
          complexity for the development team, and the benefits are huge. Give
          it a try, we do believe Mutant is a game-changer for your product
          intuition.
        </p>
        <p>
          And what the hell does it even mean to plan without a direct way of
          measuring? Oh, yeah, post-its and t-shit sizes.
        </p>

        <h2>When will I be invited?</h2>
        <p>
          We onboard teams every week, and work closely with them to develop a
          product that can really make a difference for them. If you have a
          product that is in need of Mutant right away, drop us a line.
        </p>
      </div>
    </React.Fragment>
  )
}

export { FAQPage }
