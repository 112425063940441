const debug = require("debug")("mutant:useBoardCLI")

import { useEffect } from "react"
import { join } from "@mutant-ws/m"

import { useCommands } from "/core.hooks/use-commands"
import {
  useFocus,
  WORK_BOARD_COLUMNS_LAYER,
  WORK_BOARD_FEATURES_LAYER,
} from "/core.hooks/use-focus"

export const useBoardCLI = (
  sectionLayer,
  {
    productId,
    featureId,
    cardsList: { create: createWork },
    featuresList: { create: createFeature },
  }
) => {
  const [, setFocus] = useFocus()
  const [, [addCommands, removeCommands]] = useCommands()

  useEffect(() => {
    addCommands({
      layer: sectionLayer,
      commands: [
        {
          name: "bug",
          abstract: "report a bug",
          description: "report a bug",
          onFinish: (...params) =>
            createWork({
              title: join(" ", params),
              productId,
              featureId,
              status: "backlog",
              isBug: true,
            }).then(({ result, error }) => {
              if (error) {
                throw error
              }

              setFocus({
                id: result.id,
                layer: WORK_BOARD_COLUMNS_LAYER,
                status: "update",
              })
            }),
        },
        {
          name: "feature",
          abstract: "add a new feature",
          description: "add a new feature",
          onFinish: (...params) =>
            createFeature({
              title: join(" ", params),
              productId,
            }).then(({ result, error }) => {
              if (error) {
                throw error
              }

              setFocus({
                id: result.id,
                layer: WORK_BOARD_FEATURES_LAYER,
                status: "update",
              })
            }),
        },
        {
          name: "task",
          abstract: "add a new task",
          description: "add a new task",
          featureId,
          onFinish: (...params) =>
            createWork({
              title: join(" ", params),
              productId,
              featureId,
              status: "backlog",
              isBug: false,
            }).then(({ result }) => {
              setFocus({
                id: result.id,
                layer: WORK_BOARD_COLUMNS_LAYER,
                status: "update",
              })
            }),
        },
      ],
    })

    return () => {
      removeCommands({ layer: sectionLayer })
    }
  }, [
    sectionLayer,
    productId,
    featureId,
    createWork,
    createFeature,
    setFocus,
    addCommands,
    removeCommands,
  ])
}
