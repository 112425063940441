const debug = require("debug")("mutant:useInsightsKeyboard")

import { findWith, is } from "@mutant-ws/m"
import { pipe, last, head, prop, propEq } from "ramda"

import { addShortcuts, removeShortcuts } from "../core.libs/keyboard"
import { hover } from "../core.libs/positioning"
import { useEffect } from "../core.hooks/use-deep"
import { useYank } from "../core.hooks/use-yank/use-yank"
import { useFocus, FEEDBACK_QUOTES_LAYER } from "../core.hooks/use-focus"

export const useInsightsKeyboard = (
  sectionLayer,
  {
    productId,
    focusId,
    insightsList: { items, remove },
    onFocusChange,
    onSelectChange,
  }
) => {
  const [{ layer }, setFocus] = useFocus(productId)
  const { model: yankModel, paste } = useYank()
  const hasKeyboard = layer === sectionLayer

  // hover first when gaining keyboard
  useEffect(() => {
    const hasItems = items.length !== 0

    if (!is(focusId) && hasKeyboard && hasItems) {
      onFocusChange(pipe(head, prop("id"))(items))
    }
  }, [focusId, items, layer, hasKeyboard, onFocusChange])

  useEffect(() => {
    //
    const handleUpDownHover = ({ key, shiftKey }) => {
      if (!shiftKey) {
        onFocusChange(
          hover(key === "ArrowUp" ? "prev" : "next", {
            id: focusId,
            items,
          })
        )
      }
    }

    // Paste user quote into hover insight
    const handleQuotePaste = ({ key, ctrlKey, metaKey }) => {
      const canPaste =
        yankModel === "quote" &&
        (key === "p" || (key === "v" && (ctrlKey || metaKey)))

      if (is(focusId) && canPaste) {
        paste(focusId)
      }
    }

    // Delete hover insight
    const handleInsightDelete = () => {
      if (!is(focusId)) {
        return
      }

      const { body } = findWith({ id: focusId })(items)

      if (window.confirm(`Delete "${body}" insight?`)) {
        remove(focusId, { productId }).then(() => {
          const isLast = pipe(last, propEq("id", focusId))(items)

          onFocusChange(
            hover(isLast ? "prev" : "next", {
              id: focusId,
              items,
            })
          )
        })
      }
    }

    addShortcuts({
      layer: sectionLayer,
      shortcuts: {
        "ArrowUp,ArrowDown": handleUpDownHover,

        // Give keyboard to feedback section
        ArrowRight: () => {
          setFocus({ layer: FEEDBACK_QUOTES_LAYER })
        },

        // Select focus
        Enter: () => {
          if (is(focusId)) {
            onSelectChange(focusId)
          }
        },

        "v,p": handleQuotePaste,

        d: handleInsightDelete,
      },
    })

    return () => removeShortcuts({ layer: sectionLayer })
  }, [
    productId,
    focusId,
    sectionLayer,
    items,
    yankModel,
    paste,
    remove,
    setFocus,
    onFocusChange,
    onSelectChange,
  ])

  return { hasKeyboard }
}
