const debug = require("debug")("mutant:MeasureContainer")

import React, { useEffect } from "react"
import PropTypes from "prop-types"

import {
  useFocus,
  MEASURE_LAYER,
  MEASURE_METRICS_LAYER,
} from "/core.hooks/use-focus"

import { MetricsContainer } from "../product.measure.metrics/metrics.container"

import css from "./measure.css"

const MeasureContainer = ({ productId }) => {
  const [{ layer }, setFocus] = useFocus()

  useEffect(() => {
    if (layer === MEASURE_LAYER) {
      setFocus({ layer: MEASURE_METRICS_LAYER })
    }
  }, [layer, setFocus])

  return (
    <section className={css.measure}>
      <div className={css["metrics-wrapper"]}>
        <MetricsContainer productId={productId} />
      </div>
      <div />
    </section>
  )
}

MeasureContainer.propTypes = {
  productId: PropTypes.string.isRequired,
}

export { MeasureContainer }
