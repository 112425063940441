const debug = require("debug")("mutant:MetricsList")

import { buildList } from "@mutant-ws/redux-list"
import { DELETE, GET, PATCH, POST } from "@mutant-ws/fetch-browser"
import { map, sortBy, prop, pipe, mergeRight } from "ramda"

export const MetricsList = buildList({
  name: "PRODUCT.MEASURE.METRICS",

  create: ({ productId, title, code = {}, position, isKPI }) =>
    POST("/metrics", {
      body: {
        productId,
        code: {
          ...code,
          title,
        },
        position,
        isKPI,
      },
    }),

  read: ({ productId }) =>
    GET("/metrics", {
      query: {
        filter: {
          productId,
        },
        pluck: ["title", "code", "position", "isKPI"],
      },
    }),

  update: (id, { code, position, isKPI }) =>
    PATCH(`/metrics/${id}`, {
      body: {
        code,
        position,
        isKPI,
      },
    }),

  remove: id => DELETE(`/metrics/${id}`),

  onChange: pipe(
    // default values
    map(
      mergeRight({
        code: [],
        position: 0,
      })
    ),
    sortBy(prop("position"))
  ),
})
