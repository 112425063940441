const debug = require("debug")("mutant:QuotesList")

import { buildList } from "@mutant-ws/redux-list"
import { GET } from "@mutant-ws/fetch-browser"

export const QuotesList = buildList({
  name: "PRODUCT.WORK.CARD.FIELDS.QUOTES",

  read: ({ id, productId }) =>
    GET("/quotes", {
      query: {
        filter: {
          id,
          productId,
        },
        pluck: ["body", "aggregate", "contact", "source", "createdAt"],
      },
    }),
})
