const debug = require("debug")("mutant:FieldsView")

import React, { useCallback, forwardRef } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { findWith, filterWith, hasWith, get, is, isEmpty } from "@mutant-ws/m"
import { map, contains, split, length, pipe, ifElse, always } from "ramda"

import { deepReactMemo } from "/core.hooks/use-deep"
import { UITag } from "/core.ui/tag/tag"

import { FieldMarkdownUI } from "./ui/field-markdown/field-markdown"
import { FieldImageUI } from "./ui/field-image/field-image"
import { FieldImageOverlayUI } from "./ui/field-image-overlay/overlay"
import { FieldMetricUI } from "./ui/field-metric/field-metric"
import { FieldQuoteUI } from "./ui/field-quote/field-quote"
import { WorkerUI } from "./ui/worker/worker"

import css from "./fields.css"

const FieldsView = forwardRef(
  (
    {
      cardId,
      componentId,
      editId,
      focusId,
      overlayId,
      type,
      title,
      assignedTo,
      members,
      membersPresent,
      fields,
      metrics,
      events,
      quotes,
      onAssigneeChange,
      onHighlight,
      onEditMode,
      onEditModeCancel,
      onTextFieldUpdate,
      onImageFieldChoose,
      onImageFieldClick,
      onImageFieldImgClick,
    },
    refs
  ) => {
    const handleComponentRender = useCallback(
      source => <UITag text={source} />,
      []
    )

    //
    // Fullscreen image on click
    //

    const overlayImage = is(overlayId)
      ? findWith({ id: overlayId })(fields)
      : null

    const handleImageOverlayClose = useCallback(() => {
      onImageFieldImgClick(null)
    }, [onImageFieldImgClick])

    return (
      <div className={css["fields-wrapper"]}>
        {is(overlayImage) ? (
          <FieldImageOverlayUI
            url={get(["data", "originalURL"])(overlayImage)}
            thumbnailURL={get(["data", "thumbnailURL"])(overlayImage)}
            onClose={handleImageOverlayClose}
          />
        ) : null}

        {type === "feature" ? (
          <FieldMarkdownUI
            className={css["field-component"]}
            id={`${cardId}-component`}
            value={componentId}
            minLines={1}
            usersFocusing={filterWith({
              id: source =>
                hasWith({
                  userId: source,
                  fieldId: `${cardId}-component`,
                })(membersPresent),
            })(members)}
            isEdit={`${cardId}-component` === editId}
            isFocus={`${cardId}-component` === focusId}
            isPlainText={true}
            onRender={handleComponentRender}
            onViewModeClick={onHighlight}
            onViewModeDoubleClick={onEditMode}
            onEditModeCancel={onEditModeCancel}
            onEditModeSubmit={onTextFieldUpdate}
          />
        ) : null}

        <FieldMarkdownUI
          className={css["field-title"]}
          id={`${cardId}-title`}
          value={title}
          minLines={1}
          usersFocusing={filterWith({
            id: source =>
              hasWith({
                userId: source,
                fieldId: `${cardId}-title`,
              })(membersPresent),
          })(members)}
          isFocus={`${cardId}-title` === focusId}
          isEdit={`${cardId}-title` === editId}
          isPlainText={true}
          onRender={useCallback(
            source => (
              <h1>{isEmpty(source) ? <em>Empty title field</em> : source}</h1>
            ),
            []
          )}
          onViewModeClick={onHighlight}
          onViewModeDoubleClick={onEditMode}
          onEditModeCancel={onEditModeCancel}
          onEditModeSubmit={onTextFieldUpdate}
        />

        <WorkerUI
          className={css.worker}
          current={findWith({ userId: assignedTo })(members)}
          items={members}
          onChange={onAssigneeChange}
        />

        <hr />

        {map(
          ({
            id,
            type: fieldType,
            data: { value, thumbnailURL, label, metricId, quoteId } = {},
          }) => {
            const usersFocusing = filterWith({
              id: source =>
                hasWith({
                  userId: source,
                  fieldId: id,
                })(membersPresent),
            })(members)

            switch (fieldType) {
              case "metric": {
                const { code } = findWith({ id: metricId }, {})(metrics)

                // code can be missing because there are 2 req done: field fetching
                // and based on what metric fields are present the actual metric data is loaded
                return is(code) ? (
                  <FieldMetricUI
                    key={id}
                    className={css["field-metric"]}
                    id={id}
                    title={code.title}
                    type={code.type}
                    dataSets={filterWith(
                      {
                        id: source => contains(source, code.events),
                      },
                      []
                    )(events)}
                    usersFocusing={usersFocusing}
                    isFocus={id === focusId}
                    onClick={onHighlight}
                  />
                ) : null
              }

              case "quote": {
                const {
                  aggregate: { score } = {},
                  contact,
                  source,
                  body,
                  createdAt,
                } = findWith({ id: quoteId }, {})(quotes)

                return (
                  <FieldQuoteUI
                    key={id}
                    className={css["field-quote"]}
                    id={id}
                    score={score}
                    contact={contact}
                    source={source}
                    body={body}
                    createdAt={createdAt}
                    usersFocusing={usersFocusing}
                    isFocus={id === focusId}
                    onClick={onHighlight}
                  />
                )
              }

              case "image": {
                return (
                  <FieldImageUI
                    ref={refs[id]}
                    key={id}
                    className={css["field-image"]}
                    id={id}
                    thumbnailURL={thumbnailURL}
                    label={label}
                    usersFocusing={usersFocusing}
                    isFocus={id === focusId}
                    onFileBrowse={onEditMode}
                    onFileChoose={onImageFieldChoose}
                    onSelect={onImageFieldClick}
                    onFullScreen={onImageFieldImgClick}
                  />
                )
              }

              default: {
                return (
                  <FieldMarkdownUI
                    key={id}
                    className={cx(css["field-markdown"], {
                      [css["field--has-gutter"]]: id === editId,
                    })}
                    id={id}
                    value={value}
                    placeholder="Empty markdown field"
                    minLines={ifElse(
                      isEmpty,
                      always(1),
                      pipe(split("\n"), length)
                    )(value)}
                    usersFocusing={usersFocusing}
                    isEdit={id === editId}
                    isFocus={id === focusId}
                    onViewModeClick={onHighlight}
                    onViewModeDoubleClick={onEditMode}
                    onEditModeCancel={onEditModeCancel}
                    onEditModeSubmit={onTextFieldUpdate}
                  />
                )
              }
            }
          }
        )(fields)}
      </div>
    )
  }
)

FieldsView.propTypes = {
  cardId: PropTypes.string.isRequired,
  componentId: PropTypes.string,
  editId: PropTypes.string,
  focusId: PropTypes.string,
  overlayId: PropTypes.string,
  type: PropTypes.oneOf(["feature", "work"]),
  title: PropTypes.string,
  assignedTo: PropTypes.string,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      avatarURL: PropTypes.string,
      bgColor: PropTypes.string,
      fgColor: PropTypes.string,
      isOnline: PropTypes.bool,
      location: PropTypes.shape({
        status: PropTypes.oneOf(["read", "update"]),
      }),
    })
  ),
  membersPresent: PropTypes.arrayOf(
    PropTypes.shape({
      fieldId: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
    })
  ),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired,

      // text & md
      value: PropTypes.string,

      // image
      label: PropTypes.string,
      originalURL: PropTypes.string,
      thumbnailURL: PropTypes.string,
    })
  ),
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      code: PropTypes.shape({
        title: PropTypes.string.isRequired,
        type: PropTypes.oneOf(["area", "bar"]).isRequired,
        events: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
      position: PropTypes.number,
      isKPI: PropTypes.bool,
    })
  ),
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.instanceOf(Date),
          y: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      contact: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      aggregate: PropTypes.shape({
        score: PropTypes.number.isRequired,
      }),
    })
  ),
  onAssigneeChange: PropTypes.func.isRequired,
  onHighlight: PropTypes.func.isRequired,
  onEditMode: PropTypes.func.isRequired,
  onEditModeCancel: PropTypes.func.isRequired,
  onTextFieldUpdate: PropTypes.func.isRequired,
  onImageFieldChoose: PropTypes.func.isRequired,
  onImageFieldClick: PropTypes.func.isRequired,
  onImageFieldImgClick: PropTypes.func.isRequired,
}

FieldsView.defaultProps = {
  componentId: "",
  editId: null,
  focusId: null,
  overlayId: null,
  type: null,
  title: null,
  assignedTo: null,
  members: [],
  membersPresent: [],
  fields: null,
  metrics: [],
  events: [],
  quotes: [],
}

const memo = deepReactMemo(FieldsView)

export { memo as FieldsView }
