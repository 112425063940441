const debug = require("debug")("mutant:LibRoutes")

import { pathToRegexp, compile } from "path-to-regexp"
import { stringify, parse } from "qs"
import { reduce, pipe, when, read, split, last, is, isEmpty } from "@asd14/m"

/**
 * Custom Route Error
 *
 * @param {String} name Route name
 */
function RouteNotFoundError(name) {
  this.message = `"${name}" not found`
  this.name = "RouteNotFoundError"
}

RouteNotFoundError.prototype = new Error()

const ROUTES = {
  home: "/",
  features: "/features",
  pricing: "/pricing",
  faq: "/faq",
  test: "/test",
  contact: "/contact",
  "demo:login": "/demo",
  "demo:product": "/demo/:productId",
  "users:login": "/login",
  "users:profile": "/me",
  "products:view": "/products/:productId",
}

/**
 * Pass path through pathToRegexp lib (the same lib that React Router 4
 * uses to build the routes internally)
 *
 * @type {Object<string, Object>}
 */
const compiledRoutes = reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: compile(value),
  }),
  {}
)(Object.entries(ROUTES))

/**
 * Get route path by name
 *
 * @param  {string}  name  Route name
 *
 * @throws {RouteNotFoundError}  If route name not defined
 *
 * @return {string}
 */
export const getPath = name => {
  if (isEmpty(ROUTES[name])) {
    throw new RouteNotFoundError(name)
  }

  return ROUTES[name]
}

/**
 * Get the url parameters
 *
 * @param  {string}   url        The url
 * @param  {string}   routeName  The route name
 * @param  {Object}   arg3       Props
 * @param  {boolean}  arg3.end   The end
 *
 * @return {Object}   Key/Value parameter object
 */
export const getParams = (url, routeName, { end = false } = {}) => {
  const keys = []
  const regExp = pathToRegexp(ROUTES[routeName], keys, { end })
  const match = regExp.exec(url)

  return match && match.length >= 2
    ? reduce(
        (acc, key, index) => ({
          ...acc,
          [key.name]: match[index + 1],
        }),
        {}
      )(keys)
    : {}
}

/**
 * Build the URL based on route name, route params and query param
 *
 * @param  {String}  name    Route name
 * @param  {Object}  params  Route parameters
 * @param  {Object}  query   Query parameters
 *
 * @throws {RouteNotFoundError}  If route name not defined
 *
 * @return {String}
 */
export const buildURL = (name, { params, query, anchor }) => {
  if (isEmpty(ROUTES[name])) {
    throw new RouteNotFoundError(name)
  }

  return pipe(
    read(name),
    route => route(params),
    when(
      () => is(query),
      path => `${path}?${stringify(query)}`
    ),
    when(
      () => is(anchor),
      path => `${path}#${anchor}`
    )
  )(compiledRoutes)
}

/**
 * Get parsed query parameters from url search string
 *
 * @param  {string}  search  The search string
 *
 * @return {Object}
 *
 * Example:
 * parseQuery( "a=2" )   // { a: "2" }
 * parseQuery( "a.b=2" ) // { a: { b: "2" } }
 * parseQuery( "a[]=2" ) // { a: [ "2" ] }
 */
export const parseQuery = search =>
  parse(search.replace("?", ""), { allowDots: true })

export const buildQueryString = queryObject =>
  stringify(queryObject, { allowDots: true })

// Parse ajv error structure into key/value object
export const errorMessagesByField = pipe(
  read(["body", "details", "fieldErrors"], []),
  reduce((acc, item) => {
    const field = pipe(read("dataPath"), split("."), last)(item)

    return {
      ...acc,
      [field]: item.message,
    }
  }, {})
)
