const debug = require("debug")("mutant:UIDataStatus")

import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { repeat, map } from "@mutant-ws/m"

import css from "./data-status.css"

const UIDataStatus = ({
  className,
  createCount,
  loadCount,
  updateCount,
  removeCount,
}) => (
  <div
    className={cx(className, css["data-status"], {
      [css["data-status--is-visible"]]:
        (createCount | loadCount | updateCount | removeCount) !== 0,
    })}>
    Interacting with server ...
    <div className={css.calls}>
      {map(({ type, count }) =>
        repeat(index => (
          <div
            key={`call-${type}-${index}`}
            className={cx(css.call, css[`call--${type}`])}
          />
        ))(count)
      )([
        {
          type: "create",
          count: createCount,
        },
        {
          type: "load",
          count: loadCount,
        },
        {
          type: "update",
          count: updateCount,
        },
        {
          type: "remove",
          count: removeCount,
        },
      ])}
    </div>
  </div>
)

UIDataStatus.propTypes = {
  className: PropTypes.string,
  createCount: PropTypes.number,
  loadCount: PropTypes.number,
  updateCount: PropTypes.number,
  removeCount: PropTypes.number,
}

UIDataStatus.defaultProps = {
  className: "",
  createCount: 0,
  loadCount: 0,
  updateCount: 0,
  removeCount: 0,
}

const memo = React.memo(UIDataStatus)

export { memo as UIDataStatus }
