const debug = require("debug")("mutant:HeroUI")

import React from "react"

import css from "./hero.css"

const HeroUI = () => {
  return (
    <div className={css.hero}>
      <div className={css.work} />
      <div className={css.product} />
      <div className={css.feedback} />
      <div className={css.analytics} />
    </div>
  )
}

const memo = React.memo(HeroUI)

export { memo as HeroUI }
