const debug = require("debug")("mutant:UIVxAreaView")

import React from "react"
import PropTypes from "prop-types"
import { path, reduce, map, pipe, range, length } from "ramda"
import { DateTime } from "luxon"

import { Group } from "@vx/group"
import { Grid } from "@vx/grid"
import { AreaClosed, Bar, LinePath } from "@vx/shape"
import { LinearGradient } from "@vx/gradient"
import { AxisLeft, AxisBottom } from "@vx/axis"
import { curveMonotoneX } from "@vx/curve"

import { timeFormat } from "d3-time-format"
import { format } from "d3-format"

import { deepReactMemo } from "/core.hooks/use-deep"

const UIVxAreaView = ({
  dataSets,
  color,
  width,
  height,
  xScale,
  yScale,
  leftAxisWidth,
  bottomAxisHeight,
  numTicksRows,
  isSmooth,
  hasAxisLeft,
  hasAxisBottom,
  hasGrid,
  onPointHover,
}) => {
  const numberOfDataPoints = pipe(path([0, "points"]), length)(dataSets)
  const top = 0

  const chartWidth = hasAxisLeft ? width - leftAxisWidth : width
  const chartHeight = hasAxisBottom ? height - bottomAxisHeight : height

  return (
    <React.Fragment>
      {hasAxisLeft ? (
        <AxisLeft
          top={0}
          left={leftAxisWidth}
          scale={yScale}
          numTicks={numTicksRows}
          stroke="rgba(0,0,0,.1)"
          tickStroke="rgba(0,0,0,0.1)"
          tickLabelProps={() => ({
            fontSize: "12px",
            textAnchor: "end",
            dy: "0.25em",
          })}
          tickFormat={format("~s")}
          hideZero={true}
        />
      ) : null}

      <Group top={top} left={hasAxisLeft ? leftAxisWidth : 0}>
        <LinearGradient
          id="gradient-unique-source"
          from="#000000"
          fromOpacity={0.5}
          to="#000000"
          toOpacity={0}
        />

        {hasGrid ? (
          <Grid
            width={chartWidth}
            height={chartHeight}
            xScale={xScale}
            yScale={yScale}
            stroke="rgba(0,0,0,.1)"
            strokeWidth={1}
            numTicksRows={numTicksRows}
            numTicksColumns={0}
            style={{ pointerEvents: "none" }}
          />
        ) : null}

        {map(({ id, points }) => (
          <React.Fragment key={id}>
            <LinearGradient
              id={`gradient-${id}`}
              from={color(id)}
              fromOpacity={0.5}
              to={color(id)}
              toOpacity={0}
            />
            <AreaClosed
              width={chartWidth}
              height={chartHeight}
              data={points}
              x={({ x }) => xScale(x)}
              y={({ y }) => yScale(y)}
              yScale={yScale}
              strokeWidth={1}
              fill={`url(#gradient-${id})`}
              curve={isSmooth ? curveMonotoneX : null}
              style={{ pointerEvents: "none" }}
            />
            <LinePath
              width={chartWidth}
              height={chartHeight}
              data={points}
              x={({ x }) => xScale(x)}
              y={({ y }) => yScale(y)}
              strokeWidth={2}
              stroke={color(id)}
              strokeLinecap="round"
              curve={isSmooth ? curveMonotoneX : null}
              style={{ pointerEvents: "none" }}
            />
          </React.Fragment>
        ))(dataSets)}

        <Group>
          {map(index => {
            const barWidth = chartWidth / numberOfDataPoints
            const onHover = () =>
              onPointHover(path([0, "points", index, "x"])(dataSets))

            return (
              <Bar
                key={`bar-${index}`}
                x={index * barWidth}
                y={0}
                width={barWidth}
                height={chartHeight}
                fill="transparent"
                onTouchStart={onHover}
                onTouchMove={onHover}
                onMouseEnter={onHover}
              />
            )
          })(range(0, numberOfDataPoints))}
        </Group>
      </Group>

      {hasAxisBottom ? (
        <AxisBottom
          top={chartHeight}
          left={hasAxisLeft ? leftAxisWidth : 0}
          scale={xScale}
          stroke="rgba(0,0,0,.1)"
          tickStroke="rgba(0,0,0,0.1)"
          tickValues={reduce(
            // only show Monday ticks
            (acc, item) =>
              DateTime.fromJSDate(item).weekday === 1 ? [...acc, item] : acc,
            []
          )(xScale.ticks(30))}
          tickFormat={timeFormat("%b %d, '%y")}
          tickLabelProps={() => ({
            fontSize: "12px",
            textAnchor: "middle",
            dy: "15",
          })}
        />
      ) : null}
    </React.Fragment>
  )
}

UIVxAreaView.propTypes = {
  dataSets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.instanceOf(Date).isRequired,
          y: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  color: PropTypes.func.isRequired,
  leftAxisWidth: PropTypes.number.isRequired,
  bottomAxisHeight: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  numTicksRows: PropTypes.number.isRequired,
  isSmooth: PropTypes.bool,
  hasAxisBottom: PropTypes.bool,
  hasAxisLeft: PropTypes.bool,
  hasGrid: PropTypes.bool,
  onPointHover: PropTypes.func.isRequired,
}

UIVxAreaView.defaultProps = {
  dataSets: [],
  isSmooth: true,
  hasAxisBottom: true,
  hasAxisLeft: true,
  hasGrid: true,
}

const memo = deepReactMemo(UIVxAreaView)

export { memo as UIVxAreaView }
