const debug = require("debug")("mutant:useFocus")

import { useSelector, useDispatch } from "react-redux"
import { startsWith, is } from "@mutant-ws/m"

import { setLayer as setKeyboardLayer } from "/core.libs/keyboard"
import { useQuery } from "/core.hooks/use-query"
import { useCallback } from "/core.hooks/use-deep"
import { useProduct } from "/core.hooks/use-product/use-product"

export const BASE_LAYER = "base"
export const PRODUCT_LAYER = "base.product"
export const FEEDBACK_LAYER = "base.product.feedback"
export const FEEDBACK_QUOTES_LAYER = "base.product.feedback.quotes"
export const FEEDBACK_INSIGHTS_LAYER = "base.product.feedback.insights"
export const WORK_LAYER = "base.product.work"
export const WORK_BOARD_LAYER = "base.product.work.board"
export const WORK_BOARD_COLUMNS_LAYER = "base.product.work.board.columns"
export const WORK_BOARD_FEATURES_LAYER = "base.product.work.board.features"
export const WORK_CARD_LAYER = "base.product.work.card"
export const WORK_CARD_FIELDS_LAYER = "base.product.work.card.fields"
export const WORK_CARD_SIBLINGS_LAYER = "base.product.work.card.siblings"
export const WORK_CARD_COMMENTS_LAYER = "base.product.work.card.comments"
export const MEASURE_LAYER = "base.product.measure"
export const MEASURE_METRICS_LAYER = "base.product.measure.metrics"

export const STORE_KEY = "GLOBAL.FOCUS"

export const isFeedback = startsWith("base.product.feedback")
export const isWork = startsWith("base.product.work")
export const isMeasure = startsWith("base.product.measure")

export const reducer = (
  state = { id: null, layer: BASE_LAYER, status: "read" },
  { type, payload = {} }
) => {
  switch (type) {
    case `${STORE_KEY}.SET`:
      return {
        id: is(payload.id) ? payload.id : state.id,
        layer: is(payload.layer) ? payload.layer : state.layer,
        status: is(payload.status) ? payload.status : state.status,
      }

    default:
      return state
  }
}

/**
 * Pinpoint user's location on the board
 *
 * @param {string} id     Resource ID (Card, Metric, Feedback etc)
 * @param {string} layer  Keyboard layer/app region that has keyboard control
 * @param {string} status Action being performed ("view" or "edit")
 *
 * @returns {[Object, Function]} Getter and setter
 */
export const useFocus = () => {
  const dispatch = useDispatch()
  const [queryParams] = useQuery()
  const { id: productId, updateMemberLocation } = useProduct()
  const { id, layer, status } = useSelector(state => state[STORE_KEY])

  return [
    { id, layer, status },

    // TODO: Split into multiple methods: setLocal, sync
    useCallback(
      ({ id: nextFocusId, layer: nextFocusLayer, status: nextFocusStatus }) => {
        // setLocal
        if (is(nextFocusLayer) && layer !== nextFocusLayer) {
          setKeyboardLayer(nextFocusLayer)
        }

        if (
          is(nextFocusLayer) &&
          !is(nextFocusId) &&
          nextFocusLayer !== layer
        ) {
          return dispatch({
            type: `${STORE_KEY}.SET`,
            payload: {
              layer: nextFocusLayer,
            },
          })
        }

        const isIdChanged = is(nextFocusId) && id !== nextFocusId
        const isLayerChanged = is(nextFocusLayer) && layer !== nextFocusLayer
        const isStatusChanged =
          is(nextFocusStatus) && status !== nextFocusStatus

        // sync
        if (isIdChanged || isLayerChanged || isStatusChanged) {
          dispatch({
            type: `${STORE_KEY}.SET`,
            payload: {
              id: nextFocusId,
              layer: nextFocusLayer,
              status: nextFocusStatus,
            },
          })

          if (is(productId)) {
            updateMemberLocation({
              id: nextFocusId,
              layer: nextFocusLayer,
              status: nextFocusStatus,
              queryParams,
            })
          }
        }
      },
      [
        id,
        productId,
        layer,
        status,
        dispatch,
        queryParams,
        updateMemberLocation,
      ]
    ),
  ]
}
