const debug = require("debug")("mutant:UIVxArea")

import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import {
  pipe,
  map,
  get,
  count,
  min,
  max,
  maxBy,
  findWith,
  isEmpty,
  is,
} from "@asd14/m"

import { Line } from "@vx/shape"
import { timeFormat } from "d3-time-format"
import { scaleTime, scaleLinear } from "@vx/scale"

import { deepReactMemo, useMemo } from "../../core.hooks/use-deep"

import { getDomain } from "./area.lib"
import { UIVxTooltip } from "../vx-tooltip/vx-tooltip"
import { UIVxAreaView } from "./vx-area.view"

import css from "./vx-area.css"

const combine = fn => source => {
  const result = []
  const maxLength = pipe(map(count), max)(source)

  for (let i = 0; i < maxLength; ++i) {
    result.push(fn(...map(get(i))(source)))
  }

  return result
}

const getYFromX = (x, points) =>
  pipe(
    findWith({
      x: source => x.getTime() === source.getTime(),
    }),
    get("y")
  )(points)

const UIVxArea = ({
  className,
  highlightX,
  dataSets,
  color,
  width,
  height,
  numTicksRows,
  isSmooth,
  hasAxisBottom,
  hasAxisLeft,
  hasTooltip,
  hasGrid,
  onPointHover,
}) => {
  // const marginTop = 10
  const leftAxisWidth = hasAxisLeft ? 30 : 0
  const bottomAxisHeight = hasAxisBottom ? 40 : 0

  const maxWidth = width - leftAxisWidth
  const maxHeight = height - bottomAxisHeight

  const { tooltipY, xScale, yScale } = useMemo(() => {
    /*
     * Combine all data sets by selecting max .y val
     *
     * Multiple data sets:
     * [
     *  [{x: 1, y:2}, ...],
     *  [{x: 1, y:10}, ...],
     * ]
     *
     * becomes:
     * [{x: 1, y: 10}, ...]
     */
    const mergedDataSet = pipe(
      map(get("points")),
      combine((...items) => maxBy(get("y"), items))
    )(dataSets)
    const { minX, maxX, maxY } = getDomain(mergedDataSet)

    return {
      tooltipY: maxY,
      xScale: scaleTime({
        range: [0, maxWidth],
        domain: [minX, maxX],
      }),
      yScale: scaleLinear({
        range: [maxHeight, 0],
        domain: [0, maxY],
        nice: true,
      }),
    }
  }, [maxWidth, maxHeight, dataSets])

  const tooltipWidth = 150
  const tooltipLeft = is(highlightX) ? leftAxisWidth + xScale(highlightX) : 0
  const tooltipSize = min([width, height]) < 100 ? "small" : "normal"

  return (
    <div
      className={cx(css["svg-wrapper"], {
        [className]: is(className),
      })}
      onMouseLeave={() => {}}>
      <svg width={width} height={height}>
        <UIVxAreaView
          dataSets={dataSets}
          color={color}
          width={width}
          height={height}
          leftAxisWidth={leftAxisWidth}
          bottomAxisHeight={bottomAxisHeight}
          xScale={xScale}
          yScale={yScale}
          numTicksRows={numTicksRows}
          isSmooth={isSmooth}
          hasAxisBottom={hasAxisBottom}
          hasAxisLeft={hasAxisLeft}
          hasGrid={hasGrid}
          onPointHover={onPointHover}
        />

        {isEmpty(highlightX) ? null : (
          <React.Fragment>
            <Line
              from={{ x: leftAxisWidth + xScale(highlightX), y: 0 }}
              to={{ x: leftAxisWidth + xScale(highlightX), y: height }}
              stroke="#212529"
              strokeWidth={1}
              style={{ pointerEvents: "none" }}
              strokeDasharray="2,2"
            />
            {map(({ id, points }) => (
              <circle
                key={id}
                cx={leftAxisWidth + xScale(highlightX)}
                cy={yScale(getYFromX(highlightX, points))}
                r={tooltipSize === "small" ? 4 : 6}
                fill={color(id)}
                stroke="#ffffff"
                strokeWidth={tooltipSize === "small" ? 2 : 3}
                style={{ pointerEvents: "none" }}
              />
            ))(dataSets)}
          </React.Fragment>
        )}
      </svg>

      {hasTooltip && !isEmpty(highlightX) ? (
        <UIVxTooltip
          style={{
            maxWidth: `${tooltipWidth}px`,
            top: `${yScale(tooltipY)}px`,
            left:
              tooltipLeft + tooltipWidth + 20 > width
                ? "auto"
                : `${tooltipLeft + 20}px`,
            right:
              tooltipLeft + tooltipWidth + 20 > width
                ? `${width - tooltipLeft + 20}px`
                : "auto",
          }}
          title={timeFormat("%b %d, '%y")(highlightX)}
          values={map(({ id, points }) => ({
            id,
            color: color(id),
            text: getYFromX(highlightX, points),
          }))(dataSets)}
        />
      ) : null}
    </div>
  )
}

UIVxArea.propTypes = {
  className: PropTypes.string,
  highlightX: PropTypes.instanceOf(Date),
  dataSets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.instanceOf(Date).isRequired,
          y: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  color: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  numTicksRows: PropTypes.number,
  isSmooth: PropTypes.bool,
  hasAxisBottom: PropTypes.bool,
  hasAxisLeft: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  hasGrid: PropTypes.bool,
  onPointHover: PropTypes.func.isRequired,
}

UIVxArea.defaultProps = {
  className: null,
  highlightX: null,
  dataSets: [],
  width: 300,
  height: 100,
  numTicksRows: 3,
  isSmooth: false,
  hasAxisBottom: true,
  hasAxisLeft: true,
  hasTooltip: true,
  hasGrid: true,
}

const memo = deepReactMemo(UIVxArea)

export { memo as UIVxArea }
