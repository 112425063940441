const debug = require("debug")("mutant:FeaturesView")

import React from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import { pipe, map, findWith, filterWith, has, read } from "@asd14/m"

import { deepReactMemo } from "/core.hooks/use-deep"

import { FeatureCardUI } from "./ui/card"

import css from "./features.css"

const FeaturesView = ({
  focusId,
  hightlightId,
  featureQueryId,
  editId,
  cards,
  metrics,
  events,
  members,
  membersPresent,
  onClick,
  onCardClick,
  onCardDoubleClick,
  onCardTitleEditUpdate,
  onCardTitleEditCancel,
}) => {
  return (
    <div className={css.features} onClick={onClick}>
      <SimpleBar autoHide={true} style={{ widht: "100%" }}>
        <div className={css.cards}>
          {map(
            ({
              id,
              title,
              coverURL,
              coverMetricId,
              assignedTo,
              aggregate: { backlogCount, doingCount, testingCount, doneCount },
              componentId,
            }) => {
              const {
                userId: assignedToId,
                name: assignedToName,
                avatarURL: assignedToAvatarURL,
                bgColor: assignedToBgColor,
                fgColor: assignedToFgColor,
              } = findWith({ userId: assignedTo }, {}, members)
              const cardMetric = findWith({ id: coverMetricId }, {}, metrics)
              const cardEvents = read(["code", "events"], [])(cardMetric)
              const cardEventData = filterWith(
                {
                  id: source => has(source, cardEvents),
                },
                events
              )

              return (
                <FeatureCardUI
                  key={id}
                  className={css.card}
                  id={id}
                  title={title}
                  coverURL={coverURL}
                  coverMetricId={coverMetricId}
                  userId={assignedToId}
                  userName={assignedToName}
                  userAvatarURL={assignedToAvatarURL}
                  userBgColor={assignedToBgColor}
                  userFgColor={assignedToFgColor}
                  componentId={componentId}
                  countBacklog={backlogCount}
                  countDoing={doingCount}
                  countTesting={testingCount}
                  countDone={doneCount}
                  metric={cardMetric}
                  events={cardEventData}
                  usersFocusing={pipe(
                    filterWith({ cardId: id }),
                    map(({ userId }) => findWith({ userId })(members))
                  )(membersPresent)}
                  isSelect={id === featureQueryId}
                  isHighlight={id === hightlightId}
                  isFocus={id === focusId}
                  isEdit={id === editId}
                  onClick={onCardClick}
                  onDoubleClick={onCardDoubleClick}
                  onTitleEditUpdate={onCardTitleEditUpdate}
                  onTitleEditCancel={onCardTitleEditCancel}
                />
              )
            }
          )(cards)}
        </div>
      </SimpleBar>
    </div>
  )
}

FeaturesView.propTypes = {
  focusId: PropTypes.string,
  hightlightId: PropTypes.string,
  featureQueryId: PropTypes.string,
  editId: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      position: PropTypes.number,
      assignedTo: PropTypes.string,
      coverURL: PropTypes.string,
      coverMetricId: PropTypes.string,
      aggregate: PropTypes.shape({
        backlogCount: PropTypes.number,
        doingCount: PropTypes.number,
        testingCount: PropTypes.number,
        doneCount: PropTypes.number,
      }),
    })
  ),
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      code: PropTypes.shape({
        title: PropTypes.string.isRequired,
        type: PropTypes.oneOf(["area", "bar"]).isRequired,
        events: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
      position: PropTypes.number,
      isKPI: PropTypes.bool,
    })
  ),
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.instanceOf(Date),
          y: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  members: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
      bgColor: PropTypes.string,
      fgColor: PropTypes.string,
      isEdit: PropTypes.bool,
      isOnline: PropTypes.bool,
    })
  ),
  membersPresent: PropTypes.arrayOf(
    PropTypes.shape({
      cardId: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
    })
  ),
  onClick: PropTypes.func.isRequired,
  onCardClick: PropTypes.func.isRequired,
  onCardDoubleClick: PropTypes.func.isRequired,
  onCardTitleEditUpdate: PropTypes.func.isRequired,
  onCardTitleEditCancel: PropTypes.func.isRequired,
}

FeaturesView.defaultProps = {
  focusId: null,
  hightlightId: null,
  featureQueryId: null,
  editId: null,
  cards: [],
  metrics: [],
  events: [],
  members: [],
  membersPresent: [],
}

const memo = deepReactMemo(FeaturesView)

export { memo as FeaturesView }
