const debug = require("debug")("mutant:UIFocusers")

import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { map, get, is } from "@mutant-ws/m"

import { deepReactMemo } from "/core.hooks/use-deep"
import { UITag } from "/core.ui/tag/tag"

import css from "./focusers.css"

const UIFocusers = ({ className, users, isMeEdit }) => {
  return (
    <div
      className={cx(css.focusers, {
        [className]: is(className),
        [css["focusers--is-me-editing"]]: isMeEdit,
      })}>
      {map((user = {}) => (
        <UITag
          className={cx(css.focuser, {
            [css["focuser--is-editing"]]:
              get(["location", "status"])(user) === "update",
          })}
          key={user.id}
          text={user.name}
          size="small"
          color="blue"
          hasShadow={true}
        />
      ))(users)}
    </div>
  )
}

UIFocusers.propTypes = {
  className: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      isOnline: PropTypes.bool,
      location: PropTypes.shape({
        status: PropTypes.oneOf(["read", "update"]),
      }),
    })
  ),
  isMeEdit: PropTypes.bool,
}

UIFocusers.defaultProps = {
  className: null,
  users: [],
  isMeEdit: false,
}

const memo = deepReactMemo(UIFocusers)

export { memo as UIFocusers }
