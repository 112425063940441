const debug = require("debug")("mutant:InsightsCardUI")

import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { isEmpty } from "@mutant-ws/m"

import css from "./card.css"

const InsightsCardUI = ({
  id,
  body,
  quotesCount,
  isInbox,
  isFocus,
  isSelect,
  onClick,
}) => {
  return (
    <div
      className={cx(css["card-wrapper"], {
        [css["card-wrapper--is-select"]]: isSelect,
        [css["card-wrapper--is-focus"]]: isFocus,
      })}>
      <div
        className={cx(css.card, {
          [css["card--is-inbox"]]: isInbox,
        })}
        onClick={() => onClick(id)}>
        <div>
          <div className={css.card__score}>{isInbox ? "★" : quotesCount}</div>
        </div>
        <div>
          <div className={css["card-body"]}>
            {isEmpty(body) ? <i>Unknown insight</i> : body}
          </div>

          <div className={css["card__quotes-count"]}>
            {isInbox
              ? `${quotesCount} unsorted quotes`
              : `${quotesCount} quotes`}
          </div>
        </div>
      </div>
    </div>
  )
}

InsightsCardUI.propTypes = {
  id: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  quotesCount: PropTypes.number.isRequired,
  isInbox: PropTypes.bool.isRequired,
  isFocus: PropTypes.bool.isRequired,
  isSelect: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

InsightsCardUI.defaultProps = {}

const memo = React.memo(InsightsCardUI)

export { memo as InsightsCardUI }
