const debug = require("debug")("mutant:WorkspaceSwitcherUI")

import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { map, startsWith } from "ramda"

import { deepReactMemo } from "/core.hooks/use-deep"

import css from "./workspace-switcher.css"

const WorkspaceSwitcherUI = ({ selected, items, onClick }) => {
  return (
    <ul className={css.switcher}>
      {map(
        ({ id, label, shortcut }) => (
          <li
            key={id}
            className={cx(css.item, {
              [css["item--is-focus"]]: startsWith(id, selected),
            })}
            onClick={() => onClick(id)}>
            <span className={css.shortcut}>{shortcut}</span>
            {label}
          </li>
        ),
        items
      )}
    </ul>
  )
}

WorkspaceSwitcherUI.propTypes = {
  selected: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      shortcut: PropTypes.string.isRequired,
    })
  ),
  onClick: PropTypes.func,
}

WorkspaceSwitcherUI.defaultProps = {
  selected: null,
  items: [],
  onClick: null,
}

const memo = deepReactMemo(WorkspaceSwitcherUI)

export { memo as WorkspaceSwitcherUI }
