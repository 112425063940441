const debug = require("debug")("mutant:DemoPage")

import React, { useEffect } from "react"
import { is } from "@asd14/m"
import { useHistory, useParams } from "react-router-dom"

import { useAuth } from "/core.hooks/use-auth/use-auth"
import { buildURL } from "/core.libs/routes"

import { BaseLayout } from "/layout.base/base"
import { ProductPage } from "/page.product/product"

const DemoPage = () => {
  const {
    profile,
    isAuthenticated,
    canAuthenticate,
    persistUser,
    switchProfile,
  } = useAuth()
  const { productId } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (profile !== "demo") {
      switchProfile("demo")
    }

    if (profile === "demo" && (isAuthenticated || canAuthenticate)) {
      const demoProductURL = buildURL("demo:product", {
        params: {
          productId: process.env.DEMO_ID,
        },
      })

      history.push(demoProductURL)
    }

    return () => {
      if (profile === "demo") {
        switchProfile("user")
      }
    }
  }, [
    profile,
    history,
    canAuthenticate,
    isAuthenticated,
    switchProfile,
    persistUser,
  ])

  return (
    <BaseLayout>
      {profile === "demo" && isAuthenticated && is(productId) ? (
        <ProductPage />
      ) : (
        <center>Authenticating ...</center>
      )}
    </BaseLayout>
  )
}

export { DemoPage }
