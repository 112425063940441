const debug = require("debug")("mutant:BoardContainer")

import React, { useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"

import { useQuery } from "/core.hooks/use-query"
import { useList } from "/core.hooks/use-list"
import {
  useFocus,
  WORK_BOARD_LAYER,
  WORK_BOARD_FEATURES_LAYER,
  WORK_CARD_LAYER,
} from "/core.hooks/use-focus"

import { ColumnsContainer } from "./_.columns/columns.container"
import { FeaturesContainer } from "./_.features/features.container"

import { FeaturesList } from "../product.work.board/_.features/data/list.features"
import { CardsList } from "../product.work.board/_.columns/data/list.cards"

import { useBoardCLI } from "./board.cli"

const BoardContainer = ({ productId, members }) => {
  const cardsList = useList(CardsList)
  const featuresList = useList(FeaturesList)

  const [{ "feature-id": queryFeatureId }, setQuery] = useQuery()
  const [featureHighlightId, setFeatureHightlightId] = useState()

  //
  // Board keyboard shortcuts
  //

  const [{ layer }, setFocus] = useFocus()
  const hasKeyboard = layer === WORK_BOARD_LAYER

  useEffect(() => {
    if (hasKeyboard) {
      setFocus({ layer: WORK_BOARD_FEATURES_LAYER })
    }
  }, [hasKeyboard, setFocus])

  //
  // Board specific CLI commands
  //

  useBoardCLI(WORK_BOARD_LAYER, {
    productId,
    featureId: queryFeatureId,
    cardsList,
    featuresList,
  })

  const handleFeatureOpen = useCallback(
    id => {
      // if already selected, open the card
      setFocus({ layer: WORK_CARD_LAYER })
      setQuery({ ["work-id"]: id })
    },
    [setQuery, setFocus]
  )

  return (
    <React.Fragment>
      <FeaturesContainer
        productId={productId}
        hightlightId={featureHighlightId}
        members={members}
        onCardFocus={setFeatureHightlightId}
        onCardToggle={useCallback(
          id => {
            setQuery({ ["feature-id"]: queryFeatureId === id ? undefined : id })
          },
          [queryFeatureId, setQuery]
        )}
        onCardOpen={handleFeatureOpen}
      />
      <ColumnsContainer
        productId={productId}
        // hightlight cards belonging to feature
        featureHighlightId={featureHighlightId}
        members={members}
        onCardFocus={useCallback(
          ({ featureId }) => setFeatureHightlightId(featureId),
          []
        )}
        onCardOpen={handleFeatureOpen}
      />
    </React.Fragment>
  )
}

BoardContainer.propTypes = {
  productId: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
    })
  ),
}

BoardContainer.defaultProps = {
  members: [],
}

export { BoardContainer }
