const debug = require("debug")("mutant:YankReducers")

export const STORE_KEY = "GLOBAL.YANK"

export const reducer = (
  state = {
    id: null,
    model: null,
  },
  { type, payload: { id, model, onPaste } = {} }
) => {
  switch (type) {
    case `${STORE_KEY}.YANK`:
      return {
        id,
        model,
        onPaste,
      }

    case `${STORE_KEY}.CLEAR`:
      return {
        id: null,
        model: null,
        onPaste: null,
      }

    default:
      return state
  }
}

export const selector = store => store[STORE_KEY]
