const debug = require("debug")("mutant:UITextarea")

import React, { useEffect, useRef } from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { is } from "@mutant-ws/m"

import css from "./textarea.css"

const resizeHeight = source => {
  source.style.height = "auto"
  source.style.height = `${source.scrollHeight}px`
}

const UITextarea = ({
  className,
  id,
  value,
  placeholder,
  shouldFocus,
  shouldSelectAll,
  shouldAutoResize,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
}) => {
  const ref = useRef()

  useEffect(() => {
    if (shouldFocus && is(ref.current)) {
      ref.current.focus()
    }

    if (shouldSelectAll && is(ref.current)) {
      ref.current.select()
    }
  }, [ref, shouldFocus, shouldSelectAll])

  useEffect(() => {
    if (shouldAutoResize && is(ref.current)) {
      resizeHeight(ref.current)
    }
  }, [value, ref, shouldAutoResize])

  return (
    <div
      className={cx({
        [className]: is(className),
      })}>
      <textarea
        ref={ref}
        className={css.textarea}
        name={id}
        value={value}
        placeholder={placeholder}
        rows={1}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  )
}

UITextarea.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  shouldFocus: PropTypes.bool,
  shouldSelectAll: PropTypes.bool,
  shouldAutoResize: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

UITextarea.defaultProps = {
  className: null,
  value: "",
  placeholder: "Insert text value here",
  shouldFocus: false,
  shouldSelectAll: false,
  shouldAutoResize: true,
  onChange: null,
  onKeyDown: null,
  onFocus: null,
  onBlur: null,
}

const memo = React.memo(UITextarea)

export { memo as UITextarea }
