const debug = require("debug")("mutant:QuotesView")

import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { map, keys } from "ramda"

import { deepReactMemo } from "../core.hooks/use-deep"

import { QuoteCardUI } from "./ui/card"
import { InsightTitleUI } from "./ui/title"
import css from "./quotes.css"

const QuotesView = ({
  focusId,
  editId,
  insightBody,
  quotesCount,
  isInbox,
  items,
  hasKeyboard,
  onVote,
  onQuoteUpdate,
  onQuoteEdit,
  onQuoteEditCancel,
  onQuoteClick,
}) => {
  return (
    <div
      className={cx(css.insight, {
        [css["insight--is-inbox"]]: isInbox,
      })}>
      <InsightTitleUI
        body={insightBody}
        score={quotesCount}
        quotesCount={quotesCount}
        isInbox={isInbox}
        isFocus={focusId === "title"}
        isEdit={editId === "title"}
        hasKeyboard={hasKeyboard}
        onClick={onQuoteClick}
        onDoubleClick={onQuoteEdit}
        onEditModeCancel={onQuoteEditCancel}
        onEditModeSubmit={onQuoteUpdate}
      />

      <div className={css.items}>
        {map(
          ({
            id,
            body,
            contact,
            source,
            vote,
            aggregate: { score },
            createdAt,
          }) => (
            <QuoteCardUI
              key={id}
              id={id}
              body={body}
              contact={contact}
              source={source}
              createdAt={createdAt}
              score={score}
              isFocus={id === focusId}
              isEdit={id === editId}
              isVotedDown={vote < 0}
              isVotedUp={vote > 0}
              hasKeyboard={hasKeyboard}
              onVote={onVote}
              onViewModeClick={onQuoteClick}
              onViewModeDoubleClick={onQuoteEdit}
              onEditModeCancel={onQuoteEditCancel}
              onEditModeSubmit={onQuoteUpdate}
            />
          )
        )(items)}
      </div>
    </div>
  )
}

QuotesView.propTypes = {
  focusId: PropTypes.string,
  editId: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      contact: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      vote: PropTypes.number,
      aggregate: PropTypes.shape({
        score: PropTypes.number.isRequired,
      }),
    })
  ),
  insightBody: PropTypes.string.isRequired,
  quotesCount: PropTypes.number.isRequired,
  isInbox: PropTypes.bool.isRequired,
  hasKeyboard: PropTypes.bool.isRequired,
  onVote: PropTypes.func.isRequired,
  onQuoteUpdate: PropTypes.func.isRequired,
  onQuoteEdit: PropTypes.func.isRequired,
  onQuoteEditCancel: PropTypes.func.isRequired,
  onQuoteClick: PropTypes.func.isRequired,
}

QuotesView.defaultProps = {
  focusId: null,
  editId: null,
  items: [],
}

const memo = deepReactMemo(QuotesView, keys(QuotesView.propTypes))

export { memo as QuotesView }
