const debug = require("debug")("mutant:QuoteCardUI")

import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import AceEditor from "react-ace"
import cx from "classnames"
import marked from "marked"
import gm from "gray-matter"
import isDeepEqual from "fast-deep-equal"
import { is, isEmpty } from "@mutant-ws/m"
import { keys, trim } from "ramda"
import { DateTime } from "luxon"

import { deepReactMemo } from "../../core.hooks/use-deep"

import css from "./card.css"

const QuoteCardUI = ({
  id,
  body,
  contact,
  source,
  score,
  createdAt,
  isEdit,
  isFocus,
  isVotedUp,
  isVotedDown,
  hasKeyboard,
  onVote,
  onViewModeClick,
  onViewModeDoubleClick,
  onEditModeCancel,
  onEditModeSubmit,
}) => {
  const [localBody, setLocalBody] = useState()
  const quoteRef = useRef()

  // sync state body if props change
  useEffect(() => {
    setLocalBody(gm.stringify(body, { contact, source }))
  }, [body, contact, source])

  // scroll if selected
  useEffect(() => {
    if (is(quoteRef.current) && isFocus) {
      quoteRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      })
    }
  }, [isFocus])

  const handleSubmitIfDiff = value => {
    const { content, data } = gm(value)
    const dataWithDefaults = {
      source: "",
      contact: "",
      ...data,
    }

    if (
      isDeepEqual(
        {
          content,
          data: dataWithDefaults,
        },
        {
          content: body,
          data: {
            source,
            contact,
          },
        }
      )
    ) {
      onEditModeCancel()
    } else {
      onEditModeSubmit(id, {
        body: content,
        ...dataWithDefaults,
      })
    }
  }

  return (
    <div
      ref={quoteRef}
      className={cx(css["card-wrapper"], {
        [css["card--is-edit"]]: isEdit,
      })}>
      <div
        className={cx(css.card, {
          [css["card--is-focus"]]: isFocus && hasKeyboard,
          [css["card--is-select"]]: isFocus && !hasKeyboard,
          [css["card--is-view"]]: !isEdit,
        })}
        onClick={() => !isEdit && onViewModeClick(id)}
        onDoubleClick={() => !isEdit && onViewModeDoubleClick(id)}>
        <div className={css.vote}>
          <div
            className={cx(css["vote-up"], {
              [css["vote--is-active"]]: isVotedUp,
            })}
            onClick={() => onVote(id, { value: 1 })}
            onDoubleClick={event => event.stopPropagation()}>
            ↑
          </div>
          {score}
          <div
            className={cx(css["vote-down"], {
              [css["vote--is-active"]]: isVotedDown,
            })}
            onClick={() => onVote(id, { value: -1 })}
            onDoubleClick={event => event.stopPropagation()}>
            ↓
          </div>
        </div>
        {isEdit ? (
          <div>
            <AceEditor
              mode="markdown"
              theme="clouds"
              value={localBody}
              width="100%"
              maxLines={15}
              minLines={5}
              wrapEnabled={true}
              focus={true}
              readOnly={false}
              showGutter={false}
              showPrintMargin={false}
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                showFoldWidgets: false,
                useWorker: false,
              }}
              commands={[
                {
                  name: "Revert and Exit",
                  bindKey: { win: "Escape", mac: "Escape" },
                  exec: () => {
                    setLocalBody(gm.stringify(body, { contact, source }))
                    onEditModeCancel()
                  },
                },
                {
                  name: "Save and Exit",
                  bindKey: { win: "Shift+Enter", mac: "Shift+Enter" },
                  exec: editor => {
                    handleSubmitIfDiff(editor.getValue())
                  },
                },
              ]}
              onBlur={(event, editor) => handleSubmitIfDiff(editor.getValue())}
              onChange={value => setLocalBody(value)}
            />
          </div>
        ) : (
          <React.Fragment>
            <div className={css.contact}>
              {isEmpty(contact) ? <i>Unknown human</i> : contact}
            </div>
            <div className={css["created-at"]}>
              {DateTime.fromISO(createdAt).toRelative()}
            </div>
            <div className={css.body}>
              <div
                dangerouslySetInnerHTML={{
                  __html: marked(
                    isEmpty(trim(body))
                      ? `<i class=${css.placeholder}>and then words came out ...</i>`
                      : body
                  ),
                }}
              />

              <div className={css.source}>
                {isEmpty(source) ? <i>Unknown source</i> : source}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

QuoteCardUI.propTypes = {
  id: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  contact: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  isFocus: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  isVotedUp: PropTypes.bool.isRequired,
  isVotedDown: PropTypes.bool.isRequired,
  hasKeyboard: PropTypes.bool.isRequired,
  onVote: PropTypes.func.isRequired,
  onViewModeClick: PropTypes.func.isRequired,
  onViewModeDoubleClick: PropTypes.func.isRequired,
  onEditModeSubmit: PropTypes.func.isRequired,
  onEditModeCancel: PropTypes.func.isRequired,
}

QuoteCardUI.defaultProps = {}

const memo = deepReactMemo(QuoteCardUI, keys(QuoteCardUI.propTypes))

export { memo as QuoteCardUI }
