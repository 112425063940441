const debug = require("debug")("mutant:useProductKeyboard")

import { useEffect, useState } from "react"
import { is } from "@mutant-ws/m"

import { addShortcuts, removeShortcuts } from "/core.libs/keyboard"
import { useMount } from "/core.hooks/use-mount"
import {
  useFocus,
  BASE_LAYER,
  WORK_LAYER,
  FEEDBACK_LAYER,
  MEASURE_LAYER,
  isFeedback,
  isWork,
  isMeasure,
} from "/core.hooks/use-focus"

export const useProductKeyboard = (sectionLayer, { commandLineRef }) => {
  const [{ layer }, setFocus] = useFocus()
  const [isHelpVisible, setIsHelpVisible] = useState()

  // default workspace
  useMount(() => {
    setFocus({ layer: WORK_LAYER })
  })

  useEffect(() => {
    addShortcuts({
      layer: BASE_LAYER,
      shortcuts: {
        Tab: event => {
          event.preventDefault()
        },
        1: () => {
          if (!isWork(layer)) {
            setFocus({ layer: WORK_LAYER })
          }
        },
        2: () => {
          if (!isMeasure(layer)) {
            setFocus({ layer: MEASURE_LAYER })
          }
        },
        3: () => {
          if (!isFeedback(layer)) {
            setFocus({ layer: FEEDBACK_LAYER })
          }
        },
        ":,/": event => {
          // so the first : char does not end up in the input
          event.preventDefault()

          is(commandLineRef.current) && commandLineRef.current.focus()
        },
        Escape: () => {
          if (isHelpVisible) {
            setIsHelpVisible(false)
          }
        },
        "?": () => {
          setIsHelpVisible(!isHelpVisible)
        },
      },
    })

    return () => removeShortcuts({ layer: BASE_LAYER })
  }, [layer, commandLineRef, isHelpVisible, setFocus, setIsHelpVisible])

  return {
    layer,
    isHelpVisible,
    setFocus,
    setIsHelpVisible,
  }
}
