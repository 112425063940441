const debug = require("debug")("mutant:EarlyAccess")

import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { is } from "@mutant-ws/m"

import { UIInput } from "../../core.ui/input/input"
import { UIButton } from "../../core.ui/button/button"
import { UISelect } from "../../core.ui/select/select"

import css from "./early-access.css"

const EarlyAccessView = ({
  className,
  callToAction,
  step,
  //
  name,
  nameError,
  //
  email,
  emailError,
  //
  job,
  jobError,
  //
  teamSize,
  teamSizeError,
  //
  isLoading,
  isCenter,
  onNameChange,
  onEmailChange,
  onTeamSizeChange,
  onJobChange,
  onSubmit,
  onSubmitFollowUp,
}) => (
  <div
    className={cx(css.form, {
      [className]: is(className),
      [css["form--is-center"]]: isCenter,
    })}>
    {step === 1 && (
      <React.Fragment>
        <UIInput
          className={css.form__input}
          placeholder="Email address"
          type="text"
          size="medium"
          value={email}
          error={emailError}
          isLoading={isLoading}
          isDisabled={isLoading}
          onChange={onEmailChange}
          onSubmit={onSubmit}
        />
        <UIButton
          type="primary"
          label={callToAction}
          isDisabled={isLoading}
          onClick={onSubmit}
        />
      </React.Fragment>
    )}
    {step === 2 && (
      <div className={css.followup}>
        <p>
          <strong>
            Can&apos;t wait to get you on board. Tell us a bit more about you.
          </strong>
        </p>
        <hr />
        <p>
          My name is{" "}
          <UIInput
            className={css["form__input--name"]}
            value={name}
            placeholder="-"
            error={nameError}
            isDisabled={isLoading}
            hasErrorMessage={false}
            onChange={onNameChange}
          />
        </p>
        <p>
          I am a{" "}
          <UISelect
            value={job}
            placeholder="-"
            options={[
              { value: "developer" },
              { value: "designer" },
              { value: "product" },
            ]}
            error={jobError}
            isDisabled={isLoading}
            onChange={onJobChange}
          />{" "}
          working in a{" "}
          <UISelect
            value={teamSize}
            placeholder="-"
            options={[{ value: "1-5" }, { value: "5-10" }, { value: ">10" }]}
            error={teamSizeError}
            isDisabled={isLoading}
            onChange={onTeamSizeChange}
          />{" "}
          people tech team.
        </p>
        <p style={{ textAlign: "right" }}>
          <UIButton type="primary" label="Done" onClick={onSubmitFollowUp} />
        </p>
      </div>
    )}

    {step === 3 && (
      <p style={{ textAlign: "center" }}>
        Thank you! We&apos;ve sent you a confirmation on your email.
        <br />
        See you soon!
      </p>
    )}
  </div>
)

EarlyAccessView.propTypes = {
  className: PropTypes.string,
  callToAction: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
  name: PropTypes.string,
  nameError: PropTypes.string,
  email: PropTypes.string,
  emailError: PropTypes.string,
  job: PropTypes.string,
  jobError: PropTypes.string,
  teamSize: PropTypes.string,
  teamSizeError: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isCenter: PropTypes.bool,
  onNameChange: PropTypes.func.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  onJobChange: PropTypes.func.isRequired,
  onTeamSizeChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitFollowUp: PropTypes.func.isRequired,
}

EarlyAccessView.defaultProps = {
  className: null,
  name: "",
  nameError: null,
  email: "",
  emailError: null,
  job: null,
  jobError: null,
  teamSize: null,
  teamSizeError: null,
  isCenter: false,
}

export { EarlyAccessView }
