const debug = require("debug")("mutant:useFieldsCLI")

import { is } from "@mutant-ws/m"

import { positionAfter } from "/core.libs/positioning"
import { useEffect } from "/core.hooks/use-deep"
import { useCommands } from "/core.hooks/use-commands"

export const useFieldsCLI = (
  sectionLayer,
  { productId, cardId, focusId, fieldsList: { create, items }, onFocus }
) => {
  const [, [addCommands, removeCommands]] = useCommands()

  useEffect(() => {
    addCommands({
      layer: sectionLayer,
      commands: [
        {
          name: "image",
          abstract: `upload .png, .jpg or .gif file`,
          description: `upload .png, .jpg or .gif file`,
          onFinish: () =>
            create({
              productId,
              cardId,
              type: "image",
              position: positionAfter({ id: focusId, items }),
            }).then(({ error, result }) => {
              if (!is(error)) {
                onFocus(result.id)
              }
            }),
        },
        {
          name: "template",
          abstract: "insert predefined fields",
          description:
            "Insert predefined content based on commonly used best practices.",
          params: [
            {
              name: "name",
              description:
                "Name of Mutant template. User defined templates are not currently supported.",
              options: ["feature", "bug", "hypothesis", "user-story"],
              isRequired: true,
            },
          ],
          example: "template bug",
          onFinish: type =>
            create({
              productId,
              cardId,
              template: type,
            }).then(({ error }) => {
              if (!is(error)) {
                onFocus(`${cardId}-title`)
              }
            }),
        },
      ],
    })

    return () => removeCommands({ layer: sectionLayer })
  }, [
    productId,
    cardId,
    focusId,
    sectionLayer,
    items,
    create,
    onFocus,
    addCommands,
    removeCommands,
  ])
}
