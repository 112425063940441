const debug = require("debug")("mutant:MeContainer")

import React, { useState } from "react"
import { get, when, pipe, same, is } from "@mutant-ws/m"

import { errorMessagesByField } from "../../core.libs/routes"
import { useList } from "../../core.hooks/use-list"
import { useAuth } from "../../core.hooks/use-auth/use-auth"

import { UsersList } from "./data/list.users"
import { MeView } from "./me.view"

const MeContainer = () => {
  const {
    selector: { error, isLoading },
    update,
  } = useList(UsersList)
  const { id, name, email } = useAuth()

  // data selecting
  const updateErrors = when(
    is,
    pipe(get("data"), errorMessagesByField),
    same({})
  )(error("update"))

  const [localName, setLocalName] = useState(name)
  const [localEmail, setLocalEmail] = useState(email)

  const handleUserUpdate = () => {
    update(id, {
      name: localName,
      email: localEmail,
    })
  }

  return (
    <MeView
      id={id}
      name={localName}
      nameError={get("name")(updateErrors)}
      email={localEmail}
      emailError={get("email")(updateErrors)}
      isLoading={isLoading()}
      onNameChange={event => setLocalName(event.currentTarget.value)}
      onEmailChange={event => setLocalEmail(event.currentTarget.value)}
      onSubmit={handleUserUpdate}
    />
  )
}

export { MeContainer }
