const debug = require("debug")("mutant:SiblingsContainer")

import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { filterWith, findWith, is } from "@mutant-ws/m"
import { prop, map, pipe, head } from "ramda"

import { addShortcuts, removeShortcuts } from "/core.libs/keyboard"
import { hover } from "/core.libs/positioning"
import { useLiveList } from "/core.hooks/use-live-list"
import { useQuery } from "/core.hooks/use-query"
import {
  useFocus,
  WORK_CARD_SIBLINGS_LAYER,
  WORK_CARD_FIELDS_LAYER,
} from "/core.hooks/use-focus"

import { FeatureCardUI } from "./ui/feature-card"
import { SiblingUI } from "./ui/sibling"

import { SiblingsList } from "./data/list.siblings"

import css from "./siblings.css"

const SiblingsContainer = ({
  productId,
  cardId,
  featureId,
  members,
  onEscape,
}) => {
  const [, setQuery] = useQuery()
  const [focusId, setFocusId] = useState(null)

  //
  // Siblings - data fetching
  //

  const {
    selector: { items, byId },
    read,
    readOne,
  } = useLiveList(SiblingsList, {
    events: {
      prefix: "cards",
      shouldAcceptFn: useCallback(
        (data = {}) => {
          const isWork = data.type === "work"
          const isFeature = data.type === "feature"

          const isOurCard = isWork && featureId === data.featureId
          const isOurFeature = isFeature && featureId === data.id

          return isOurCard || isOurFeature
        },
        [featureId]
      ),
    },
  })

  useEffect(() => {
    if (is(featureId) && is(productId)) {
      read({
        productId,
        featureId,
      })
    }
  }, [featureId, productId, read])

  const cards = items()

  //
  // Feature - data fetching
  //

  useEffect(() => {
    if (is(featureId) && is(productId)) {
      readOne(featureId, { productId })
    }
  }, [featureId, productId, readOne])

  const {
    assignedTo: featureAssignedTo,
    title: featureTitle,
    coverURL: featureCoverURL,
    componentId,
    aggregate: { backlogCount, doingCount, testingCount, doneCount } = {},
  } = byId(featureId, {})

  const {
    name: featureUserName,
    avatarURL: featureAvatarURL,
    bgColor: featureUserBgColor,
    fgColor: featureUserFgColor,
  } = findWith({ userId: featureAssignedTo }, {})(members)

  //
  // Siblings - specific keyboard shortcuts
  //

  const [{ layer }, setFocus] = useFocus(productId)
  const hasKeyboard = layer === WORK_CARD_SIBLINGS_LAYER

  const handleSwitch = useCallback(
    id => {
      if (id !== cardId) {
        setQuery({
          ["work-id"]: id,
        })
      }
    },
    [cardId, setQuery]
  )

  useEffect(() => {
    addShortcuts({
      layer: WORK_CARD_SIBLINGS_LAYER,
      shortcuts: {
        // Close card
        Escape: () => onEscape(),

        // Give keyboard control to main Fields section
        ArrowRight: () =>
          setFocus({
            layer: WORK_CARD_FIELDS_LAYER,
          }),

        // Mode up and down through siblings
        ArrowUp: () => setFocusId(hover("up", { id: focusId, items: cards })),
        ArrowDown: () =>
          setFocusId(hover("down", { id: focusId, items: cards })),

        // Open hovered card
        Enter: () => handleSwitch(focusId),
      },
    })

    return () => removeShortcuts({ layer: WORK_CARD_SIBLINGS_LAYER })
  }, [cards, handleSwitch, focusId, onEscape, setFocus])

  // focus first when gaining keyboard
  useEffect(() => {
    if (hasKeyboard && !is(focusId)) {
      setFocusId(pipe(head, prop("id"))(cards))
    }
  }, [focusId, layer, cards, hasKeyboard])

  // sync focus if query param changes
  useEffect(() => {
    setFocusId(cardId)
  }, [cardId])

  return (
    <React.Fragment>
      <FeatureCardUI
        className={css.feature}
        id={featureId}
        title={featureTitle}
        coverURL={featureCoverURL}
        userName={featureUserName}
        userAvatarURL={featureAvatarURL}
        avatarBackgroundColor={featureUserBgColor}
        avatarForegroundColor={featureUserFgColor}
        componentId={componentId}
        countBacklog={backlogCount}
        countDoing={doingCount}
        countTesting={testingCount}
        countDone={doneCount}
        isSelect={cardId === featureId}
        isFocus={hasKeyboard && focusId === featureId}
        onClick={handleSwitch}
      />

      {map(status => {
        const cardsByStatus = filterWith({ status })(cards)

        return (
          <React.Fragment key={status}>
            <h3 className={css.status}>
              {status} <small>{cardsByStatus.length}</small>
            </h3>
            {map(
              ({
                id: siblingId,
                title: siblingTitle,
                assignedTo: siblingAssignedTo,
              }) => {
                const { name, bgColor, fgColor, avatarURL } = findWith(
                  { userId: siblingAssignedTo },
                  {}
                )(members)

                return (
                  <SiblingUI
                    key={siblingId}
                    id={siblingId}
                    title={siblingTitle}
                    userName={name}
                    userAvatarURL={avatarURL}
                    avatarBackgroundColor={bgColor}
                    avatarForegroundColor={fgColor}
                    isSelect={cardId === siblingId}
                    isFocus={hasKeyboard && focusId === siblingId}
                    onClick={handleSwitch}
                  />
                )
              }
            )(cardsByStatus)}
          </React.Fragment>
        )
      })(["backlog", "doing", "testing", "done"])}
    </React.Fragment>
  )
}

SiblingsContainer.propTypes = {
  productId: PropTypes.string.isRequired,
  cardId: PropTypes.string.isRequired,
  featureId: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
      bgColor: PropTypes.string,
      fgColor: PropTypes.string,
    })
  ),
  onEscape: PropTypes.func.isRequired,
}

SiblingsContainer.defaultProps = {
  members: [],
}

export { SiblingsContainer }
