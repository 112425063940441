const debug = require("debug")("mutant:useTrack")

import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { track as nextTrack } from "@mutant-ws/next"
import { is, isNotEmpty, debounce } from "@mutant-ws/m"

let ReactGA = null

if (process.env.API_URL === "https://api.mutant.love") {
  ReactGA = require("react-ga")
  ReactGA.initialize("UA-150304478-1")
}

export const track = (name, data = {}) =>
  Promise.all([
    nextTrack(name, {
      // mutant
      productId: "50b186fa-4d13-4aca-b182-bf13cd8d78a1",
      ...data,
    }),
    nextTrack(name, {
      // demo
      productId: "1ba60e36-8580-4273-8768-4fe40f180006",
      ...data,
    }),
  ])

export const debounceTrack = debounce(track, { wait: 1000 })

export const useTrack = () => {
  const location = useLocation()

  useEffect(() => {
    const currentUrl = location.pathname + location.search

    track("pageview")

    is(ReactGA) && ReactGA.pageview(currentUrl)

    // jump to content if anchor is defined
    if (isNotEmpty(location.hash)) {
      const element = document.getElementById(location.hash.replace("#", ""))

      is(element) && element.scrollIntoView()
    } else {
      window.scroll({ top: 0 })
    }
  }, [location.hash, location.pathname, location.search])
}
