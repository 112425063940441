const debug = require("debug")("mutant:GuestLayout")

import React, { useState, useRef, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { pipe, reduce, read, isEmpty, is } from "@asd14/m"

import { buildURL, getPath, errorMessagesByField } from "/core.libs/routes"
import { addShortcuts, removeShortcuts } from "/core.libs/keyboard"
import { track } from "/core.hooks/use-track"
import { useCommands, byLayer } from "/core.hooks/use-commands"
import { useAuth } from "/core.hooks/use-auth/use-auth"

import { EarlyAccessContainer } from "/page.home/_.early-access/early-access.container"
import { BaseLayout } from "/layout.base/base"
import { UILogo } from "/core.ui/logo/logo"
import { UICommandLine } from "/core.ui/command-line/command-line"

import { UIFooter } from "./ui/footer/footer"
import { UITopMenu } from "./ui/topmenu/topmenu"
import { UIDataStatus } from "./ui/data-status/data-status"
import { UIDemoLogin } from "./ui/demo-login/demo-login"

import css from "./guest.css"

const statusSelector = pipe(
  Object.values,
  reduce(
    (acc, item) => ({
      loadCount: item.isLoading ? acc.loadCount + 1 : acc.loadCount,
      createCount: isEmpty(item.creating)
        ? acc.createCount
        : acc.createCount + 1,
      updateCount: isEmpty(item.updating)
        ? acc.updateCount
        : acc.updateCount + 1,
      removeCount: isEmpty(item.removing)
        ? acc.removeCount
        : acc.removeCount + 1,
    }),
    {
      loadCount: 0,
      createCount: 0,
      updateCount: 0,
      removeCount: 0,
    }
  )
)

const GuestLayout = ({ children }) => {
  const history = useHistory()

  const commandRef = useRef()
  const [isCLIVisible, setIsCLIVisible] = useState(false)

  const loginFormRef = useRef()
  const [demoName, setDemoName] = useState("")
  const [demoNameError, setDemoNameError] = useState()
  const [demoEmail, setDemoEmail] = useState("")
  const [demoEmailError, setDemoEmailError] = useState()
  const [isDemoLoginVisible, setIsDemoLoginVisible] = useState(false)

  const {
    isAuthenticated,
    register,
    loginWithEmail,
    loginDemo,
    logout,
  } = useAuth()
  const { createCount, loadCount, updateCount, removeCount } = useSelector(
    statusSelector
  )

  //
  // Guest CLI commands
  //

  useEffect(() => {
    addShortcuts({
      layer: "base",
      shortcuts: {
        Escape: () => {
          setIsCLIVisible(false)
        },
        ":,/": event => {
          // prevent ":" or "/" geting registered by input
          event.preventDefault()

          setIsCLIVisible(true)

          // in case cli is already open
          if (is(commandRef.current)) {
            commandRef.current.focus()
          }
        },
      },
    })

    return () => removeShortcuts({ layer: "base" })
  }, [])

  //
  // Guest keyboard shortcuts
  //

  const [commands, [addCommands, removeCommands]] = useCommands()

  const handleCmdRegister = useCallback(
    (email, name) => register({ email, name }),
    [register]
  )

  const handleCmdMe = useCallback(
    () => history.push(getPath("users:profile")),
    [history]
  )

  useEffect(() => {
    addCommands({
      layer: "base",
      commands: [
        ...(isAuthenticated
          ? [
              {
                name: "me",
                abstract: "go to your profile page",
                description: "go to your profile page",
                onFinish: handleCmdMe,
              },
              {
                name: "logout",
                abstract: "ok, bye!",
                description: "ok, bye!",
                onFinish: logout,
              },
            ]
          : [
              {
                name: "login",
                abstract: "enter existing account",
                description: "enter existing account",
                params: [{ name: "email", isRequired: true }],
                onFinish: loginWithEmail,
              },
              {
                name: "register",
                abstract: "create a new account",
                description: "create a new account",
                params: [{ name: "email", isRequired: true }, { name: "name" }],
                onFinish: handleCmdRegister,
              },
            ]),
      ],
    })

    return () => removeCommands({ layer: "base" })
  }, [
    isAuthenticated,
    handleCmdMe,
    handleCmdRegister,
    loginWithEmail,
    register,
    logout,
    addCommands,
    removeCommands,
  ])

  // focus inner input when displaying CommandLine
  useEffect(() => {
    if (isCLIVisible && is(commandRef.current)) {
      commandRef.current.focus()
    }
  }, [isCLIVisible])

  const handleHeaderTracking = linkName => () => {
    track(`global__top-menu--${linkName}-click`)

    if (linkName === "login") {
      setIsCLIVisible(!isCLIVisible)

      if (isDemoLoginVisible) {
        setIsDemoLoginVisible(false)
      }
    }

    if (linkName === "demo") {
      setIsDemoLoginVisible(!isDemoLoginVisible)

      if (isCLIVisible) {
        setIsCLIVisible(false)
      }
    }
  }

  const handleFooterTracking = linkName => () => {
    track(`global__footer--${linkName}-click`)
  }

  const handleLoginSubmit = useCallback(() => {
    loginDemo({ email: demoEmail, name: demoName })
      .then(() => {
        const demoProductURL = buildURL("demo:product", {
          params: {
            productId: process.env.DEMO_ID,
          },
        })

        window.open(demoProductURL)
        setIsDemoLoginVisible(false)
      })
      .catch(error => {
        const errors = errorMessagesByField(error)

        setDemoEmailError(read("email", null, errors))
        setDemoNameError(read("name", null, errors))
      })
  }, [loginDemo, demoName, demoEmail])

  return (
    <BaseLayout>
      <UITopMenu
        isLoggedIn={isAuthenticated}
        isLoginActive={isCLIVisible}
        isDemoActive={isDemoLoginVisible}
        onClick={handleHeaderTracking}
      />
      <UIDataStatus
        createCount={createCount}
        loadCount={loadCount}
        updateCount={updateCount}
        removeCount={removeCount}
      />
      {children}
      <UIFooter onClick={handleFooterTracking}>
        <p>
          We are gradually inviting teams to use <UILogo height="15px" />
        </p>
        <EarlyAccessContainer
          className={css["footer-form"]}
          location="footer"
        />
      </UIFooter>

      {isCLIVisible ? (
        <div className={css["cli-wrapper"]}>
          <UICommandLine
            ref={commandRef}
            commands={byLayer("base", commands)}
          />
        </div>
      ) : null}

      {isDemoLoginVisible ? (
        <div className={css["demo-login-wrapper"]}>
          <UIDemoLogin
            ref={loginFormRef}
            name={demoName}
            nameError={demoNameError}
            email={demoEmail}
            emailError={demoEmailError}
            isLoading={false}
            onNameChange={setDemoName}
            onEmailChange={setDemoEmail}
            onSubmit={handleLoginSubmit}
          />
        </div>
      ) : null}
    </BaseLayout>
  )
}

GuestLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { GuestLayout }
