const debug = require("debug")("mutant:CardsList")

import { buildList } from "@mutant-ws/redux-list"
import { GET, PATCH } from "@mutant-ws/fetch-browser"

export const CardsList = buildList({
  name: "PRODUCT.WORK-CARD.CARDS",

  readOne: id =>
    GET(`/cards/${id}`, {
      query: {
        pluck: [
          "featureId",
          "title",
          "componentId",
          "assignedTo",
          "status",
          "type",
          "position",
          "createdAt",
          "isBug",
          "doingAt",
          "testingAt",
          "doneAt",
        ],
      },
    }),

  update: (id, { title, componentId, assignedTo }) =>
    PATCH(`/cards/${id}`, {
      body: {
        componentId,
        title,
        assignedTo,
      },
    }),
})
