import { hot } from "react-hot-loader"
import React from "react"
import { Provider } from "react-redux"
import { Helmet } from "react-helmet"

import { MutantRouter } from "./index.router"
import { mutantStore } from "./index.redux"

// Parcel hot reloading
if (module.hot) {
  module.hot.accept()
}

export const MutantApp = hot(module)(() => (
  <Provider store={mutantStore}>
    <Helmet
      defaultTitle="Mutant Workspace"
      titleTemplate="%s | Mutant Workspace"
    />
    <MutantRouter />
  </Provider>
))
