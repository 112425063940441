const debug = require("debug")("mutant:Homepage")

import React from "react"
import cx from "classnames"
import { Helmet } from "react-helmet"

import { EarlyAccessContainer } from "./_.early-access/early-access.container"
import { HeroUI } from "./ui/hero"

import css from "./home.css"

import cliPath from "./ui/images/CLI.png"
// import productPath from "./ui/images/product.png"
// import devPath from "./ui/images/dev.png"
// import designPath from "./ui/images/design.png"
import githubPath from "./ui/images/github.png"

const HomePage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>User-driven product development planning </title>
      </Helmet>

      <section className={css.hero}>
        <div className={css["hero-content"]}>
          <h1>
            Development planning
            <br />
            for data-driven product teams
          </h1>

          <h2>
            Building a great product is not a linear process. It is a series of
            countless decisions and iterations.
            <br />
            Mutant helps your team make sense of it all and build the next,
            better version.
          </h2>

          <HeroUI />

          <EarlyAccessContainer location="hero-home" isCenter={true} />
        </div>
      </section>

      <section className={cx(css["hero-content"])}>
        <h1 className={css["one-workspace"]}>
          Built for outcome-driven product development teams
        </h1>

        <div className={css["essential-tools"]}>
          <div>
            <code>
              <em>1</em> work
            </code>
            <p>
              A powerful task board built for power users: keyboard-first,
              command-line driven, real-time collaboration.
            </p>
          </div>

          <div>
            <code>
              <em>2</em> analytics
            </code>
            <p>
              Self-serviced user analytics on a need-to-know basis, instead of
              buried in dashboards are obsolete the next week.
            </p>
            <p>
              <a
                className={css["github-button"]}
                href="https://github.com/mutant-ws/next"
                target="_blank"
                rel="noopener noreferrer">
                <img src={githubPath} />
                @mutant-ws/next
              </a>
            </p>
          </div>

          <div>
            <code>
              <em>3</em> feedback
            </code>
            <p>
              A system to organize feature requests, feedback or ideas your team
              has and integrate them in product planning.
            </p>
          </div>
        </div>

        <img className={css["cli-img"]} src={cliPath} />
      </section>
    </React.Fragment>
  )
}

export { HomePage }
