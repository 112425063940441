const debug = require("debug")("mutant:CommentsContainer")

import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import { DateTime } from "luxon"
import cx from "classnames"
import { findWith, is } from "@mutant-ws/m"
import { pipe, map, head, prop, last } from "ramda"

import { addShortcuts, removeShortcuts } from "/core.libs/keyboard"
import { hover } from "/core.libs/positioning"
import { useList } from "/core.hooks/use-list"
import {
  useFocus,
  WORK_CARD_COMMENTS_LAYER,
  WORK_CARD_FIELDS_LAYER,
} from "/core.hooks/use-focus"

import { UIAvatar } from "/core.ui/avatar/avatar"

import { FieldMarkdownUI } from "./ui/field-markdown/field-markdown"
import { CommentsList } from "./data/list.comments"

import css from "./comments.css"

const CommentsContainer = ({ productId, parentId, members, onEscape }) => {
  const [editId, setEditId] = useState(null)
  const [hoverId, setHoverId] = useState(null)

  //
  // Comments data fetching
  //

  const {
    selector: { items },
    create,
    read,
    update,
    remove,
  } = useList(CommentsList)

  useEffect(() => {
    read({ parentId })
  }, [parentId, read])

  const comments = items()

  //
  // Comments keyboard shortcuts
  //

  const [{ layer }, setFocus] = useFocus(productId)
  const hasKeyboard = layer === WORK_CARD_COMMENTS_LAYER

  useEffect(() => {
    addShortcuts({
      layer: WORK_CARD_COMMENTS_LAYER,
      shortcuts: {
        // Close card
        Escape: () => onEscape(),

        // Give keyboard to Card body section
        ArrowLeft: () =>
          setFocus({
            layer: WORK_CARD_FIELDS_LAYER,
          }),

        //
        "ArrowUp,ArrowDown": ({ key }) => {
          setHoverId(
            hover(key === "ArrowUp" ? "up" : "down", {
              id: hoverId,
              items: [{ id: "new-comment" }, ...comments],
            })
          )
        },

        i: () => {
          if (is(hoverId)) {
            event.preventDefault()
            setEditId(hoverId)
          }
        },

        d: () => {
          if (!is(hoverId) || hoverId === "new-comment") {
            return
          }

          if (window.confirm(`Delete comment?`)) {
            remove(hoverId).then(() => {
              const isLast = hoverId === pipe(last, prop("id"))(comments)

              setHoverId(
                hover(isLast === "ArrowUp" ? "up" : "down", {
                  id: hoverId,
                  items: comments,
                })
              )
            })
          }
        },
      },
    })

    return () => removeShortcuts({ layer: WORK_CARD_COMMENTS_LAYER })
  }, [hoverId, comments, onEscape, remove, setFocus])

  // hover first when gaining keyboard
  useEffect(() => {
    const hasComments = comments.length !== 0

    if (!is(hoverId) && hasKeyboard) {
      setHoverId(hasComments ? pipe(head, prop("id"))(comments) : "new-comment")
    }
  }, [comments, hoverId, hasKeyboard])

  //
  const handleSubmit = useCallback(
    (commentId, { oldValue, newValue }) => {
      if (oldValue === newValue) {
        setEditId(null)
      } else {
        update(commentId, {
          text: newValue,
        }).then(() => {
          setEditId(null)
        })
      }
    },
    [update]
  )

  const handleHover = useCallback(id => setHoverId(id), [setHoverId])

  const handleSwitchEditMode = useCallback(
    commentId => {
      setEditId(commentId)
      setHoverId(commentId)
    },
    [setEditId, setHoverId]
  )

  const handleCancel = useCallback(() => {
    setEditId(null)
  }, [setEditId])

  const handleNewCommentSubmit = useCallback(
    (commentId, { newValue }) => {
      create({
        parentId,
        text: newValue,
      }).then(({ result: { id } }) => {
        setEditId(null)
        setHoverId(id)
      })
    },
    [parentId, create]
  )

  return (
    <>
      <FieldMarkdownUI
        key="new-comment"
        id="new-comment"
        value=""
        placeholder="Post a comment"
        isFocus={hasKeyboard && "new-comment" === hoverId}
        isEdit={"new-comment" === editId}
        onViewModeClick={handleHover}
        onViewModeDoubleClick={handleSwitchEditMode}
        onEditModeCancel={handleCancel}
        onEditModeSubmit={handleNewCommentSubmit}
      />
      {map(
        ({
          id: commentId,
          text,
          createdAt,
          updatedAt,
          permissions: { ownerId } = {},
        }) => {
          const { name, avatarURL } = findWith({ id: ownerId }, {})(members)

          return (
            <div key={commentId} className={cx(css.comment)}>
              <div className={css.header}>
                <UIAvatar
                  className={css.avatar}
                  name={name ?? "?"}
                  avatar={avatarURL}
                  size={26}
                />
                <div className={css.name}>{name}</div>
                <div className={css.date}>
                  {DateTime.fromISO(createdAt).toRelative()}{" "}
                  {is(updatedAt) ? "(edited)" : null}
                </div>
              </div>
              <FieldMarkdownUI
                id={commentId}
                value={text}
                isFocus={hasKeyboard && commentId === hoverId}
                isEdit={commentId === editId}
                onViewModeClick={handleHover}
                onViewModeDoubleClick={handleSwitchEditMode}
                onEditModeCancel={handleCancel}
                onEditModeBlur={handleSubmit}
                onEditModeSubmit={handleSubmit}
              />
            </div>
          )
        }
      )(comments)}
    </>
  )
}

CommentsContainer.propTypes = {
  productId: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
    })
  ),
  onEscape: PropTypes.func.isRequired,
}

CommentsContainer.defaultProps = {
  members: [],
}

export { CommentsContainer }
