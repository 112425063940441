const debug = require("debug")("mutant:useFeedbackCLI")

import { useEffect } from "react"
import { is } from "@mutant-ws/m"
import { HTTPError } from "@mutant-ws/fetch-browser"

import { useCommands } from "../core.hooks/use-commands"

export const useFeedbackCLI = (
  sectionLayer,
  { productId, insightId, onInsightCreate, onQuoteCreate }
) => {
  const [, [addCommands, removeCommands]] = useCommands()

  useEffect(() => {
    addCommands({
      layer: sectionLayer,
      commands: [
        //
        {
          name: "quote",
          description: "add new user quote",
          onFinish: () =>
            onQuoteCreate({
              productId,
              insightId,
            }).then(({ error }) => {
              if (is(error)) {
                throw new HTTPError(error.body.message, {
                  status: error.status,
                })
              }
            }),
        },

        //
        {
          name: "insight",
          description: "add new insight",
          onFinish: () =>
            onInsightCreate({ productId }).then(({ error }) => {
              if (is(error)) {
                throw new HTTPError(error.body.message, {
                  status: error.status,
                })
              }
            }),
        },
      ],
    })

    return () => removeCommands({ layer: sectionLayer })
  }, [
    productId,
    insightId,
    sectionLayer,
    onInsightCreate,
    onQuoteCreate,
    addCommands,
    removeCommands,
  ])
}
