/* eslint-disable react/forbid-prop-types,no-shadow */

const debug = require("debug")("mutant:UIVxBar")

import React from "react"
import PropTypes from "prop-types"
import { map, read, findWith, is, pipe, flatten, isEmpty } from "@asd14/m"
import { uniqBy } from "ramda"
import { timeFormat } from "d3-time-format"
import { scaleBand } from "d3-scale"

import { deepReactMemo, useMemo } from "../../core.hooks/use-deep"

import { UIVxTooltip } from "../vx-tooltip/vx-tooltip"
import { UIVxBarView } from "./vx-bar.view"

import css from "./vx-bar.css"

const getYFromX = (x, points) =>
  pipe(
    findWith({
      x: source => x.getTime() === source.getTime(),
    }),
    read("y")
  )(points)

const UIVxBar = ({
  width,
  height,
  highlightX,
  color,
  dataSets,
  numTicksRows,
  hasTooltip,
  hasAxisLeft,
  hasAxisBottom,
  onPointHover,
}) => {
  const leftAxisWidth = hasAxisLeft ? 30 : 0
  const bottomAxisHeight = hasAxisBottom ? 40 : 0

  const maxWidth = width - leftAxisWidth

  // useMemo to mitigate hover state changes
  const { xDomain, xGroupScale } = useMemo(() => {
    // Array of uniq x values (Date objects) from all data sets
    const xDomain = pipe(
      map(read("points")),
      flatten,
      map(read("x")),
      uniqBy(item => item.getTime())
    )(dataSets)

    const xGroupScale = scaleBand()
      // no of  groups = distinct dates
      .domain(xDomain)
      // spread on the width of the element
      .range([0, maxWidth])
      .padding(0.2)

    return {
      xDomain,
      xGroupScale,
    }
  }, [maxWidth, dataSets])

  const tooltipWidth = 150
  const tooltipLeft = is(highlightX)
    ? leftAxisWidth + xGroupScale(highlightX) + xGroupScale.bandwidth()
    : 0

  return (
    <div className={css.chart}>
      <svg width={width} height={height}>
        <UIVxBarView
          width={width}
          height={height}
          color={color}
          numTicksRows={numTicksRows}
          leftAxisWidth={leftAxisWidth}
          bottomAxisHeight={bottomAxisHeight}
          dataSets={dataSets}
          xDomain={xDomain}
          xGroupScale={xGroupScale}
          hasAxisLeft={hasAxisLeft}
          hasAxisBottom={hasAxisBottom}
          onPointHover={onPointHover}
        />
      </svg>

      {hasTooltip && !isEmpty(highlightX) ? (
        <UIVxTooltip
          style={{
            maxWidth: `${tooltipWidth}px`,
            top: "0px",
            left:
              tooltipLeft + tooltipWidth + 20 > width
                ? "auto"
                : `${tooltipLeft + 20}px`,
            right:
              tooltipLeft + tooltipWidth + 20 > width
                ? `${width - tooltipLeft + 20}px`
                : "auto",
          }}
          title={timeFormat("%b %d, '%y")(highlightX)}
          values={map(
            ({ id, points }) => ({
              id,
              color: color(id),
              text: getYFromX(highlightX, points),
            }),
            dataSets
          )}
        />
      ) : null}
    </div>
  )
}

UIVxBar.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  highlightX: PropTypes.instanceOf(Date),
  color: PropTypes.func.isRequired,
  dataSets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.instanceOf(Date).isRequired,
          y: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  numTicksRows: PropTypes.number,
  hasAxisLeft: PropTypes.bool,
  hasAxisBottom: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  onPointHover: PropTypes.func.isRequired,
}

UIVxBar.defaultProps = {
  width: 300,
  height: 100,
  highlightX: null,
  dataSets: [],
  numTicksRows: 3,
  hasAxisLeft: true,
  hasAxisBottom: true,
  hasTooltip: true,
}

const memo = deepReactMemo(UIVxBar)

export { memo as UIVxBar }
