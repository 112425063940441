const debug = require("debug")("mutant:InsightsContainer")

import PropTypes from "prop-types"
import React, { useState, useCallback, useEffect } from "react"

import { useQuery } from "/core.hooks/use-query"
import { useLiveList } from "/core.hooks/use-live-list"
import { useFocus, FEEDBACK_INSIGHTS_LAYER } from "/core.hooks/use-focus"

import { useInsightsKeyboard } from "./insights.keyboard"
import { InsightsList } from "./data/list.insights"
import { InsightsView } from "./insights.view"

const InsightsContainer = ({ productId, insight, items }) => {
  const [focusId, setFocusId] = useState()
  const [, setQuery] = useQuery()
  const [{ layer }, setFocus] = useFocus(productId)

  const { remove } = useLiveList(InsightsList, {
    events: {
      prefix: "insights",
    },
  })

  // sync focusId if selected insight changes
  useEffect(() => {
    setFocusId(insight.id)
  }, [insight.id])

  const handleInsightSelect = useCallback(
    id => {
      if (layer !== FEEDBACK_INSIGHTS_LAYER) {
        setFocus({ layer: FEEDBACK_INSIGHTS_LAYER })
      }

      setQuery({ "insight-id": id })
      setFocusId(id)
    },
    [layer, setFocus, setQuery, setFocusId]
  )

  //
  // Insights keyboard shortcuts
  //

  const { hasKeyboard } = useInsightsKeyboard(FEEDBACK_INSIGHTS_LAYER, {
    productId,
    focusId,
    insightsList: { items, remove },
    onFocusChange: setFocusId,
    onSelectChange: handleInsightSelect,
  })

  return (
    <React.Fragment>
      <InsightsView
        focusId={hasKeyboard ? focusId : null}
        selectId={insight.id}
        items={items}
        onInsightClick={handleInsightSelect}
      />
    </React.Fragment>
  )
}

InsightsContainer.propTypes = {
  productId: PropTypes.string.isRequired,
  insight: PropTypes.shape({
    id: PropTypes.string,
    body: PropTypes.string,
    isInbox: PropTypes.bool,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      aggregate: PropTypes.shape({
        quotesCount: PropTypes.number,
      }).isRequired,
    })
  ),
}

InsightsContainer.defaultProps = {
  insight: {},
  items: [],
}

export { InsightsContainer }
