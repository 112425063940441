const debug = require("debug")("mutant:ProductView")

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { get } from "@mutant-ws/m"

import { buildURL } from "../../core.libs/routes"

import { UIFieldCode } from "../../core.ui/field-code/field-code"
import { UITabs } from "../../core.ui/tabs/tabs"
import { UIButton } from "../../core.ui/button/button"

import css from "./product.css"

const ProductView = ({ id, name, integrations }) => {
  const history = useHistory()
  const [tab, setTab] = useState("install")

  return (
    <div className={css.product} key={id}>
      <h2>
        {name}
        <UIButton
          className={css["go-to-board"]}
          label="Go to workspace"
          type="secondary"
          size="small"
          onClick={() =>
            history.push(
              buildURL("products:view", {
                params: { productId: id },
              })
            )
          }
        />
      </h2>
      <UITabs
        value={tab}
        align="left"
        items={[
          {
            id: "overview",
            label: "Overview",
          },
          {
            id: "install",
            label: "Install",
          },
        ]}
        onChange={value => setTab(value)}
      />

      {tab === "install" && (
        <div>
          <h2>Browser events</h2>
          <p>
            Start recording events in under 1 minute. For more details read the
            full docs,{" "}
            <a
              href="https://github.com/mutant-ws/next"
              target="_blank"
              rel="noopener noreferrer">
              @mutant-ws/next
            </a>
            .
          </p>
          <p>
            <strong>1. Install package</strong>
          </p>
          <UIFieldCode
            id={`product-${id}-step-1`}
            language="bash"
            value="npm install @mutant-ws/next"
            isReadOnly={true}
            hasLineNumbers={false}
            hasLineHighlight={false}
          />
          <br />
          <p>
            <strong>2. Link events to your &quot;{name}&quot; workspace</strong>
          </p>
          <p>
            You only need to do this once so put it somewhere high in your
            import chain.
          </p>
          <UIFieldCode
            id={`product-${id}-step-2`}
            language="javascript"
            value={`import { set } from "@mutant-ws/next"

set({
  productId: "${id}",
})`}
            isReadOnly={true}
            hasLineNumbers={false}
            hasLineHighlight={false}
          />
          <br />
          <p>
            <strong>
              3. When something happens in your application, push an event
            </strong>
          </p>
          <UIFieldCode
            id={`product-${id}-step-3`}
            language="javascript"
            value={`import { track } from "@mutant-ws/next"

track("page__section--action-name")`}
            isReadOnly={true}
            hasLineNumbers={false}
            hasLineHighlight={false}
          />
          <h2>API</h2>
          <p>
            <code>POST</code> at <code>https://api.mutant.love/track</code> with
            the same constraints as before (<code>name</code> and{" "}
            <code>productId</code> required).
          </p>
          <p>Ex. Tracking CI deployment success and failure.</p>
          <UIFieldCode
            id={`product-${id}-step-4`}
            language="bash"
            value={`curl \\
  --header "Content-Type: application/json" \\
  --data '{"name": "mutant__ci-success", "productId": "${id}"}' \\
  https://api.mutant.love/track`}
            isReadOnly={true}
            hasLineNumbers={false}
            hasLineHighlight={false}
          />
        </div>
      )}

      {tab === "slack" && (
        <div>
          <p>
            To get your Webhook URL, visit Slack&apos;s&nbsp;
            <a href="https://my.slack.com/services/new/mutant">
              Mutant Integration
            </a>
            &nbsp; page, choose a default channel, and click the green &quot;Add
            CircleCI Integration&quot; button at the bottom of the page.
          </p>
          {get("slack")(integrations)}
        </div>
      )}
    </div>
  )
}

ProductView.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  integrations: PropTypes.shape({
    slack: PropTypes.shape({
      webhookURL: PropTypes.string,
    }),
  }),
}

ProductView.defaultProps = {
  name: "",
  integrations: {},
}

const memo = React.memo(ProductView)

export { memo as ProductView }
