const debug = require("debug")("mutant:MetricUI")

import React, { useRef, useState, useCallback } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { map, prop } from "ramda"
import { is, isEmpty } from "@mutant-ws/m"
import { ParentSize } from "@vx/responsive"

import { deepReactMemo } from "/core.hooks/use-deep"
import { useHeavyColorScale } from "/core.hooks/use-color-scale"

import { UIVxArea } from "/core.ui/vx-area/vx-area"
import { UIVxBar } from "/core.ui/vx-bar/vx-bar"

import css from "./metric.css"

const MetricUI = ({ className, title, type, dataSets }) => {
  const [highlightX, setHighlightX] = useState()
  const fieldRef = useRef()

  const handleHightlightPoint = useCallback(source => {
    setHighlightX(is(source) ? source : null)
  }, [])

  const eventNames = map(prop("id"))(dataSets)
  const color = useHeavyColorScale(eventNames)

  return (
    <div
      ref={fieldRef}
      className={cx(css.metric, {
        [className]: is(className),
      })}>
      {isEmpty(title) ? null : <div className={css.title}>{title}</div>}

      {isEmpty(dataSets) ? (
        <div className={cx(css.chart, css["chart--empty"])}>
          ¯\_(ツ)_/¯
          <small>
            You need to measure to <strong>know</strong>.
          </small>
        </div>
      ) : (
        <div className={css.chart}>
          <ParentSize>
            {({ width, height }) => {
              if (type === "bar") {
                return (
                  <UIVxBar
                    width={width}
                    height={height}
                    highlightX={highlightX}
                    color={color}
                    dataSets={dataSets}
                    hasAxisBottom={false}
                    hasAxisLeft={false}
                    hasTooltip={false}
                    hasGrid={false}
                    onPointHover={handleHightlightPoint}
                  />
                )
              }

              return (
                <UIVxArea
                  width={width}
                  height={height}
                  highlightX={highlightX}
                  color={color}
                  dataSets={dataSets}
                  isSmooth={true}
                  hasAxisBottom={false}
                  hasAxisLeft={false}
                  hasTooltip={false}
                  hasGrid={false}
                  onPointHover={handleHightlightPoint}
                />
              )
            }}
          </ParentSize>
        </div>
      )}
    </div>
  )
}

MetricUI.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  dataSets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.instanceOf(Date).isRequired,
          y: PropTypes.number.isRequired,
        })
      ),
    })
  ),
}

MetricUI.defaultProps = {
  className: null,
  title: null,
  type: "area",
  dataSets: [],
}

const memo = deepReactMemo(MetricUI)

export { memo as MetricUI }
