const debug = require("debug")("mutant:UITabs")

import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { isEmpty } from "@mutant-ws/m"
import { keys, map } from "ramda"

import { deepReactMemo } from "../../core.hooks/use-deep"

import css from "./tabs.css"

const UITabs = ({ value, align, items, onChange }) => (
  <ul className={cx(css.tabs, css[`tabs--align-${align}`])}>
    {map(({ id, label }) => (
      <li
        key={id}
        className={cx(css.tab, {
          [css["tab--is-active"]]: value === id,
        })}
        onClick={() => onChange(id)}>
        {isEmpty(label) ? id : label}
      </li>
    ))(items)}
  </ul>
)

UITabs.propTypes = {
  value: PropTypes.string,
  align: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
    })
  ),
  onChange: PropTypes.func.isRequired,
}

UITabs.defaultProps = {
  value: null,
  align: "left",
  items: [],
}

const memo = deepReactMemo(UITabs, keys(UITabs.propTypes))

export { memo as UITabs }
