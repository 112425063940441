const debug = require("debug")("mutant:useMetricsKeyboard")

import { useEffect } from "react"
import { is, findWith } from "@mutant-ws/m"
import { pipe, head, prop, last, propEq } from "ramda"

import { addShortcuts, removeShortcuts } from "/core.libs/keyboard"
import { hover, move } from "/core.libs/positioning"
import { useYank } from "/core.hooks/use-yank/use-yank"
import { useFocus } from "/core.hooks/use-focus"

export const useMetricsKeyboard = (
  sectionLayer,
  {
    productId,
    focusId,
    metricsList: { items, update, remove },
    onHoverChange,
    onEdit,
  }
) => {
  const [{ layer }] = useFocus(productId)
  const { yank } = useYank()
  const hasKeyboard = layer === sectionLayer

  // hover first when gaining keyboard
  useEffect(() => {
    const hasMetrics = items.length !== 0

    if (!is(focusId) && hasKeyboard && hasMetrics) {
      onHoverChange(pipe(head, prop("id"))(items))
    }
  }, [focusId, items, hasKeyboard, onHoverChange])

  useEffect(() => {
    addShortcuts({
      layer: sectionLayer,
      shortcuts: {
        // Mode up and down through fields
        "ArrowUp,ArrowDown": ({ key, shiftKey }) => {
          const shouldUpdate = shiftKey && is(focusId)
          const shouldHover = !shiftKey

          if (shouldUpdate) {
            update(focusId, {
              position: move(key === "ArrowUp" ? "up" : "down", {
                id: focusId,
                items,
              }),
            })
          }

          if (shouldHover) {
            onHoverChange(
              hover(key === "ArrowUp" ? "up" : "down", {
                id: focusId,
                items,
              })
            )
          }
        },

        // copy to global state buffer
        "c,y": ({ key, ctrlKey, metaKey }) => {
          const shouldYank =
            is(focusId) &&
            (key === "y" || (key === "c" && (ctrlKey || metaKey)))

          if (shouldYank) {
            yank({ id: focusId, model: "metric" })
          }
        },

        // switch to edit mode
        "e,i,Enter": event => {
          onEdit(focusId, event)
        },

        // Delete selected
        "Backspace,Delete,d": () => {
          if (!is(focusId)) {
            return
          }

          const { title } = pipe(findWith({ id: focusId }), prop("code"))(items)

          if (window.confirm(`Delete "${title}" metric?`)) {
            remove(focusId).then(() => {
              const isLast = pipe(last, propEq("id", focusId))(items)

              onHoverChange(
                hover(isLast ? "up" : "down", {
                  id: focusId,
                  items,
                })
              )
            })
          }
        },
      },
    })

    return () => removeShortcuts({ layer: sectionLayer })
  }, [
    sectionLayer,
    focusId,
    items,
    yank,
    update,
    remove,
    onHoverChange,
    onEdit,
  ])

  return {
    hasKeyboard,
  }
}
