const debug = require("debug")("mutant:QuotesContainer")

import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import { filterWith, findWith, isEmpty, is } from "@mutant-ws/m"
import { map } from "ramda"

import { useLiveList } from "../core.hooks/use-live-list"
import { useFocus, FEEDBACK_QUOTES_LAYER } from "../core.hooks/use-focus"

import { QuotesList } from "./data/list.quotes"
import { VotesList } from "./data/list.votes"
import { QuotesView } from "./quotes.view"
import { useQuotesKeyboard } from "./quotes.keyboard"

const QuotesContainer = ({ productId, insight, onInsightUpdate }) => {
  const [focusId, setFocusId] = useState()
  const [editId, setEditId] = useState()
  const [{ layer }, setFocus] = useFocus(productId)
  const hasKeyboard = layer === FEEDBACK_QUOTES_LAYER

  //
  // Quotes data fetching
  //

  const {
    selector: { items: quotesItems },
    read: readQuotes,
    create,
    update,
    remove,
  } = useLiveList(QuotesList, {
    events: {
      prefix: "quotes",
      shouldAcceptFn: useCallback(
        (data = {}) => data.insightId === insight.id,
        [insight.id]
      ),
    },
  })

  useEffect(() => {
    readQuotes({ productId, insightId: insight.id })
  }, [productId, insight.id, readQuotes])

  const quotes = filterWith({
    insightId: insight.id,
  })(quotesItems())

  //
  // Votes data fetching
  //

  const {
    selector: { items: votesItems },
    create: createVote,
    read: readVotes,
  } = useLiveList(VotesList, {
    events: {
      prefix: "votes",
    },
  })

  useEffect(() => {
    // votes for loggedin user and product
    readVotes({ productId })
  }, [productId, readVotes])

  const votes = votesItems()

  //
  // Action handlers
  //

  const handleQuoteVote = useCallback(
    (id, { value }) => {
      // for when clicking
      setFocus({
        layer: FEEDBACK_QUOTES_LAYER,
      })

      setFocusId(id)

      return createVote({
        productId,
        quoteId: id,
        value,
      })
    },
    [productId, createVote, setFocus]
  )

  const handleQuoteEdit = id => {
    const isTitle = id === "title"
    const isInboxTitle = insight.isInbox && isTitle

    if (is(focusId) && !isInboxTitle) {
      // dont allow "i" char to register inside the input
      event.preventDefault()

      setEditId(id)
    }
  }

  const handleQuoteUpdate = useCallback(
    (id, { body, ...markdownMeta }) =>
      id === "title"
        ? onInsightUpdate(insight.id, { body }).then(() => setEditId(null))
        : update(id, { productId, body, ...markdownMeta }).then(() =>
            setEditId(null)
          ),
    [productId, insight.id, update, onInsightUpdate]
  )

  const handleQuoteClick = useCallback(
    id => {
      setFocus({ layer: FEEDBACK_QUOTES_LAYER })
      setFocusId(id)
    },
    [setFocus]
  )

  const handleQuoteEditCancel = useCallback(() => setEditId(null), [])

  //
  // Quotes - specific keyboard shortcuts
  //

  useQuotesKeyboard(FEEDBACK_QUOTES_LAYER, {
    productId,
    focusId,
    insightId: insight.id,
    insightIsInbox: insight.isInbox,
    items: [
      {
        id: "title",
        position: Number.NEGATIVE_INFINITY,
      },
      ...quotes,
    ],
    onCreate: create,
    onUpdate: update,
    onRemove: remove,
    onVote: handleQuoteVote,
    onFocus: setFocusId,
    onEdit: handleQuoteEdit,
  })

  return (
    <QuotesView
      focusId={focusId}
      editId={editId}
      items={map(item => {
        const vote = findWith({ resourceId: item.id })(votes)

        return {
          ...item,
          vote: isEmpty(vote) ? null : vote.value,
        }
      })(quotes)}
      insightBody={insight.body}
      quotesCount={insight.aggregate.quotesCount}
      isInbox={insight.isInbox}
      hasKeyboard={hasKeyboard}
      onVote={handleQuoteVote}
      onQuoteUpdate={handleQuoteUpdate}
      onQuoteEdit={handleQuoteEdit}
      onQuoteEditCancel={handleQuoteEditCancel}
      onQuoteClick={handleQuoteClick}
    />
  )
}

QuotesContainer.propTypes = {
  productId: PropTypes.string.isRequired,
  insight: PropTypes.shape({
    id: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    aggregate: PropTypes.shape({
      quotesCount: PropTypes.number,
    }).isRequired,
    isInbox: PropTypes.bool.isRequired,
  }).isRequired,
  onInsightUpdate: PropTypes.func.isRequired,
}

QuotesContainer.defaultProps = {}

export { QuotesContainer }
