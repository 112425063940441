const debug = require("debug")("mutant:useProduct.MembersList")

import { buildList } from "@mutant-ws/redux-list"
import { GET, POST, PATCH } from "@mutant-ws/fetch-browser"

const MembersList = buildList({
  name: "GLOBAL.MEMBERS",

  create: ({ productId }, { email }) =>
    POST(`/products/${productId}/members`, {
      body: {
        email,
      },
    }),

  read: ({ productId }) =>
    GET(`/product/${productId}/members`, {
      query: {
        pluck: [
          "userId",
          "name",
          "avatarURL",
          "role",
          "location",
          "isOnline",
          "assignedAt",
          "updatedAt",
        ],
      },
    }),
  update: ({ productId }, { focusId, layer, status, queryParams }) =>
    PATCH(`/products/${productId}/members`, {
      body: {
        focusId,
        layer,
        status,
        queryParams,
      },
    }),
})

export { MembersList }
