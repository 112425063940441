const debug = require("debug")("mutant:FeatureCardUI")

import React, { useEffect, useRef } from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { is } from "@mutant-ws/m"

import { UITag } from "../../../core.ui/tag/tag"
import { UIAvatar } from "../../../core.ui/avatar/avatar"
import { UISimpleHist } from "../../../core.ui/simple-hist/hist"

import css from "./feature-card.css"

const FeatureCardUI = ({
  className,
  id,
  title,
  coverURL,
  userName,
  userAvatarURL,
  avatarBackgroundColor,
  avatarForegroundColor,
  componentId,
  countBacklog,
  countDoing,
  countTesting,
  countDone,
  isSelect,
  isFocus,
  onClick,
}) => {
  const cardRef = useRef()

  useEffect(() => {
    if (is(cardRef.current) && isFocus === true) {
      cardRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      })
    }
  }, [isFocus])

  const countCards = countBacklog + countDoing + countTesting + countDone

  return (
    <div
      ref={cardRef}
      className={cx(css.card, {
        [className]: is(className),
        [css["card--is-select"]]: isSelect,
        [css["card--is-focus"]]: isFocus,
        [css["card--with-cover"]]: is(coverURL),
      })}
      onClick={() => {
        is(onClick) && onClick(id)
      }}>
      {is(coverURL) ? (
        <div
          className={css.cover}
          style={{
            backgroundImage: `url(${coverURL})`,
          }}
        />
      ) : null}

      <div className={css.body}>
        <div className={css.tags}>
          <UITag className={css.tag} text={componentId} size="small" />
        </div>

        <h3 className={css.title}>{title}</h3>

        {is(userName) ? (
          <UIAvatar
            className={css.avatar}
            name={userName}
            avatar={userAvatarURL}
            size={20}
            backgroundColor={avatarBackgroundColor}
            foregroundColor={avatarForegroundColor}
          />
        ) : (
          <span className={css["avatar-placeholder"]}>&nbsp;</span>
        )}

        <UISimpleHist
          className={css["status-hist"]}
          total={countCards}
          items={[countBacklog, countDoing, countTesting, countDone]}
        />
      </div>
    </div>
  )
}

FeatureCardUI.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  coverURL: PropTypes.string,
  userName: PropTypes.string,
  userAvatarURL: PropTypes.string,
  avatarBackgroundColor: PropTypes.string,
  avatarForegroundColor: PropTypes.string,
  componentId: PropTypes.string,
  countBacklog: PropTypes.number,
  countDoing: PropTypes.number,
  countTesting: PropTypes.number,
  countDone: PropTypes.number,
  isFocus: PropTypes.bool,
  isSelect: PropTypes.bool,
  onClick: PropTypes.func,
}

FeatureCardUI.defaultProps = {
  className: null,
  id: null,
  title: null,
  coverURL: null,
  userName: null,
  userAvatarURL: null,
  avatarBackgroundColor: "",
  avatarForegroundColor: "",
  componentId: null,
  countBacklog: 0,
  countDoing: 0,
  countTesting: 0,
  countDone: 0,
  isSelect: false,
  isFocus: false,
  onClick: null,
}

const memo = React.memo(FeatureCardUI)

export { memo as FeatureCardUI }
