/* eslint-disable react/forbid-dom-props */

const debug = require("debug")("mutant:UIHero")

import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { map, pipe, split, join, toLower, isEmpty } from "@mutant-ws/m"

import css from "./hero.css"

const UIHero = ({ children, picture, feature }) => (
  <section
    id={pipe(split(" "), join("-"), map(toLower))(feature)}
    className={css.hero}>
    <div className={css.content}>
      {!isEmpty(feature) && (
        <p>
          {map(item => (
            <span
              key={item}
              className={cx(css.feature, {
                [css["is-active"]]: feature === item,
              })}>
              {item}
            </span>
          ))(["Plan", "Issue Tracking", "Behavior Tracking"])}
        </p>
      )}
      {children}
      <img className={css["main-picture"]} src={picture} />
    </div>
  </section>
)

UIHero.propTypes = {
  children: PropTypes.node.isRequired,
  picture: PropTypes.string.isRequired,
  feature: PropTypes.string,
}

UIHero.defaultProps = {
  feature: "",
}

export { UIHero }
