const debug = require("debug")("mutant:LibPositioning")

import { max, min, findIndexWith, isNothing } from "@mutant-ws/m"
import { path, pipe, prop, inc } from "ramda"

export const positionBefore = ({ id, items }) => {
  const index = findIndexWith({ id })(items)
  const currentPos = path([index, "position"])(items)
  const prevPos = index <= 0 ? null : path([index - 1, "position"])(items)

  if (items.length === 0) {
    return 10
  }

  // nothing hovered, add at the end
  if (isNothing(id)) {
    return pipe(max(prop("position")), prop("position"), Math.floor, inc)(items)
  }

  return isNothing(prevPos)
    ? Math.ceil(currentPos) - 1
    : currentPos - (currentPos - prevPos) / 2
}

export const positionAfter = ({ id, items }) => {
  const index = findIndexWith({ id })(items)
  const currentPos = path([index, "position"])(items)
  const nextPos = path([index + 1, "position"])(items)

  // only title present as field
  if (items.length === 1) {
    return 10
  }

  // nothing hovered, add at the end
  if (isNothing(id)) {
    return pipe(max(prop("position")), prop("position"), Math.floor, inc)(items)
  }

  // current hover over title
  if (currentPos === Number.NEGATIVE_INFINITY) {
    return Math.floor(nextPos) - 1
  }

  return isNothing(nextPos)
    ? Math.ceil(currentPos) + 1
    : currentPos + (nextPos - currentPos) / 2
}

export const hover = (direction, { id, items, matchIdField = "id" }) => {
  const index = findIndexWith({ [matchIdField]: id })(items)

  return direction === "up" || direction === "prev"
    ? path([max([0, index - 1]), matchIdField])(items)
    : path([min([items.length - 1, index + 1]), matchIdField])(items)
}

export const move = (direction, { id, items }) => {
  const index = findIndexWith({ id })(items)

  if (direction === "up" || direction === "prev") {
    const prevPos =
      index - 1 < 0 ? undefined : path([index - 1, "position"], items)
    const prevPrevPos =
      index - 2 < 0 ? undefined : path([index - 2, "position"], items)

    // Use .floor to mitigate number of decimals
    return isNothing(prevPos)
      ? undefined
      : isNothing(prevPrevPos)
      ? Math.floor(prevPos) - 1
      : prevPrevPos + (prevPos - prevPrevPos) / 2
  }

  // direction === "down"
  const nextPos = path([index + 1, "position"])(items)
  const nextNextPos = path([index + 2, "position"])(items)

  // Use .ceil to mitigate number of decimals
  return isNothing(nextPos)
    ? undefined
    : isNothing(nextNextPos)
    ? Math.ceil(nextPos) + 1
    : nextPos + (nextNextPos - nextPos) / 2
}
