const debug = require("debug")("mutant:UIFooter")

import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { is } from "@mutant-ws/m"

import { getPath } from "/core.libs/routes"

import css from "./footer.css"
import githubPath from "./images/github.png"

const UIFooter = ({ children, onClick }) => (
  <React.Fragment>
    <hr className={css.hr} />
    <div className={css.footer}>
      {children}
      <p className={css["sound-bite"]}>
        <span>✓ Free 15 days trial</span>
        <span>✓ No credit card required</span>
        <span>✓ Pay as you grow</span>
      </p>
      <p className={css.terms}>
        Made by humans in Amsterdam, Netherlands.{" "}
        <Link
          to={getPath("contact")}
          onClick={is(onClick) ? onClick("contact") : null}>
          We would appreciate your feedback.
        </Link>
      </p>

      <p className={css.links}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/mutant-ws/next"
          onClick={is(onClick) ? onClick("github") : null}>
          <img src={githubPath} />
        </a>
      </p>
    </div>
  </React.Fragment>
)

const memo = React.memo(UIFooter)

UIFooter.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
}

UIFooter.defaultProps = {
  children: null,
  onClick: null,
}

export { memo as UIFooter }
