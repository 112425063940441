/* eslint-disable react/no-multi-comp  */
const debug = require("debug")("mutant:FeatureCardUI")

import React, { useCallback, useState, useEffect, useRef } from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { read, isEmpty, is } from "@asd14/m"

import { deepReactMemo } from "/core.hooks/use-deep"

import { UITag } from "/core.ui/tag/tag"
import { UIAvatar } from "/core.ui/avatar/avatar"
import { UIAvatarGroup } from "/core.ui/avatar/group"
import { UISimpleHist } from "/core.ui/simple-hist/hist"
import { UITextarea } from "/core.ui/textarea/textarea"
import { UIErrorBoundary } from "/core.ui/error-boundary/error-boundary"

import { MetricUI } from "./metric"

import css from "./card.css"

const FeatureCardUI = ({
  className,
  id,
  title,
  coverURL,
  coverMetricId,
  userName,
  userAvatarURL,
  userBgColor,
  userFgColor,
  componentId,
  countBacklog,
  countDoing,
  countTesting,
  countDone,
  metric,
  events,
  usersFocusing,
  isHighlight,
  isSelect,
  isFocus,
  isEdit,
  onClick,
  onDoubleClick,
  onTitleEditUpdate,
  onTitleEditCancel,
}) => {
  const cardRef = useRef()

  useEffect(() => {
    if (is(cardRef.current) && isFocus === true) {
      // cardRef.current.scrollIntoView()
    }
  }, [isFocus])

  const countCards = countBacklog + countDoing + countTesting + countDone

  //
  // Editable title field
  //

  const [localTitle, setLocalTitle] = useState(title)

  useEffect(() => {
    if (!isEdit) {
      setLocalTitle(title)
    }
  }, [title, isEdit])

  const handleLocalTitleChange = useCallback(event => {
    setLocalTitle(event.currentTarget.value)
  }, [])

  const handleLocalTitleSave = useCallback(() => {
    onTitleEditUpdate(localTitle)
  }, [localTitle, onTitleEditUpdate])

  const handleLocalTitleShortcuts = useCallback(
    event => {
      if (event.key === "Tab") {
        // random focus next element
        event.preventDefault()

        onTitleEditUpdate(localTitle)
      }

      if (event.key === "Enter" && is(onTitleEditUpdate)) {
        // prevent new line
        event.preventDefault()

        onTitleEditUpdate(localTitle)
      }

      if (event.key === "Escape" && is(onTitleEditCancel)) {
        // dont trigger blur my mistake
        event.preventDefault()

        onTitleEditCancel()
      }
    },
    [localTitle, onTitleEditUpdate, onTitleEditCancel]
  )

  return (
    <div
      className={cx(css["card-wrapper"], {
        [className]: is(className),
      })}>
      <div
        ref={cardRef}
        className={cx(css.card, {
          [css["card--is-select"]]: isSelect,
          [css["card--is-highlight"]]: isHighlight,
          [css["card--is-focus"]]: isFocus,
          [css["card--with-cover"]]: is(coverURL) && !is(coverMetricId),
          [css["card--with-metric"]]: is(coverMetricId),
        })}
        onClick={() => {
          is(onClick) && onClick(id)
        }}
        onDoubleClick={() => {
          is(onDoubleClick) && onDoubleClick(id)
        }}>
        {is(coverURL) && !is(coverMetricId) ? (
          <div
            className={css.cover}
            style={{
              backgroundImage: `url(${coverURL})`,
            }}
          />
        ) : null}

        {is(coverMetricId) ? (
          <div className={css.metric}>
            <MetricUI
              id={coverMetricId}
              title={read(["code", "title"])(metric)}
              type={read(["code", "type"])(metric)}
              dataSets={events}
            />
          </div>
        ) : null}

        <div className={css.body}>
          <div className={css.tags}>
            <UITag className={css.tag} text={componentId} size="small" />
          </div>

          {isEdit ? (
            <UITextarea
              className={css["title-textarea"]}
              id={`feature-${id}-title`}
              value={localTitle}
              placeholder="Placeholder for feature"
              shouldFocus={true}
              shouldSelectAll={true}
              shouldAutoResize={false}
              onChange={handleLocalTitleChange}
              onKeyDown={handleLocalTitleShortcuts}
              onBlur={handleLocalTitleSave}
            />
          ) : (
            <h3
              className={cx(css.title, {
                [css["title--is-empty"]]: isEmpty(title),
              })}>
              {isEmpty(title) ? "Placeholder for feature" : title}
            </h3>
          )}

          {is(userName) ? (
            <UIAvatar
              className={css.avatar}
              name={userName}
              avatar={userAvatarURL}
              size={20}
              backgroundColor={userBgColor}
              foregroundColor={userFgColor}
            />
          ) : (
            <span className={css["avatar-placeholder"]}>&nbsp;</span>
          )}

          <UISimpleHist
            className={css["status-hist"]}
            total={countCards}
            items={[countBacklog, countDoing, countTesting, countDone]}
          />
        </div>

        <UIAvatarGroup
          className={css.focusers}
          users={usersFocusing}
          limit={2}
        />
      </div>
    </div>
  )
}

FeatureCardUI.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  coverURL: PropTypes.string,
  coverMetricId: PropTypes.string,
  userName: PropTypes.string,
  userAvatarURL: PropTypes.string,
  userBgColor: PropTypes.string,
  userFgColor: PropTypes.string,
  componentId: PropTypes.string,
  countBacklog: PropTypes.number,
  countDoing: PropTypes.number,
  countTesting: PropTypes.number,
  countDone: PropTypes.number,
  metric: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    code: PropTypes.shape({
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["area", "bar"]).isRequired,
      events: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    position: PropTypes.number,
    isKPI: PropTypes.bool,
  }),
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.instanceOf(Date).isRequired,
          y: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  usersFocusing: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      avatarURL: PropTypes.string,
      name: PropTypes.string.isRequired,
      bgColor: PropTypes.string,
      fgColor: PropTypes.string,
      isEdit: PropTypes.bool.isRequired,
    })
  ),
  isHighlight: PropTypes.bool,
  isSelect: PropTypes.bool,
  isFocus: PropTypes.bool,
  isEdit: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onTitleEditUpdate: PropTypes.func.isRequired,
  onTitleEditCancel: PropTypes.func.isRequired,
}

FeatureCardUI.defaultProps = {
  className: null,
  id: null,
  title: null,
  coverURL: null,
  coverMetricId: null,
  userName: null,
  userAvatarURL: null,
  userBgColor: null,
  userFgColor: null,
  componentId: null,
  countBacklog: 0,
  countDoing: 0,
  countTesting: 0,
  countDone: 0,
  metric: {},
  events: [],
  usersFocusing: [],
  isHighlight: false,
  isSelect: false,
  isFocus: false,
  isEdit: false,
}

const MemoFeatureCardUI = deepReactMemo(FeatureCardUI)

const FeatureCardUIWithBoundary = props => {
  return (
    <UIErrorBoundary message="Something inside this card has gone wrong. We put our best middle manager on it!">
      <MemoFeatureCardUI {...props} />
    </UIErrorBoundary>
  )
}

export { FeatureCardUIWithBoundary as FeatureCardUI }
