const debug = require("debug")("mutant:ProductPage")

import cx from "classnames"
import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import React, { useRef, useEffect, useCallback } from "react"
import { pluck, reduce, pipe, map, isEmpty, is } from "@mutant-ws/m"

import { useYank } from "/core.hooks/use-yank/use-yank"
import { useColorScale } from "/core.hooks/use-color-scale"
import { useAuth } from "/core.hooks/use-auth/use-auth"
import { useSocket } from "/core.hooks/use-socket"
import { useProduct } from "/core.hooks/use-product/use-product"
import { useCommands, byLayer } from "/core.hooks/use-commands"
import {
  PRODUCT_LAYER,
  FEEDBACK_LAYER,
  WORK_LAYER,
  MEASURE_LAYER,
  isFeedback,
  isWork,
  isMeasure,
} from "/core.hooks/use-focus"

import { UICommandLine } from "/core.ui/command-line/command-line"
import { UIYank } from "/core.ui/yank/yank"
import { UITag } from "/core.ui/tag/tag"

import { FeedbackContainer } from "../product.feedback/feedback.container"
import { WorkContainer } from "../product.work/work.container"
import { MeasureContainer } from "../product.measure/measure.container"

import { useProductCLI } from "./product.cli"
import { useProductKeyboard } from "./product.keyboard"
import { WorkspaceSwitcherUI } from "./ui/workspace-switcher/workspace-switcher"
import { MembersUI } from "./ui/members/members"
import { HelpUI } from "./ui/help/help"

import css from "./product.css"

const loadingSelector = pipe(
  Object.values,
  reduce(
    (acc, item) =>
      acc ||
      item.isLoading ||
      !isEmpty(item.creating) ||
      !isEmpty(item.updating) ||
      !isEmpty(item.removing),
    false
  )
)

const ProductPage = () => {
  const commandLineRef = useRef()
  const { id: userId } = useAuth()
  const isHTTPActive = useSelector(loadingSelector)

  //
  // Product data fetching
  //

  const { productId } = useParams()
  const { name: productName, members = [], invite } = useProduct()
  const [colorPicker, reverseColorPicker] = useColorScale(
    pluck("userId")(members)
  )

  //
  // WebSocket connection
  //

  const { isConnected, connect, disconnect } = useSocket()

  useEffect(() => {
    connect({ room: productId })

    return () => disconnect()
  }, [connect, disconnect, productId])

  //
  // Product keyboard shortcuts
  //

  const {
    layer,
    isHelpVisible,
    setFocus,
    setIsHelpVisible,
  } = useProductKeyboard(PRODUCT_LAYER, {
    commandLineRef,
  })

  //
  // Product CLI commands
  //

  useProductCLI(PRODUCT_LAYER, {
    onMemberInvite: invite,
  })

  const [commands] = useCommands()

  //
  // Copy/Paste buffer
  //

  const { id, model } = useYank()

  return (
    <React.Fragment>
      <Helmet>
        <title>{productName}</title>
      </Helmet>

      {is(id) ? <UIYank id={id} model={model} /> : null}

      <div className={css["hud-wrapper"]}>
        <div className={css.hud}>
          <WorkspaceSwitcherUI
            selected={layer}
            items={[
              { id: WORK_LAYER, label: "work", shortcut: "1" },
              { id: MEASURE_LAYER, label: "analytics", shortcut: "2" },
              { id: FEEDBACK_LAYER, label: "feedback", shortcut: "3" },
            ]}
            onClick={useCallback(value => setFocus({ layer: value }), [
              setFocus,
            ])}
          />
          <div className={css["cli-wrapper"]}>
            <UICommandLine
              ref={commandLineRef}
              className={css.cli}
              classNameSuggestions={css["cli-suggestions"]}
              commands={byLayer(layer, commands)}
              hasFocusAfterRun={false}
              onFocus={() => setIsHelpVisible(false)}
            />
            <UITag
              className={css["help-icon"]}
              text="?"
              color="blue"
              onClick={() => setIsHelpVisible(!isHelpVisible)}
            />
            {isHelpVisible ? <HelpUI /> : null}
          </div>
          <MembersUI
            meId={userId}
            items={map(item => ({
              ...item,
              bgColor: colorPicker(item.userId),
              fgColor: reverseColorPicker(item.userId),
            }))(members)}
            isMeLoading={isHTTPActive}
            isMeOnline={isConnected}
          />
        </div>
      </div>

      <div className={css["product-wrapper"]}>
        <div
          className={cx(css.product, {
            [css["product--focus-feedback"]]: isFeedback(layer),
            [css["product--focus-work"]]: isWork(layer),
            [css["product--focus-measure"]]: isMeasure(layer),
          })}>
          <div className={cx(css.frame, css.work)}>
            <WorkContainer productId={productId} members={members} />
          </div>
          <div className={cx(css.frame, css.measure)}>
            <MeasureContainer productId={productId} />
          </div>
          <div className={cx(css.frame, css.feedback)}>
            <FeedbackContainer productId={productId} members={members} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

ProductPage.propTypes = {}

ProductPage.defaultProps = {}

export { ProductPage }
