const debug = require("debug")("mutant:UsersList")

import { buildList } from "@mutant-ws/redux-list"
import { GET, PATCH } from "@mutant-ws/fetch-browser"

const UsersList = buildList({
  name: "PROFILE.ME.USERS",

  read: () => GET("/auth/me"),

  update: (id, { name, email }) =>
    PATCH("/auth/me", {
      body: {
        name,
        email,
      },
    }),
})

export { UsersList }
