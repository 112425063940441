const debug = require("debug")("mutant:ColumnsContainer")

import React from "react"
import PropTypes from "prop-types"
import {
  pluck,
  dec,
  filterWith,
  findWith,
  findIndexWith,
  not,
  equals,
  map,
  get,
  hasWith,
  is,
  isEmpty,
} from "@mutant-ws/m"
import { length, path, pipe, prop, propOr, when, min, groupBy } from "ramda"

import { hover, move, positionBefore } from "/core.libs/positioning"
import { addShortcuts, removeShortcuts } from "/core.libs/keyboard"
import { useColorScale } from "/core.hooks/use-color-scale"
import { useLiveList } from "/core.hooks/use-live-list"
import { useQuery } from "/core.hooks/use-query"
import { useAuth } from "/core.hooks/use-auth/use-auth"
import { useMemo, useEffect, useCallback } from "/core.hooks/use-deep"
import {
  useFocus,
  WORK_BOARD_COLUMNS_LAYER,
  WORK_BOARD_FEATURES_LAYER,
} from "/core.hooks/use-focus"

import { CardsList } from "./data/list.cards"
import { ColumnsView } from "./columns.view"

// find first column to the right that has cards
const getNextStatus = ({ current, cards }) => {
  if (isEmpty(cards)) {
    return current === "backlog"
      ? "doing"
      : current === "doing"
      ? "testing"
      : "done"
  }

  let count = 0,
    status = current

  do {
    status =
      status === "backlog" ? "doing" : status === "doing" ? "testing" : "done"
    count = pipe(filterWith({ status }), length)(cards)
  } while (count === 0 && status !== "done")

  return status
}

// find first prev column with cards inside
const getPrevStatus = ({ current, cards }) => {
  if (isEmpty(cards)) {
    return current === "done"
      ? "testing"
      : current === "testing"
      ? "doing"
      : "backlog"
  }

  let count = 0,
    status = current

  do {
    status =
      status === "done" ? "testing" : status === "testing" ? "doing" : "backlog"
    count = pipe(filterWith({ status }), length)(cards)
  } while (count === 0 && status !== "backlog")

  return status
}

const ColumnsContainer = ({
  productId,
  featureHighlightId,
  members,
  onCardFocus,
  onCardOpen,
}) => {
  const [{ "feature-id": featureQueryId }, setQuery] = useQuery()
  const { id: userId } = useAuth()
  const [colorPicker, reverseColorPicker] = useColorScale(
    pluck("userId")(members)
  )

  //
  // Cards - data fetching
  //

  const {
    selector: { items },
    read,
    update,
    remove,
  } = useLiveList(CardsList, {
    events: {
      prefix: "cards",
      shouldAcceptFn: useCallback(({ type } = {}) => type === "work", []),
    },
  })

  useEffect(() => {
    read({ productId })
  }, [productId, read])

  const cards = when(
    () => is(featureQueryId),
    filterWith({ featureId: featureQueryId })
  )(items())

  const cardsByStatus = useMemo(() => groupBy(prop("status"))(cards), [cards])

  //
  // App wide focus
  //

  const [{ id: focusId, layer, status: focusStatus }, setFocus] = useFocus()
  const hasKeyboard = layer === WORK_BOARD_COLUMNS_LAYER
  const focusCardStatus = pipe(findWith({ id: focusId }), prop("status"))(cards)
  const focusCardIndex = pipe(
    propOr([], focusCardStatus),
    findIndexWith({ id: focusId })
  )(cardsByStatus)

  //
  // Columns keyboard shortcuts
  //

  const handleCardChange = useCallback(
    card => {
      if (!isEmpty(card)) {
        setFocus({
          id: card.id,
          layer: WORK_BOARD_COLUMNS_LAYER,
        })

        onCardFocus(card)
      }
    },
    [onCardFocus, setFocus]
  )

  useEffect(() => {
    addShortcuts({
      layer: WORK_BOARD_COLUMNS_LAYER,
      shortcuts: {
        // Escape: () => {
        //   if (is(featureQueryId)) {
        //     setQuery({
        //       ["feature-id"]: undefined,
        //     })
        //   }
        // },

        "ArrowUp,ArrowDown": ({ key, shiftKey }) => {
          const direction = key === "ArrowDown" ? "down" : "up"

          if (isEmpty(cards) && direction === "up") {
            setFocus({ layer: WORK_BOARD_FEATURES_LAYER })
          }

          if (isEmpty(cards)) {
            return
          }

          if (shiftKey) {
            const position = move(direction, {
              id: focusId,
              items: propOr([], focusCardStatus)(cardsByStatus),
            })

            update(focusId, {
              status: focusCardStatus,
              position,
            })
          } else {
            const newFocusId = hover(direction, {
              id: focusId,
              items: get(focusCardStatus, [])(cardsByStatus),
            })

            if (direction === "up" && newFocusId === focusId) {
              setFocus({ layer: WORK_BOARD_FEATURES_LAYER })
            } else {
              handleCardChange(findWith({ id: newFocusId })(cards))
            }
          }
        },

        "ArrowRight,ArrowLeft": ({ key, shiftKey }) => {
          if (isEmpty(cards)) {
            return
          }

          /**
           * Move card to side columns
           */
          if (shiftKey && is(focusId)) {
            const newStatus =
              key === "ArrowRight"
                ? getNextStatus({ current: focusCardStatus })
                : getPrevStatus({ current: focusCardStatus })
            const cardsWithNewStatus = propOr([], newStatus)(cardsByStatus)
            const newIndex = min(focusCardIndex, length(cardsWithNewStatus))

            update(focusId, {
              status: newStatus,
              position: positionBefore({
                id: path([newIndex, "id"])(cardsWithNewStatus),
                items: cardsWithNewStatus,
              }),
            })
          }

          /**
           * Hover to first side column that has cards
           */
          if (!shiftKey) {
            const newStatus =
              key === "ArrowRight"
                ? getNextStatus({ current: focusCardStatus, cards })
                : getPrevStatus({ current: focusCardStatus, cards })
            const cardsWithNewStatus = propOr([], newStatus)(cardsByStatus)
            const newIndex = min(
              focusCardIndex,
              pipe(length, dec)(cardsWithNewStatus)
            )

            if (key === "ArrowRight" && focusCardStatus !== "done") {
              handleCardChange(get(newIndex)(cardsWithNewStatus))
            }

            if (key === "ArrowLeft" && status !== "backlog") {
              handleCardChange(get(newIndex)(cardsWithNewStatus))
            }
          }
        },

        Enter: () => {
          if (is(focusId)) {
            onCardOpen(focusId)
          }
        },

        "d,D": () => {
          if (is(focusId)) {
            const title = pipe(findWith({ id: focusId }), prop("title"))(cards)

            if (window.confirm(`Delete work "${title}"?`)) {
              const nextFocusId = hover("down", {
                id: focusId,
                items: get(focusCardStatus, [])(cardsByStatus),
              })

              handleCardChange(findWith({ id: nextFocusId })(cards))

              return remove(focusId).then(({ error }) => {
                if (is(error)) {
                  handleCardChange(findWith({ id: focusId })(cards))
                }
              })
            }
          }
        },

        "e,i": event => {
          if (is(focusId)) {
            // prevent "i" or "e" getting in the input
            event.preventDefault()

            setFocus({ status: "update" })
          }
        },
      },
    })

    return () => removeShortcuts({ layer: WORK_BOARD_COLUMNS_LAYER })
  }, [
    featureQueryId,
    focusId,
    focusCardStatus,
    focusCardIndex,
    cards,
    cardsByStatus,
    remove,
    update,
    setQuery,
    setFocus,
    onCardOpen,
    handleCardChange,
  ])

  //
  // Focus first when gaining keyboard
  //

  useEffect(() => {
    const isFocusOfTypeWork = hasWith({ id: focusId })(cards)

    if (hasKeyboard && !isFocusOfTypeWork && !isEmpty(cards)) {
      handleCardChange(get(0)(cards))
    }
  }, [focusId, hasKeyboard, cards, handleCardChange])

  //
  // Editable card.title
  //

  const handleCardTitleEditUpdate = useCallback(
    nextTitle => {
      const prevTitle = pipe(findWith({ id: focusId }), get("title"))(cards)

      setFocus({ status: "read" })

      if (nextTitle !== prevTitle) {
        update(focusId, {
          title: nextTitle,
        })
      }
    },
    [focusId, cards, update, setFocus]
  )

  const handleCardTitleEditCancel = useCallback(() => {
    setFocus({ status: "read" })
  }, [setFocus])

  return (
    <ColumnsView
      // Highlight cards linked to the above selected Feature.
      // Dont highlight filter by Feature.
      featureHighlightId={is(featureQueryId) ? null : featureHighlightId}
      focusId={hasKeyboard ? focusId : null}
      editId={hasKeyboard && focusStatus === "update" ? focusId : null}
      cards={cards}
      members={map(item => {
        return {
          ...item,
          bgColor: colorPicker(item.userId),
          fgColor: reverseColorPicker(item.userId),
          isEdit: pipe(get(["location", "status"], ""), equals("edit"))(item),
        }
      })(members)}
      membersPresent={pipe(
        filterWith({
          isOnline: true,
          userId: not(equals(userId)),
          location: pipe(get("layer"), equals(WORK_BOARD_COLUMNS_LAYER)),
        }),
        map(item => ({
          userId: get("userId")(item),
          cardId: get(["location", "focusId"])(item),
        }))
      )(members)}
      onClick={useCallback(() => {
        setFocus({ layer: WORK_BOARD_COLUMNS_LAYER })
      }, [setFocus])}
      onCardClick={useCallback(
        id => {
          if (id === focusId) {
            onCardOpen(id)
          } else {
            handleCardChange(findWith({ id })(cards))
          }
        },
        [focusId, cards, onCardOpen, handleCardChange]
      )}
      onCardDoubleClick={onCardOpen}
      onCardTitleEditUpdate={handleCardTitleEditUpdate}
      onCardTitleEditCancel={handleCardTitleEditCancel}
    />
  )
}

ColumnsContainer.propTypes = {
  productId: PropTypes.string.isRequired,
  featureHighlightId: PropTypes.string,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      name: PropTypes.string,
      avatarURL: PropTypes.string,
      isOnline: PropTypes.bool,
    })
  ),
  onCardFocus: PropTypes.func.isRequired,
  onCardOpen: PropTypes.func.isRequired,
}

ColumnsContainer.defaultProps = {
  featureHighlightId: null,
  members: [],
}

export { ColumnsContainer }
