const debug = require("debug")("mutant:BaseLayout")

import React from "react"
import PropTypes from "prop-types"

const BaseLayout = ({ children }) => {
  return <React.Fragment>{children}</React.Fragment>
}

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { BaseLayout }
